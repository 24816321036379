import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '@services/account.service';
@Component({
  selector: 'app-email-activate-account',
  templateUrl: './email-activate-account.component.html',
  styleUrls: ['./email-activate-account.component.css']
})
export class EmailActivateAccountComponent {
  emailLinkActivation!: any;
  submitted: boolean = false;
  err!:any;
  get email(){return this.emailLinkActivation.get('email'); }
  constructor(private router: Router,private emaiLink:  AccountService){

  }
  ngOnInit(): void {
    this.emailLinkActivation= new FormGroup({
      "email": new FormControl("", [Validators.required,Validators.email]),
    })
  }
  resendlink(){
    if (this.emailLinkActivation.valid && this.email.value != null){
      this.submitted = true;
      console.log('user')
      this.emaiLink.verifyLink(this.emailLinkActivation.value).subscribe((result)=>{
        this.submitted = false;
        this.router.navigateByUrl('/emailvalidation')
      },
        (error)=>{
          this.submitted = false;
          this.err = error;
        }
      );
    } else{
      this.submitted = false;
      this.validateform(this.emailLinkActivation)
    }
  }
  private validateform(form: FormGroup ){
    Object.keys(form.controls).forEach(field =>{
      const controls =  form.get(field);
      if(controls instanceof FormControl){
        controls.markAsDirty({onlySelf: true});

      }else if(controls instanceof FormGroup){
        this.validateform(controls)
      }
    })

  }
}

import { Base } from "../models/base";
import { HttpClient, HttpErrorResponse, HttpHandler, HttpHeaders, HttpParams, HttpXhrBackend } from "@angular/common/http";
import { catchError, Observable, retry, throwError } from "rxjs";
import { environment } from 'environments/environment';


export class BaseService<T extends Base> {

  constructor(
    private httpClient: HttpClient,
    private endpoint: string,
  ) {

  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'rejectUnauthorized': 'false'
    })
  }

  getAll(state: boolean): Observable<T[]> {
    return this.httpClient
      .get<T[]>(`${environment.apiUrl}/${this.endpoint}/all/${state}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  getList(): Observable<T[]> {
    return this.httpClient
      .get<T[]>(`${environment.apiUrl}/${this.endpoint}/list`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  changeState(id: number, isEnabled: boolean): Observable<T[]> {
    return this.httpClient
      .get<T[]>(`${environment.apiUrl}/${this.endpoint}/${id}/state/${isEnabled}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  getCreated(value: number): Observable<T[]> {
    return this.httpClient
      .get<T[]>(`${environment.apiUrl}/${this.endpoint}/from/${value}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  changeDefault(id: number, isDefault: boolean): Observable<T[]> {
    return this.httpClient
      .put<T[]>(`${environment.apiUrl}/${this.endpoint}/{id}/default/{isDefault}?id=${id}&isDefault=${isDefault}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }


  restoreState(id: number, isEnabled: boolean): Observable<T[]> {
    return this.httpClient
      .put<T[]>(`${environment.apiUrl}/${this.endpoint}/{id}/state/{isEnabled}?id=${id}&isEnabled=${isEnabled}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }
  // get(page: number) : Observable<T[]>{
  //     return this.httpClient.get<T[]>(this.url+"/"+this.endpoint+"?page="+page+"&total=10")
  //     .pipe(
  //         retry(2),
  //         catchError(this.handleError)
  //     )
  // }

  getById(id: number): Observable<T> {
    return this.httpClient
      .get<T>(`${environment.apiUrl}/${this.endpoint}/${id}`)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  create(item: any): Observable<T> {
    console.log(item)

    return this.httpClient.post<T>(`${environment.apiUrl}/${this.endpoint}`, JSON.stringify(item), this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  update(item: T): Observable<T> {
    console.log(item);
    return this.httpClient.put<T>(`${environment.apiUrl}/${this.endpoint}/{id}/update`, JSON.stringify(item), this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }
  /**updateProjet(item: any): Observable<T> {
    console.log(item);
    return this.httpClient.put<any>(`${environment.apiUrl}/${this.endpoint}/{id}/update?id=${item.id}&name=${item.name}&description=${item.description}&notifyUrl=${item.notifyUrl}
    &cancelUrl=${item.cancelUrl}&successUrl=${item.successUrl}&websiteUrl=${item.websiteUrl}&itemTypeSold=${item.itemTypeSold}&nameOfItemSold=${item.itemTypeSold}&isCustomerCharge=${true}
    &organizationID=${item.organizationID}&principalCode=${item.principalCode}&isPrivacyPolicyRead=${true}&operateurs=${item.operateurs}
    `, JSON.stringify(item),this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }
**/
  delete(item: T) {
    return this.httpClient.delete<T>(`${environment.apiUrl}/${this.endpoint}/${item.id}/delete`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }
  validate(item: T) {
    return this.httpClient.put<T>(`${environment.apiUrl}/${this.endpoint}/{id}/validate?id=${item.id}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }
  deleteProject(item: T) {
    return this.httpClient.delete<T>(`${environment.apiUrl}/${this.endpoint}/{id}/delete?id=${item.id}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }
  disabled(item: T) {
    return this.httpClient.delete<T>(`${environment.apiUrl}/${this.endpoint}/{id}/delete?id=${item.id}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }
  disableds(item: T) {
    return this.httpClient.delete<T>(`${environment.apiUrl}/${this.endpoint}/{id}/delete?id=${item.id}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  login(item: T) {
    return this.httpClient.post<T>(`${environment.apiUrl}/${this.endpoint}`, JSON.stringify(item), this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  getBycode(code: any): Observable<T> {
    return this.httpClient
      .get<T>(`${environment.apiUrl}/${this.endpoint}?code=${code}`)
      .pipe(
        catchError(this.handleError)
      )
  }
  
  getParams(): Observable<T> {
    console.log('okssssss')
    return this.httpClient
      .get<T>(`${environment.apiUrl}/${this.endpoint}/show`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
        
      )
  }
  updateParams(item: any): Observable<T> {
    return this.httpClient
      .put<T>(`${environment.apiUrl}/${this.endpoint}/update`,JSON.stringify(item), this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }
  getBycreated(id: number): Observable<T> {
    return this.httpClient
      .get<T>(`${environment.apiUrl}/${this.endpoint}/from/${id}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }
  getBycreatedu(id: number, status: boolean): Observable<T> {
    return this.httpClient
      .get<T>(`${environment.apiUrl}/${this.endpoint}/from/${id}/${status}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }
 demoPayement(item:any): Observable<T>{
  console.log(item)

    return this.httpClient.post<T>(`${environment.apiUrl}/${this.endpoint}`, JSON.stringify(item), this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
 }

  getPayement(code: string): Observable<T> {
    return this.httpClient
      .get<T>(`${environment.apiUrl}/${this.endpoint}/${code}`)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }
  getBalance(code: string): Observable<T> {
    return this.httpClient
      .get<T>(`${environment.apiUrl}/${this.endpoint}/getBalance?projectKey=${code}`)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }
  private handleError(error: HttpErrorResponse) {
    let errorMessage;
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      if (error.status == 200) {
        errorMessage = { "Code d'erreur": error.status, "message": "OK" };

      } else {
        errorMessage = { "Code d'erreur": error.status, "message": error.error.message };

      }
    }
    return throwError(errorMessage);
  }
}

<div class="container-fluid">
    <main>
        <div class="pagetitle">
            <h1>Withdraw History</h1>
            <nav style="display: flex;justify-content: space-between;">

                <div>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/dashbord">Home</a></li>
                        <li class="breadcrumb-item active">Finance</li>
                        <li class="breadcrumb-item active">withdraw history</li>
                    </ol>
                </div>
            </nav>


            <div class="card" style="background-color: white;">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-12">
                            <div class="card-body pt-3">
                                <div class="tab-content pt-2">


                                    <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
                                        <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                                            <app-spinner></app-spinner>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="DataStateEnum.ERROR">


                                        </ng-container>
                                        <ng-container *ngSwitchCase="DataStateEnum.LOADED">

                                            <div class="tab-pane fade show active profile-overview" id="profile-edit">
                                                <table datatable [dtOptions]="dtOptions" class="row-border hover">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Code</th>
                                                            <th>Phone</th>
                                                            <th>Amount</th>
                                                            <th>Currency
                                                            </th>
                                                            <th>WithdrawBy</th>
                                                            <th>State</th>
                                                            <th></th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Code</th>
                                                            <th>Phone</th>
                                                            <th>Amount</th>
                                                            <th>Currency
                                                            </th>
                                                            <th>WithdrawBy</th>
                                                            <th>State</th>
                                                            <th></th>

                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>




                                        </ng-container>
                                    </ng-container>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>


    </main>

</div>
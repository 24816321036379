import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';

import { Parameter } from '../models/parameter';
import { environment } from 'environments/environment';
import { BaseService } from './base.service';




@Injectable({
  providedIn: 'root'
})
export class ParameterService extends BaseService<Parameter> {

  constructor(private httpCient: HttpClient) {
    super(
      httpCient,
      "parameter"
    );
}

  
}

<div class="container-fluid">
    <main>
        <div class="pagetitle">
            <h1>Users</h1>
            <nav>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/dashbord">Home</a></li>
                    <li class="breadcrumb-item active">Users</li>
                </ol>
            </nav>
            <div class="card" style="background-color: white;">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 col-md-6 col-lg-12">
                        <div class="card-body pt-3">
                            <ul class="nav nav-tabs nav-tabs-bordered">
    
                                <li class="nav-item"> <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-edit">Active Users</button></li>
    
                                <li class="nav-item"> <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password">Disabled Users</button></li>
                            </ul>
                            <div class="tab-content pt-2">
                    <ng-container  *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
                        <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                          <app-spinner></app-spinner>
                        </ng-container>
                        <ng-container *ngSwitchCase="DataStateEnum.ERROR">
                         
    
                        </ng-container>
                        <ng-container *ngSwitchCase="DataStateEnum.LOADED">
                        
            
                                        <div class="tab-pane fade show active profile-overview" id="profile-edit">
                                            <table datatable [dtOptions]="dtOptions"  class="row-border hover">
                                                <thead>
                                                  <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Picture</th>
                                                    <th>Action</th>
                    
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr *ngFor="let groups of activeUsers; let i = index">
                                                    <td>{{i+1}}</td>
                                                    <td>{{groups.name}}</td>
                                                    <td>{{groups.email}}</td>
                                                    <td>{{groups.phone}}</td>
                                                    <td>{{groups.picture}}</td>
                                                    <td> <button type="button" [routerLink]="['/dashbord/user-details', groups.id]"  class="btn btn-success btn-circle"><i
                                                      class="fas fa-eye"></i></button></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                        </div>
                                    
                      </ng-container>
                    </ng-container>
                    <ng-container  *ngIf="data$ | async as result" [ngSwitch]="result.dataState">
                      <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                        <app-spinner></app-spinner>
                      </ng-container>
                      <ng-container *ngSwitchCase="DataStateEnum.ERROR">
                       
  
                      </ng-container>
                      <ng-container *ngSwitchCase="DataStateEnum.LOADED">
                      
  
          
          
                                      <div class="tab-pane fade pt-3" id="profile-change-password">
                                          <table datatable [dtOptions]="dtOptions"  class="row-border hover">
                                              <thead>
                                                <tr>
                                                  <th>#</th>
                                                  <th>Name</th>
                                                  <th>Email</th>
                                                  <th>Phone</th>
                                                  <th>Picture</th>
                  
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr *ngFor="let group of desactiveUsers; let i = index">
                                                  <td>{{i+1}}</td>
                                                  <td>{{group.name}}</td>
                                                  <td>{{group.email}}</td>
                                                  <td>{{group.phone}}</td>
                                                  <td>{{group.picture}}</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                   
      
      
                                      </div>
                                  
                    </ng-container>
                  </ng-container>
                  </div>
                </div>
            </div>
        </div>
                    </div>
                
                </div>
            </div>
  
    
    </main>

</div>
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { Project } from '@/models/project';
import { ArrayType } from '@angular/compiler';
import {Component, OnInit} from '@angular/core';
import { PaymentService } from '@services/payment.service';
import { ProjectsService } from '@services/project.service';
import { element } from 'protractor';
import { Observable, map, startWith, catchError, of } from 'rxjs';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit{
    datas$: Observable<AppDataState<any[]>> |null = null;
    data$: Observable<AppDataState<any[]>> |null = null;
    enableProjet!: Project[];
    activeProjet!: any;
    addProjetModal: boolean=false;
    editProjetModal: boolean=false;
    submitError!: any;
    submitted: boolean=false;
    temporaire!:any;
    edit: boolean=false;
    addProjetValue:any;
    ProjetUpdate:any;
    invalid= false;
    activeProjets: any;
    disabledProjets!: any;
    dataLoad: boolean=true;
    balance: number;
    total:number =0;
    i:number =0;
    projects: any;
    readonly DataStateEnum=DataStateEnum;
    userinformation = JSON.parse(localStorage.getItem("user")!);
  id = this.userinformation.id;
    dtOptions: DataTables.Settings = {};
    constructor(private projetService:ProjectsService,private paymentService: PaymentService){ }
    async ngOnInit() {
        this.getProjet();
      }
    getProjet(){
        this.datas$ = this.projetService.getAll(true).pipe(
          map(data =>{
            this.activeProjets = data;
            console.log("dataaaaaaaaaaaaaaaaaaaaaaa", data);
            this.activeProjets.forEach(element => {
              console.log("elementtttttttttttttttttt", element);
            this.datas$ = this.paymentService.getBalance(element.publicKey).pipe(
            map(data =>{
              console.log("data2", data);
                let val =+ data.availableBalance;
                console.log('valueeee', val);
                this.total = this.total + val;
              console.log(this.total);
              return ({dataState:DataStateEnum.LOADED})
            }),
            startWith({dataState:DataStateEnum.LOADING}),
            catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
          );
    });
            
    return ({dataState:DataStateEnum.LOADED})
          }),
          startWith({dataState:DataStateEnum.LOADING}),
          catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
        );
    }
 
}

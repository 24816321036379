import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { principalBeneficiaryService } from '@/services/principalBeneficiary.service';
import { Beneficiary } from '@/models/beneficiary';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-beneficiary',
  templateUrl: './beneficiary.component.html',
  styleUrls: ['./beneficiary.component.scss']
})
export class BeneficiaryComponent {
  updateBeneficiary:any;
  addbeneficiaryvalue:any;
  enableBeneficiary!: Beneficiary[];
  activeBeneficiary!: Beneficiary[];
  addbeneficiryModal: boolean=false;
  editbeneficiryModal: boolean=false;
  submitError!: any;
  submitted: boolean=false;
  temporaire!:any;
  edit: boolean=false;
  dataLoad: boolean=true;
  data$: Observable<AppDataState<any[]>> |null = null;
  dtOptions: DataTables.Settings = {};

  datas$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  get designation(){return this.addbeneficiaryvalue.get('designation'); }
  get code(){return this.addbeneficiaryvalue.get('code'); }
  get designationupdate(){return this.updateBeneficiary.get('designation'); }
  get codeupdate(){return this.updateBeneficiary.get('code'); }
  constructor(
   private beneficiary: principalBeneficiaryService,config: NgbModalConfig, private modalService: NgbModal,private toastr: ToastrService) { }

 ngOnInit(){
  this.dtOptions = {
    pagingType: 'full_numbers'
  };
    this.addbeneficiaryvalue= new FormGroup({
    "designation": new FormControl("", [Validators.required]),
    "code": new FormControl("", [Validators.required]),
  })

  this.updateBeneficiary= new FormGroup({
    "id": new FormControl("", [Validators.required]),
    "designation": new FormControl("", [Validators.required]),
    "code": new FormControl("", [Validators.required]),
  })
  this.getActiveBeneficiary();
  this.getDisabledBeneficiary();

}
getActiveBeneficiary(){
  this.datas$ = this.beneficiary.getAll(true).pipe(
    map(data =>{
      this.activeBeneficiary = data;
      return ({dataState:DataStateEnum.LOADED})
    }),
    startWith({dataState:DataStateEnum.LOADING}),
    catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
  );
  // this.beneficiary.getAll(true)
  //  .subscribe(data => {
  //   this.activeBeneficiary = data;
  //   this.dataLoad = false;

  //  },
  //  (error)=>{
  //   console.log(error.message);
  //   this.dataLoad = false;

  //  })
  }

  getDisabledBeneficiary(){
      this.data$ = this.beneficiary.getAll(false).pipe(
    map(data =>{
      this.enableBeneficiary = data;
      return ({dataState:DataStateEnum.LOADED})
    }),
    startWith({dataState:DataStateEnum.LOADING}),
    catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
  );
//  this.beneficiary.getAll(false)
//     .subscribe(data => {
//      this.enableBeneficiary = data;
//      this.dataLoad = false;


//     },
//     (error)=>{
//      console.log(error.message);
//      this.dataLoad = false;

//     })

  }

applyFilterGlobal($event:any) {
  return ($event.target as HTMLInputElement).value
}

addBeneficiary(){
  if( this.addbeneficiaryvalue.valid && this.designation.value != null && this.code.value != null){
    this.submitted = true;
    console.log(this.addbeneficiaryvalue.value);
    this.beneficiary.create(this.addbeneficiaryvalue.value).subscribe((result)=>{
     this.submitted = false;
     this.closeDialofg()

     this.ngOnInit();
     this.toastr.success('The action has been performed successfully', 'Success');
    },
    (error)=>{
     this.submitted = false;
     this.submitError = error;
     this.toastr.error('Action Failed an errror has occured', 'Error');
    }
    )
  }
}
delete_(value:any) {
  this.submitted = true;

  this.beneficiary.disabled(value).subscribe(data => {
    this.submitted = false;
    this.closeDialofg()

    this.ngOnInit();
    this.toastr.warning('The action has been performed successfully', 'Success');

  },
  (error)=>{
    this.submitted = false;
    this.toastr.error('Action Failed an errror has occured', 'Error');
  })
}

editBeneficiary(){
  this.submitted = true;

    this.beneficiary.update(this.updateBeneficiary.value).subscribe(data => {
       this.submitted = false;
       this.closeDialofg()

      this.updateBeneficiary.reset();
      this.ngOnInit();
      this.toastr.success('The action has been performed successfully', 'Success');
    },
    (error)=>{
      this.submitted = false;
      this.toastr.error('Action Failed an errror has occured', 'Error');

    })
}
beneficiaryrestore(value:any) {
  this.submitted = true;

  this.beneficiary.restoreState(value.id, true).subscribe(data => {
    this.submitted = false;
    this.closeDialofg()

    this.ngOnInit();
    this.toastr.success('The action has been performed successfully', 'Success');
  },
  (error)=>{
    this.submitted = false;
    this.toastr.error('Action Failed an errror has occured', 'Error');
  })
}

showDialogVal(content:any,val:any) {
  this.edit = true;
  this.temporaire = {...val};
  this.modalService.open(content);


}
showDialog(val: any){
  this.modalService.open(val);
}
closeDialofg(){
  this.modalService.dismissAll()
}

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Currency } from '../models/currency';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class CurrencyService extends BaseService<Currency> {

  constructor(httpCient: HttpClient) {
    super(
      httpCient,
      "currencies"
    );
  }
}

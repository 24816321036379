<div class="content">
    <div class="contenu d-flex justify-content-center align-items-center">

        <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
            <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                <app-spinner></app-spinner>
            </ng-container>
            <ng-container *ngSwitchCase="DataStateEnum.ERROR">
                <div class="card p-3"> 
                    <div class="mb-4 text-center">
                        <img src="../../../../assets/images/error.png" width="75px" height="75px" alt="" srcset="">
                    </div>
                    <div class="text-center">
                        <h1>We encounter an error !</h1>
                        <p>{{result.errorMessage}}</p>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="DataStateEnum.LOADED">
                <div class="card bg-white mycard" style="width: 40%;">
                    <div class="card-header">
                        <div class="bg-white shadow-sm pt-0 pl-0 pr-0 pb-0">
                            <!-- Credit card form tabs -->
                            <div class="entete col-12 pl-0 pr-0 d-flex">
                                <div class="subs d-flex">
                                  <div class="col-8">
                                        <div class="d-flex tec" style="font-weight: bold;">Item Name:
                                            <p style="font-weight:600;margin-left:10px"> {{ data.itemName}}</p>
                                        </div>
                                        <div class="d-flex tec" style="font-weight: bold;">Reference:
                                            <p style="font-weight: 600;margin-left:10px"> {{ data.itemRef}}</p>
                                        </div>
                                        <div class="d-flex tec" style="font-weight: bold;">Description:
                                            <p style="font-weight: 600;margin-left:10px"> {{ data.description}}</p>
                                        </div>

                                    </div>
                                    <div class="col-5" style="text-align: end;">
                                        <div class="d-flex tec" style="font-weight: bold;">Amount:
                                            <p style="font-weight: 600;margin-left:10px"> {{ data.amount}}</p>
                                        </div>
                                        <div class="d-flex tec" style="font-weight: bold;">Currency:
                                            <p style="font-weight: 600;margin-left:10px"> {{ data.currencyCode}}</p>
                                        </div>
                                        <div class="d-flex tec" style="font-weight: bold;">Country:
                                            <p style="font-weight: 600;margin-left:10px"> {{ data.countryCode}}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <ul class="nav nav-tabs nav-tabs-bordered item">

                                <li class="nav-item" style="width: 33.3%;"> <a class="nav-link" style="background-color: lightgray;" disabled data-bs-toggle="tab" data-bs-target="#cartB"><i class="fas fa-credit-card mr-2"></i>Card</a></li>
                                <li class="nav-item " style="width: 33.3%;"> <a class="nav-link active" data-bs-toggle="tab" data-bs-target="#mobil"><i class="fas fa-mobile mr-2"></i>Mobile</a></li>

                                <li class="nav-item" style="width: 33.4%;"> <a disabled class="nav-link" style="background-color: lightgray;" data-bs-toggle="tab" data-bs-target="#paypal"><i class="fab fa-paypal mr-2"></i>Paypal</a></li>

                            </ul>
                        </div>
                        <div class="tab-content pt-2">

                            <div class="tab-pane fade show active profile-overview" id="mobil">
                                <ul class="nav nav-tabs nav-tabs-bordered col-12 justify-content-center text-align-center d-flex ">

                                    <li class="nav-item mob" id="mymtn" (click)="getActive(mtn)">
                                        <a class="nav-link" data-bs-toggle="tab" data-bs-target="#mtn">

                                            <img src="../../../assets/images/mtn.png" width="60px" height="33px" alt="" srcset="">
                                        </a>
                                    </li>
                                    <li class="nav-item mob" id="myorange" (click)="getActive(orange)">
                                        <a class="nav-link" data-bs-toggle="tab" data-bs-target="#orange">
                                            <img src="../../../assets/images/orange.png" width="60px" height="33px" alt="" srcset="">

                                        </a>
                                    </li>

                                </ul>

                                <div *ngIf="mobile==orange">
                                    <div class="form-group">
                                        <label for="phone">Numero de Telephone</label>
                                        <input type="number" placeholder="Enter phone number" class="form-control">

                                    </div>
                                    <div>
                                        <p>1. Cliquer sur le bouton valider puis composer ou cliquer sur le numero suivant <a href="">#150*50#</a></p>
                                    </div>
                                    <div>
                                        <p>2. Entrer votre code secret</div>
                                    <div> <button class="btn btn-danger" style="margin-left: 20px;margin-right: 20px;">Annuler</button> <button class="btn btn-warning">Valider</button></div>
                                </div>
                                <div *ngIf="mobile==mtn">
                                    <form [formGroup]="paymentInfo">
                                        <input type="number" formControlName="amount" [(ngModel)]="data.amount" style="display: none;" class="form-control">
                                        <input type="text" formControlName="currency" [(ngModel)]="data.currencyCode" style="display: none;" class="form-control">
                                        <input type="text" formControlName="paymentKey" [(ngModel)]="data.paymentKey" style="display: none;" class="form-control">

                                        <div class="form-group" formGroupName="payer">
                                            <label for="phone">Numero de Telephone</label>
                                            <input type="number" formControlName="partyId" placeholder="Enter phone number" class="form-control">

                                        </div>
                                        <div *ngIf="paymentError">
                                            <span class="error fs-6 text-danger mx-2 my-2">{{paymentError}}</span>
                                        </div>
                                        <div>
                                            <p>1. Cliquer sur le bouton valider puis composer ou cliquer sur le numero suivant <a href="">*126#</a></p>
                                        </div>
                                        <div>
                                            <p>2. Entrer votre code secret</div>
                                        <div>
                                            <div *ngIf="status==false">
                                                <button class="btn btn-danger" style="margin-left: 20px;margin-right: 20px;">Annuler</button> <button (click)="sendPayment()" class="btn btn-warning">Valider</button>

                                            </div>
                                            <div *ngIf="status==true" style="width: 100%;justify-content: center;align-items: center;display: flex;">
                                                <span class="loader"></span>

                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                            <div class="tab-pane fade pt-3" id="mobil">




                            </div>
                        </div>
                        <div class="tab-content pt-2">

                            <div class="tab-pane fade show active profile-overview" id="paypal">

                            </div>



                        </div>
                        <div class="tab-content pt-2">

                            <div class="tab-pane fade show active profile-overview" id="profile-edit">


                            </div>


                            <div class="tab-pane fade pt-3" id="profile-change-password">

                            </div>
                        </div>

                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
<main class="content">
    <div class="pagetitle container-fluid">
        <h1>Demo Payement</h1>
        <nav style="display: flex;justify-content: space-between;">
            <div>
                <ol class="breadcrumb">

                    <li class="breadcrumb-item"><a routerLink="/dashbord">Home</a></li>
                    <li class="breadcrumb-item active">Demo payement</li>

                </ol>
            </div>


        </nav>

    </div>
    <form [formGroup]="testprojectValue">
        <div class="container-fluid">
            <div class="row">
                <div class="col-8 mx-auto">
                    <div class="card card-primary card-outline">
                        <a class="d-block w-100">
                            <div class="card-header">
                                <h4 class="card-title w-100">
                                    DEMO PAYEMENT
                                </h4>
                            </div>


                        </a>
                        <div class="">
                            <div class="card-body table-responsive p-0">
                                <table class="table table-striped table-valign-middle">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <div class="input-field" [class.error]="firstName.invalid && firstName.hasError('required','type')">
                                                        <label class="form-label" for="form3Example3">First Name<span
                                                            class="text-danger me-1">*</span></label>
                                                        <input type="text" formControlName="firstName" placeholder="firstName" required id="form3Example3" class="form-control" />
                                                        <div *ngIf="firstName.invalid && (firstName.touched || firstName.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="firstName.errors?.required">*The name is
                                                            required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <div class="input-field" [class.error]="lastName.invalid && lastName.hasError('required','type')">
                                                        <label class="form-label" for="form3Example3">lastName<span
                                                                class="text-danger me-1">*</span></label>
                                                        <input type="text" formControlName="lastName" placeholder="lastName" required id="form3Example3" class="form-control" />
                                                        <div *ngIf="lastName.invalid && (lastName.touched || lastName.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="lastName.errors?.required">*The name is
                                                                required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <div class="input-field" [class.error]="email.invalid && email.hasError('required','type')">
                                                        <label class="form-label" for="form3Example3">email<span
                                                            class="text-danger me-1">*</span></label>
                                                        <input type="email" formControlName="email" placeholder="email" required id="form3Example3" class="form-control" />
                                                        <div *ngIf="email.invalid && (email.touched || email.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="email.errors?.required">*The email
                                                            is
                                                            required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <div class="input-field" [class.error]="phone.invalid && phone.hasError('required','type')">
                                                        <label class="form-label" for="form3Example3">phone<span
                                                            class="text-danger me-1">*</span></label>
                                                        <input type="tel" minlength="9" maxlength="13" formControlName="phone" placeholder="6514214778" required id="form3Example3" class="form-control" />
                                                        <div *ngIf="phone.invalid && (phone.touched || phone.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="phone.errors?.required">*The phone
                                                            is required</span>
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="phone.errors?.minlength">*The phone
                                                                have 8 number minimum</span>
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="phone.errors?.maxlength">*The phone
                                                                    have 13 number max</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <div class="input-field" [class.error]="countryCode.invalid && countryCode.hasError('required','type')">
                                                        <label class="form-label" for="form3Example3">country code<span
                                                            class="text-danger me-1">*</span></label>
                                                        <select formControlName="countryCode" class="form-control" id="operators">
                                                                <option value="CM">Cameroun(CM)</option>
                                                            </select>

                                                        <div *ngIf="countryCode.invalid && (countryCode.touched || countryCode.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="countryCode.errors?.required">*The countryCode is
                                                            required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <div class="input-field">
                                                        <label class="form-label" for="form3Example3">Name of item<span
                                                            class="text-danger me-1">*</span></label>
                                                        <input type="text" formControlName="itemName" placeholder="formation" required id="form3Example3" class="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <div class="input-field">
                                                        <label class="form-label" for="form3Example3">Reference of item<span
                        class="text-danger me-1">*</span></label>
                                                        <input type="text" formControlName="itemRef" placeholder="12345f" required id="form3Example3" class="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <div class="input-field" [class.error]="description.invalid && description.hasError('required','type')">
                                                        <label class="form-label" for="form3Example3">description<span
                    class="text-danger me-1">*</span></label>
                                                        <input type="text" formControlName="description" placeholder="description" required id="form3Example3" class="form-control" />
                                                        <div *ngIf="description.invalid && (description.touched || description.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="description.errors?.required">*The description is
                    required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <div class="input-field">
                                                        <label class="form-label" for="form3Example3">Amount<span
                    class="text-danger me-1">*</span></label>
                                                        <input type="number" min="50" formControlName="amount" placeholder="200" required id="form3Example3" class="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <div class="input-field">
                                                        <label class="form-label" for="form3Example3">Language<span
                    class="text-danger me-1">*</span></label>
                                                        <select formControlName="lang" class="form-control" id="operators">
                <option value="fr">french</option>
                <option value="en">english</option>
            </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <div class="input-field" [class.error]="currencyCode.invalid && currencyCode.hasError('required','type')">

                                                        <label>Selected currency<span class="text-danger me-1">*</span></label>
                                                        <div class="d-flex">
                                                            <select formControlName="currencyCode" required class="form-control" id="currencyCode">
                                                    <option value="XAF">XAF</option>
                                                  

                                                </select>


                                                        </div>
                                                        <div *ngIf="currencyCode.invalid && (currencyCode.touched || currencyCode.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="currencyCode.errors?.required">*The
                                                    currency is required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <div class="input-field" [class.error]="publicKey.invalid && publicKey.hasError('required','type')">

                                                        <label>Select a project</label>
                                                        <div class="d-flex">
                                                            <select formControlName="publicKey" class="form-control" id="operators">
                                                    <option></option>
                                                    <option *ngFor="let items of activeProjects"
                                                        value="{{items.publicKey}}">{{items.name}}</option>

                                                </select>

                                                            <div *ngIf="dataLoadBeneficiary" class="spinner-border spinner-border-sm" role="status">
                                                            </div>
                                                        </div>
                                                        <div *ngIf="publicKey.invalid && (publicKey.touched ||publicKey.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="publicKey.errors?.required">*The
                                                    project is required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>






                                        <div class="col-8 mx-auto" style="align-items: end;justify-content: flex-end;display: flex;">
                                            <button type="button" class="btn btn-primary" (click)="addproject()" [disabled]="submitted">Save<div
                                                    *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>

                                        </div>
                                    </div>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </form>

</main>
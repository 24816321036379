<section class="content">
    <div class="container-fluid">
        <main id="main" class="main">
            <div class="pagetitle">
                <h1>Profile</h1>
                <nav>

                    <ol class="breadcrumb col-md-3">
                        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                        <li class="breadcrumb-item">Users</li>
                        <li class="breadcrumb-item active">Profile</li>
                    </ol>
                </nav>
            </div>
            <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
                <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                    <app-spinner></app-spinner>
                </ng-container>
                <ng-container *ngSwitchCase="DataStateEnum.ERROR">


                </ng-container>
                <ng-container *ngSwitchCase="DataStateEnum.LOADED">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-12">
                            <section class="section profile">
                                <div class="row">
                                    <div class="col-xl-4">
                                        <div class="card" style="background-color: white;padding: 0px !important;">
                                            <div class="card-body profile-card pt-4 d-flex flex-column align-items-center" style="padding: 10px !important;">

                                                <img src="../../../assets/images/profil.png" alt="Profile" class="rounded-circle" style="padding-bottom: 10px;">
                                                <h3>{{userinfo.name}}</h3>
                                                <h3>{{userinfo.role}}</h3>
                                                <div class="row" style="width: 100%;">
                                                    <div class="row mybox" style="width: 100%;">
                                                        <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                            <div class="size11"><b> Projects: </b></div>
                                                            <div class="size12"> <b>{{userinfo.listProjects.length}}</b> </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mybox" style="width: 100%;">
                                                        <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                            <div class="size11"><b> Currency: </b></div>
                                                            <div class="size12"> <b>{{userinfo.defaultCurrency}}</b> </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>
                                                <div class="row">
                                                    <!-- <button class="btn btn-primary" type="button">change picture</button> -->
                                                    <button class="btn btn-warning" (click)="changePassword()" [disabled]="submitted" type="button">change password<div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-xl-8">
                                        <div class="card" style="background-color: white;">
                                            <div class="card-body pt-3">
                                                <ul class="nav nav-tabs nav-tabs-bordered">

                                                    <li class="nav-item"> <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-edit"> Profile</button></li>
                                                </ul>
                                                <div class="tab-content pt-2">

                                                    <div class="tab-pane fade show active profile-overview" id="profile-edit">
                                                        <form [formGroup]="updateUsers">
                                                            <div class="row mb-3">
                                                                <label for="name" class="col-sm-3 col-form-label">Name</label>
                                                                <div class="col-sm-9">
                                                                    <input type="text" formControlName="id" style="display: none;" class="form-control" id="name" [(ngModel)]="userinfo.id">
                                                                    <input type="text" *ngIf="edit==true" disabled="true" class="form-control" id="name" value="{{userinfo.name}}">

                                                                    <input type="text" *ngIf="edit==false" formControlName="name" class="form-control" id="name" [(ngModel)]="userinfo.name">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <label for="inputEmail" class="col-sm-3 col-form-label">Email</label>
                                                                <div class="col-sm-9">
                                                                    <input type="email" *ngIf="edit==true" disabled="true" class="form-control" id="inputEmail" value="{{userinfo.email}}">

                                                                    <input type="email" *ngIf="edit==false" formControlName="email" class="form-control" id="inputEmail" [(ngModel)]="userinfo.email">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <label for="telephone" class="col-sm-3 col-form-label">Phone Number</label>
                                                                <div class="col-sm-9">
                                                                    <input type="number" *ngIf="edit==true" disabled="true" class="form-control" id="phone" value="{{userinfo.phone}}">

                                                                    <input type="number" *ngIf="edit==false" formControlName="phone" class="form-control" id="phone" [(ngModel)]="userinfo.phone">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <label for="ville" class="col-sm-3 col-form-label">City</label>
                                                                <div class="col-sm-9">
                                                                    <input type="text" disabled="true" *ngIf="edit==true" class="form-control" id="ville" value="{{userinfo.city}}">

                                                                    <input type="text" *ngIf="edit==false" formControlName="city" class="form-control" id="ville" [(ngModel)]="userinfo.city">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <label for="Address" class="col-sm-3 col-form-label">Address</label>
                                                                <div class="col-sm-9">
                                                                    <input type="text" disabled="true" *ngIf="edit==true" class="form-control" id="address" value="{{userinfo.address}}">

                                                                    <input type="text" *ngIf="edit==false" formControlName="address" class="form-control" id="address" [(ngModel)]="userinfo.address">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <label for="birthday" class="col-sm-3 col-form-label">Birth day</label>
                                                                <div class="col-sm-9">
                                                                    <input type="date" *ngIf="edit==true" disabled="true" class="form-control" id="address" value="{{userinfo.birthday}}">

                                                                    <input type="date" *ngIf="edit==false" formControlName="birthday" class="form-control" id="address" [(ngModel)]="userinfo.birthday">
                                                                </div>
                                                            </div>
                                                            <div class="d-flex flex-column align-items-end"><button class="btn btn-primary" (click)="edition()" *ngIf="edit==true" type="button">Update my profile</button>
                                                                <div style="display: flex;">
                                                                    <button class="btn btn-secondary" style="color: white;" (click)="annuler()" *ngIf="edit==false" type="button">Annuler</button>

                                                                    <button class="btn btn-primary" (click)="add()" *ngIf="edit==false" [disabled]="submitte" type="button">Update<div *ngIf="submitte" class="spinner-border spinner-border-sm" role="status"></div></button>

                                                                </div>

                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

        </main>
    </div>
</section>
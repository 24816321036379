import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from './base.service';
import { Organization } from '../models/organization';


@Injectable({
  providedIn: 'root'
})
export class OrganizationService extends BaseService<Organization> {

  constructor(private httpCient: HttpClient) {
    super(
      httpCient,
      "organizations"
    );
  }
}

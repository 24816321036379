import { AppDataState, DataStateEnum } from '@/State/load.state';
import { Currency } from '@/models/currency';
import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyService } from '@services/currency.service';
import { FinanceService } from '@services/finance.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, map, startWith, catchError, of } from 'rxjs';

@Component({
  selector: 'app-payement-history',
  templateUrl: './payement-history.component.html',
  styleUrls: ['./payement-history.component.scss']
})
export class PayementHistoryComponent {
  data$: Observable<AppDataState<any[]>> |null = null;
  dtOptions: DataTables.Settings = {};

  datas$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  userinformation = JSON.parse(localStorage.getItem("user")!);
  id = this.userinformation.id;
  submitError!: any;
  submitted: boolean=false;
  dataLoad: boolean=true;
  finance!:any;
  temporaire!:any;
  edit: boolean=false;
  adddeleteModal: boolean=false;
  
  constructor(
    private financeService: FinanceService,
    private router: Router,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private toastr: ToastrService

  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers'
    };
    // create value form



    this.getUsersFinance();
  }
  

  

  getUsersFinance() {
    this.datas$ = this.financeService.getfinancesOwner(this.id).pipe(
      map(data =>{
        this.finance = data;
        console.log(this.finance)
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );
  }
  
}

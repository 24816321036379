import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { AppDataState, DataStateEnum } from '@/State/load.state';

import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Project } from '@/models/project';
import { ProjectsService } from '@services/project.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-projets',
  templateUrl: './projets.component.html',
  styleUrls: ['./projets.component.scss'],
  providers: [NgbModalConfig, NgbModal]

})
export class ProjetsComponent {
  datas$: Observable<AppDataState<any[]>> |null = null;
  data$: Observable<AppDataState<any[]>> |null = null;
  enableProjet!: Project[];
  activeProjet!: any;
  addProjetModal: boolean=false;
  editProjetModal: boolean=false;
  submitError!: any;
  submitted: boolean=false;
  temporaire!:any;
  edit: boolean=false;
  addProjetValue:any;
  ProjetUpdate:any;
  invalid= false;
  activeProjets!:any;
  disabledProjets!: any;
  dataLoad: boolean=true;
  readonly DataStateEnum=DataStateEnum;
  dtOptions: DataTables.Settings = {};

  get name(){return this.addProjetValue.get('name'); }
  get address(){return this.addProjetValue.get('address'); }
  get city(){return this.addProjetValue.get('city'); }
  get email(){return this.addProjetValue.get('email'); }
  get phone(){return this.addProjetValue.get('phone'); }
  get website(){return this.addProjetValue.get('website'); }
  get document(){return this.addProjetValue.get('document'); }


  get nameupdate(){return this.ProjetUpdate.get('name'); }
  get addressupdate(){return this.ProjetUpdate.get('address'); }
  get cityupdate(){return this.ProjetUpdate.get('city'); }
  get emailupdate(){return this.ProjetUpdate.get('email'); }
  get phoneupdate(){return this.ProjetUpdate.get('phone'); }
  get websiteupdate(){return this.ProjetUpdate.get('website'); }
  get documentupdate(){return this.ProjetUpdate.get('document'); }


  constructor(
    private route: Router,private projetService:ProjectsService,config: NgbModalConfig, private modalService: NgbModal,private toastr: ToastrService) { 
      config.backdrop = 'static';
		config.keyboard = false;
    }

  async ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers'
    };
    this.addProjetValue= new FormGroup({
      "name": new FormControl("", [Validators.required]),
      "address": new FormControl("", [Validators.required]),
      "city": new FormControl(""),
      "email": new FormControl(""),
      "phone": new FormControl(""),
      "website": new FormControl(""),
      "document": new FormControl(""),


    })

    this.ProjetUpdate= new FormGroup({
      "id": new FormControl("", [Validators.required]),
      "name": new FormControl("", [Validators.required]),
      "address": new FormControl("", [Validators.required]),
      "city": new FormControl(""),
      "email": new FormControl(""),
      "phone": new FormControl(""),
      "website": new FormControl(""),
      "document": new FormControl(""),
    })

    this.getProjet();
    this.getDisabledProjet();
  }


  getProjet(){

    this.datas$ = this.projetService.getAll(true).pipe(
      map(data =>{
        this.activeProjets = data;
        console.log(this.activeProjets)
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );

    // this.Projet.getAll(true)
    //  .subscribe(data => {
    //   this.activeProjet = data;
    //   console.log(this.activeProjet)

    //   this.dataLoad = false;


    //  },
    //  (error)=>{
    //   console.log(error.message);
    //   this.dataLoad = false;

    //  })
    
    }

    getDisabledProjet(){
    this.projetService.getAll(false).subscribe(data => {
       this.enableProjet = data;
      console.log(this.enableProjet)

       },
       (error)=>{
        console.log(error.message);

      })

    }
  applyFilterGlobal($event:any) {
    return ($event.target as HTMLInputElement).value
  }
  showDialogadd() {
    this.addProjetModal = true;
  }

  enabledialog(){
    this.addProjetModal = false;

    this.editProjetModal = false;

  }

  delete_(value:any) {
    this.submitted = true;

    this.projetService.deleteProject(value).subscribe(data => {
      this.closeDialofg()
      this.submitted = false;
      this.ngOnInit();
      this.toastr.warning('The action has been performed successfully', 'Success');

    },
    (error)=>{
      this.submitted = false;
      this.toastr.error('Action Failed an errror has occured', 'Error');
    })
  }

  restoreProjet(value:any){
    this.submitted = true;

    this.projetService.restoreState(value.id,true).subscribe(data => {
      this.ngOnInit();

      this.closeDialofg()
      this.submitted = false;
      this.toastr.success('The action has been performed successfully', 'Success');


    },
    (error)=>{
      this.submitted = false;
      this.toastr.error('Action Failed an errror has occured', 'Error');
    })
  }
  editProjet(){
    this.submitted = true;
    console.log(this.ProjetUpdate.value)
      this.projetService.update(this.ProjetUpdate.value).subscribe(data => {
        this.enabledialog();
        this.submitted = false;

        this.ProjetUpdate.reset();
        this.closeDialofg()

        this.ngOnInit();
        this.toastr.success('The action has been performed successfully', 'Success');


      },
      (error)=>{
        this.submitted = false;
        this.submitError =error
        this.toastr.error('Action Failed an errror has occured', 'Error');
      })
  }
  open(content: any) {
		this.modalService.open(content);
	}
  closeDialofg(){
    this.modalService.dismissAll()
  }
  showDialogedit(content:any,val:any) {
    this.edit = true;
    this.temporaire = {...val};
		this.modalService.open(content);


  }
  getClassOf(val) {
    if (val === "CONFIRM") {
      return 'btn btn-success';
    } 
     else {
      return 'btn btn-warning'
    }
  }
  activate(val) {
    if (val === "CONFIRM") {
      return false;
    } 
     else {
      return true;
    }
  }
  activation(value:any) {
    this.submitted = true;

    this.projetService.validate(value).subscribe(data => {
      this.closeDialofg()
      this.submitted = false;
      this.ngOnInit();
      this.toastr.success('The action has been performed successfully', 'Success');

    },
    (error)=>{
      this.submitted = false;
      this.toastr.error('Action Failed an errror has occured', 'Error');
    })
  }
}

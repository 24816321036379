<main id="main" class="main">
    <div class="pagetitle">
        <h1>Gains ou Retrait</h1>
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item">Finances</li>
                <li class="breadcrumb-item active">Earn and withdraw</li>
            </ol>
        </nav>
    </div>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-5">
                    <div class="mb-3">
                        <div class="card card-primary card-outline">
                            <div class="card-header border-0">
                                <h3 class="card-title">Generals informations</h3>
                            </div>
                            <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center border-bottom mb-3">
                                    <div class="text-success">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-database-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M3.904 1.777C4.978 1.289 6.427 1 8 1s3.022.289 4.096.777C13.125 2.245 14 2.993 14 4s-.875 1.755-1.904 2.223C11.022 6.711 9.573 7 8 7s-3.022-.289-4.096-.777C2.875 5.755 2 5.007 2 4s.875-1.755 1.904-2.223Z" />
                                            <path
                                                d="M2 6.161V7c0 1.007.875 1.755 1.904 2.223C4.978 9.71 6.427 10 8 10s3.022-.289 4.096-.777C13.125 8.755 14 8.007 14 7v-.839c-.457.432-1.004.751-1.49.972C11.278 7.693 9.682 8 8 8s-3.278-.307-4.51-.867c-.486-.22-1.033-.54-1.49-.972Z" />
                                            <path
                                                d="M2 9.161V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13s3.022-.289 4.096-.777C13.125 11.755 14 11.007 14 10v-.839c-.457.432-1.004.751-1.49.972-1.232.56-2.828.867-4.51.867s-3.278-.307-4.51-.867c-.486-.22-1.033-.54-1.49-.972Z" />
                                            <path
                                                d="M2 12.161V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16s3.022-.289 4.096-.777C13.125 14.755 14 14.007 14 13v-.839c-.457.432-1.004.751-1.49.972-1.232.56-2.828.867-4.51.867s-3.278-.307-4.51-.867c-.486-.22-1.033-.54-1.49-.972Z" />
                                        </svg></div>
                                    <div class="d-flex flex-column text-right">
                                        <span class="font-weight-bold">
                                                <i class="ion ion-android-arrow-up text-success"></i> 1,500.00 XAF
                                            </span>
                                        <span class="text-muted">
                                                <span class="badge bg-warning float-left"
                                                    title="THIS AMOUNT IS AN ESTIMATE OF YOUR TOTAL BALANCE. NOTE THAT IT MAY CHANGE ACCORDING TO THE EXCHANGE RATE">i</span> Estimation totale du solde</span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center border-bottom mb-3">
                                    <p class="text-warning text-xl">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-bar-chart-line" viewBox="0 0 16 16">
                                                <path
                                                    d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2zm1 12h2V2h-2v12zm-3 0V7H7v7h2zm-5 0v-3H2v3h2z" />
                                            </svg>
                                    </p>
                                    <p class="d-flex flex-column text-right">
                                        <span class="font-weight-bold">
                                                <i class="ion ion-android-arrow-up text-warning"></i>
                                                250.00 XAF </span>
                                        <span class="text-muted">
                                                <span class="badge bg-warning float-left"
                                                    title="THIS AMOUNT IS AN ESTIMATE OF YOUR CURRENT BALANCE. NOTE THAT IT MAY CHANGE ACCORDING TO THE EXCHANGE RATE">i</span> Estimation du solde courant</span>
                                    </p>
                                </div>
                                <div class="d-flex justify-content-between align-items-center border-bottom mb-3">
                                    <p class="text-warning text-xl">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-hand-index-fill" viewBox="0 0 16 16">
                                                <path
                                                    d="M8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v5.34l-1.2.24a1.5 1.5 0 0 0-1.196 1.636l.345 3.106a2.5 2.5 0 0 0 .405 1.11l1.433 2.15A1.5 1.5 0 0 0 6.035 16h6.385a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5.114 5.114 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.632 2.632 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002z" />
                                            </svg>
                                    </p>
                                    <p class="d-flex flex-column text-right">
                                        <span class="font-weight-bold">
                                                <i class="ion ion-android-arrow-up text-warning"></i>
                                                0.00 XAF </span>
                                        <span class="text-muted">
                                                <span class="badge bg-warning float-left"
                                                    title="THIS AMOUNT IS AN ESTIMATE OF YOUR WITHDRAWALS PENDING TREATMENT.">i</span> Payement en attente de tratement</span>
                                    </p>
                                </div>
                                <div class="d-flex justify-content-between align-items-center mb-0">
                                    <p class="text-danger text-xl">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-shuffle" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                    d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z" />
                                                <path
                                                    d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z" />
                                            </svg>
                                    </p>
                                    <p class="d-flex flex-column text-right">
                                        <span class="font-weight-bold">
                                                <i class="ion ion-android-arrow-down text-danger"></i> 11 </span>
                                        <span class="text-muted">Nombre de payement</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="card card-primary card-outline" style="width:500px;overflow-x: scroll;padding-bottom:10px;">
                            <div class="card-header d-flex justify-content-between align-items-center border-bottom mb-3" style="cursor: move;">

                                <h3 class="card-title">
                                    Earn for all project
                                </h3>
                                <button type="button" class="btn btn-sm btn-primary" data-toggle="collapse" data-target="#earn" aria-controls="earn">
                                    <!--a class="btn btn-sm btn-tool">
                                        <i class="fas fas fa-eye"></i>
                                    </a-->
                                   <i class="fas fas fa-eye"></i> View  

                                </button>
                            </div>
                            <!-- /.card-header -->
                            <div class="card-body collapse fade" id="earn">

                                <table class="table table-head-fixed text-nowrap">
                                    <thead>
                                        <tr>
                                            <th>Operator</th>
                                            <th>Total earn</th>
                                            <th>Remaining</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <img width="45" src="https://paymooney.com/Rapid/img/operateur/orange-money.png" alt="Paypal"> Orange Money CM
                                            </td>
                                            <td>600.0 XAF</td>
                                            <td style="color: #007bff;font-weight: bold;">50.0 XAF</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img width="45" src="https://paymooney.com/Rapid/img/operateur/momo.png" alt="Paypal"> Mobile Money CM
                                            </td>
                                            <td>900.0 XAF</td>
                                            <td style="color: #007bff;font-weight: bold;">200.0 XAF</td>
                                        </tr>
                                        <tr>
                                            <td>Total estimate</td>
                                            <td><b>1,500.00 XAF</b></td>
                                            <td style="color: #007bff;font-weight: bold;"><b>250.00 XAF</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- /.card-body -->

                        </div>
                    </div>

                </div>
                <div class="col-md-7">
                    <div class="row">
                        <div class="col-12">
                            <div class="card card-primary card-outline">

                                <div class="card-header">
                                    <form accept-charset="UTF-8" enctype="multipart/form-data" role="form">
                                        <input type="hidden" name="_token" value="uPgMO2kDmnKz0iwL0rl92jsxxJpEHeDLU7Jtb3R7">
                                        <div class="form-group row">
                                            <label for="inputSkills" class=" col-sm-2 col-form-label">Project</label>
                                            <div class="col-sm-6">
                                                <select class="custom-select form-control" name="project" required="">
                                                   <option value="">test</option>
                                                </select>
                                            </div>
                                            <div class="col-sm-4">
                                                <button type="submit" class="btn btn-primary" id="btsubmit">Gain et Retrait</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <!-- /.card-header -->

                                <!-- /.card-body -->

                            </div>
                            <!-- /.card -->
                        </div>
                    </div>


                </div>
            </div>


        </div>

    </section>


</main>
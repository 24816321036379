<div class="container-fluid">
    <main>
        <div class="pagetitle">
            <h1>Project</h1>
            <nav style="display: flex;justify-content: space-between;">

                <div>
                    <ol class="breadcrumb">

                        <li class="breadcrumb-item"><a routerLink="/dashbord">Home</a></li>
                        <li class="breadcrumb-item active">Project</li>





                    </ol>
                </div>

            </nav>
            <div class="card" style="background-color: white;">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-12">
                            <div class="card-body pt-3">
                                <ul class="nav nav-tabs nav-tabs-bordered">

                                    <li class="nav-item"> <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-edit">Active Project</button></li>

                                    <li class="nav-item"> <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password">Disabled Project</button></li>
                                </ul>
                                <div class="tab-content pt-2">
                                    <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
                                        <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                                            <app-spinner></app-spinner>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="DataStateEnum.ERROR">


                                        </ng-container>
                                        <ng-container *ngSwitchCase="DataStateEnum.LOADED">

                                            <div class="tab-pane fade show active profile-overview" id="profile-edit">
                                                <table [dtOptions]="dtOptions" datatable class="row-border hover">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Name</th>
                                                            <th>Public Key</th>
                                                            <th>Participants</th>
                                                            <th>Status</th>
                                                            <th>CreatedBy</th>
                                                            <th>Actions</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let groups of activeProjets; let i = index">
                                                            <td>{{i+1}}</td>
                                                            <td>{{groups.name}}</td>
                                                            <td>{{groups.publicKey}}</td>

                                                            <td><button type="button" [routerLink]="['/dashbord/user-project', groups.id]" class="btn btn-primary "><i
                                                                class="bi bi-people-fill"> users{{groups.listUsers.length}}</i></button></td>
                                                            <td><button [ngClass]="getClassOf(groups.status)">{{groups.status}}</button></td>
                                                            <td>{{groups.createdBy.name}}</td>
                                                            <td>
                                                                <button type="button" [routerLink]="['/dashbord/project-details', groups.id]" class="btn btn-success btn-circle"><i
                                  class="fas fa-eye"></i></button>
                                                                <button type="button" [routerLink]="['/dashbord/update-project', groups.id]" class="btn btn-warning btn-circle"><i
                                  class="bi bi-pencil-square"></i></button>
                                                                <button type="button" (click)="showDialogedit(delete,groups)" class="btn btn-danger btn-circle"><i
                                  class="fas fa-trash"></i></button>
                                                                <button *ngIf="activate(groups.status)" type="button" (click)="showDialogedit(active,groups)" class="btn btn-success" style="margin: 10px;"><i
                                    class="bi bi-check-all btn-circle">activate</i> </button>
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="tab-pane fade pt-3" id="profile-change-password">
                                                <table datatable [dtOptions]="dtOptions" class="row-border hover">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Name</th>
                                                            <th>Public Key</th>
                                                            <th>CreatedBy</th>

                                                            <th>Actions</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let group of enableProjet; let i = index">
                                                            <td>{{i+1}}</td>
                                                            <td>{{group.name}}</td>
                                                            <td>{{group.publicKey}}</td>
                                                            <td>{{group.createdBy.name}}</td>
                                                            <td>
                                                                <button type="button" (click)="showDialogedit(restore,group)" class="btn btn-warning btn-circle"><i
                                      class="fas fa-recycle" ></i></button>


                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>



                                            </div>
                                        </ng-container>
                                    </ng-container>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>


    </main>

</div>


<ng-template #delete let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Delete Organization</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="bi bi-x-lg"> </i></button>
    </div>
    <div class="modal-body">
        <p>Are you sure that you want to Delete {{temporaire?.name}}?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-danger" (click)="delete_(temporaire)" [disabled]="submitted">Delete<div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>

    </div>
</ng-template>
<ng-template #restore let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Restore Organization</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="bi bi-x-lg"> </i></button>
    </div>
    <div class="modal-body">
        <p>Are you sure that you want to Restore {{temporaire?.name}}?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-warning" (click)="restoreProjet(temporaire)" [disabled]="submitted">Restore<div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>
    </div>
</ng-template>
<ng-template #active let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Activate project</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="bi bi-x-lg"> </i></button>
    </div>
    <div class="modal-body">
        <p>Are you sure that you want to Activate {{temporaire?.name}}?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-danger" (click)="activation(temporaire)" [disabled]="submitted">confirm<div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>

    </div>
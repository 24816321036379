import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.css']
})
export class DocumentationComponent  {
  menuVar: boolean =false;
menu_icon_variable: boolean = false;
headerCh: boolean =true;
constructor(@Inject(DOCUMENT) private document: Document,private activatedRoute: ActivatedRoute){

}
@HostListener('document:scroll')
scrollfunction(){
  if(document.body.scrollTop > 0 || document.documentElement.scrollTop > 0){
    this.headerCh = true;
  }else{
    this.headerCh = true;
  }
}
  ngOnInit(): void {
  this.activatedRoute.fragment.subscribe((value)=>{
    this.jumpTo(value);
    //this.scroll;
  })
  }
  jumpTo(section:any){
    document.getElementById(section)?.scrollIntoView({behavior: 'smooth'})
  }

showmenu(){
  this.menuVar =! this.menuVar;

}
menu(){
  this.menuVar= false;
}  
    
}


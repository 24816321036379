import { DataStateEnum, AppDataState } from '@/State/load.state';
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, map, startWith, catchError, of } from 'rxjs';
import { Location } from '@angular/common'
import { FinanceService } from '@services/finance.service';
@Component({
  selector: 'app-payement-details',
  templateUrl: './payement-details.component.html',
  styleUrls: ['./payement-details.component.scss']
})
export class PayementDetailsComponent {
  readonly DataStateEnum=DataStateEnum;
  datas$: Observable<AppDataState<any[]>> |null = null;
  id:any;
  finance:any;
  constructor(private location: Location,private router: Router,private route:ActivatedRoute,private financeService: FinanceService){}

  ngOnInit(){
    this.id= this.route.snapshot.paramMap.get('id');
    this.getUsersFinance()
  }
  back(){
    this.location.back();
  }
  getClassOf(val) {
    if (val === "CONFIRM") {
      return 'btn btn-success';
    } 
     else {
      return 'btn btn-warning'
    }
  }



 
getUsersFinance() {
  this.datas$ = this.financeService.getFinanceById(this.id).pipe(
    map(data =>{
      this.finance = data;
        console.log(this.finance)
      return ({dataState:DataStateEnum.LOADED})
    }),
    startWith({dataState:DataStateEnum.LOADING}),
    catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
  );
}
}

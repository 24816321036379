<main class="content">
    <div class="pagetitle container-fluid">
        <h1>New Project</h1>
        <nav style="display: flex;justify-content: space-between;">
            <div>
                <ol class="breadcrumb">

                    <li class="breadcrumb-item"><a routerLink="/dashbord">Home</a></li>
                    <li class="breadcrumb-item active">Projects</li>
                    <li class="breadcrumb-item active">New</li>
                </ol>
            </div>


        </nav>
       
    </div>
    <form [formGroup]="addprojectValue">
        <div class="container-fluid">
            <div class="row">
                <div class="col-8 mx-auto">
                    <div class="card card-primary card-outline">
                        <a class="d-block w-100">
                            <div class="card-header">
                                <h4 class="card-title w-100">
                                    GENERALS INFORMATIONS
                                </h4>
                            </div>


                        </a>
                        <div class="">
                            <div class="card-body table-responsive p-0">
                                <table class="table table-striped table-valign-middle">
                                    <div class="card-body">

                                        <div class="form-group">
                                            <div class="input-field"
                                                [class.error]="description.invalid && description.hasError('required','type')">
                                                <label class="form-label" for="form3Example3">description<span
                                                        class="text-danger me-1">*</span></label>
                                                <input type="text" formControlName="description"
                                                    placeholder="description" required id="form3Example3"
                                                    class="form-control" />
                                                <div
                                                    *ngIf="description.invalid && (description.touched || description.dirty)">
                                                    <span class="error fs-6 text-danger mx-2 my-2"
                                                        *ngIf="description.errors?.required">*The description is
                                                        required</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="input-field"
                                                [class.error]="name.invalid && name.hasError('required','type')">
                                                <label class="form-label" for="form3Example3">Name<span
                                                        class="text-danger me-1">*</span></label>
                                                <input type="text" formControlName="name" placeholder="name" required
                                                    id="form3Example3" class="form-control" />
                                                <div *ngIf="name.invalid && (name.touched || name.dirty)">
                                                    <span class="error fs-6 text-danger mx-2 my-2"
                                                        *ngIf="name.errors?.required">*The name is
                                                        required</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-8 mx-auto">
                    <div class="card card-primary card-outline">
                        <a class="d-block w-100">
                            <div class="card-header">
                                <h4 class="card-title w-100">
                                    TECHNICAL INFORMATIONS
                                </h4>
                            </div>

                        </a>
                        <div class="">
                            <div class="card-body table-responsive p-0">
                                <table class="table table-striped table-valign-middle">
                                    <div class="card-body">
                                        <div class="form-group">
                                            <div class="input-field"
                                                [class.error]="notifyUrl.invalid && notifyUrl.hasError('required','type')">
                                                <label class="form-label" for="form3Example3">notifyUrl<span
                                                        class="text-danger me-1">*</span></label>
                                                <input type="text" formControlName="notifyUrl" placeholder="notifyUrl"
                                                    required id="form3Example3" class="form-control" />
                                                <div
                                                    *ngIf="notifyUrl.invalid && (notifyUrl.touched || notifyUrl.dirty)">
                                                    <span class="error fs-6 text-danger mx-2 my-2"
                                                        *ngIf="notifyUrl.errors?.required">*The notifyUrl is
                                                        required</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="input-field"
                                                [class.error]="cancelUrl.invalid && cancelUrl.hasError('required','type')">
                                                <label class="form-label" for="form3Example3">cancelUrl<span
                                                        class="text-danger me-1">*</span></label>
                                                <input type="text" formControlName="cancelUrl" placeholder="cancelUrl"
                                                    required id="form3Example3" class="form-control" />
                                                <div
                                                    *ngIf="cancelUrl.invalid && (cancelUrl.touched || cancelUrl.dirty)">
                                                    <span class="error fs-6 text-danger mx-2 my-2"
                                                        *ngIf="cancelUrl.errors?.required">*The cancelUrl is
                                                        required</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="input-field"
                                                [class.error]="successUrl.invalid && successUrl.hasError('required','type')">
                                                <label class="form-label" for="form3Example3">successUrl<span
                                                        class="text-danger me-1">*</span></label>
                                                <input type="text" formControlName="successUrl" placeholder="successUrl"
                                                    required id="form3Example3" class="form-control" />
                                                <div
                                                    *ngIf="successUrl.invalid && (successUrl.touched || successUrl.dirty)">
                                                    <span class="error fs-6 text-danger mx-2 my-2"
                                                        *ngIf="successUrl.errors?.required">*The successUrl is
                                                        required</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="row">
                <div class="col-8 mx-auto">
                    <div class="card card-primary card-outline">
                        <a class="d-block w-100">
                            <div class="card-header">
                                <h4 class="card-title w-100">
                                    ADDITIONAL INFORMATIONS
                                </h4>
                            </div>

                        </a>
                        <div class="">
                            <div class="card-body table-responsive p-0">
                                <table class="table table-striped table-valign-middle">
                                    <div class="card-body">
                                        <div class="form-group">
                                            <div class="input-field"
                                                [class.error]="websiteUrl.invalid && websiteUrl.hasError('required','type')">
                                                <label class="form-label" for="form3Example3">websiteUrl<span
                                                        class="text-danger me-1">*</span></label>
                                                <input type="text" formControlName="websiteUrl" placeholder="websiteUrl"
                                                    required id="form3Example3" class="form-control" />
                                                <div
                                                    *ngIf="websiteUrl.invalid && (websiteUrl.touched || websiteUrl.dirty)">
                                                    <span class="error fs-6 text-danger mx-2 my-2"
                                                        *ngIf="websiteUrl.errors?.required">*The website is
                                                        required</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="input-field">
                                                <label class="form-label" for="form3Example3">Name of items sold<span
                                                        class="text-danger me-1">*</span></label>
                                                <input type="text" formControlName="nameOfItemSold"
                                                    placeholder="nameOfItemSold" required id="form3Example3"
                                                    class="form-control" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">

                                                <div class="form-group">
                                                    <div class="input-field"
                                                        [class.error]="itemTypeSold.invalid && itemTypeSold.hasError('required','type')">
                                                        <label class="form-label" for="form3Example3">Type of items
                                                            sold<span class="text-danger me-1">*</span></label>

                                                        <select formControlName="itemTypeSold" class="form-control"
                                                            id="item">
                                                            <option></option>
                                                            <option value="PHYSICS">PHYSICS</option>
                                                            <option value="DIGITAL">DIGITAL</option>

                                                        </select>

                                                        <div
                                                            *ngIf="itemTypeSold.invalid && (itemTypeSold.touched || itemTypeSold.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2"
                                                                *ngIf="itemTypeSold.errors?.required">*The Type of items
                                                                sold is
                                                                required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-6">
                                                <div class="form-group">
                                                    <div class="input-field"
                                                        [class.error]="operateurs.invalid && operateurs.hasError('required','type')">

                                                        <label>Selected Operator</label>

                                                        <div class="d-flex">
                                                            <p-multiSelect  required [options]="activeOperator"
                                                                formControlName="operateurs"
                                                                [(ngModel)]="selectedCities" optionValue="code"
                                                                optionLabel="designation"></p-multiSelect>
                                                            </div>
                                                            <div *ngIf="dataLoadOperator"
                                                                class="spinner-border spinner-border-sm" role="status">
                                                            </div>


                                                        <div
                                                            *ngIf="operateurs.invalid && (operateurs.touched || operateurs.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2"
                                                                *ngIf="operateurs.errors?.required">*The operator is
                                                                required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <div class="input-field"
                                                        [class.error]="organizationID.invalid && organizationID.hasError('required','type')">

                                                        <label>Selected Organization</label>
                                                        <div class="d-flex">
                                                            <select formControlName="organizationID"
                                                                [disabled]="dataLoadOrganization" class="form-control"
                                                                id="operators">
                                                                <option></option>
                                                                <option *ngFor="let items of activeOrganisation"
                                                                    value="{{items.id}}">{{items.name}}</option>

                                                            </select><button class="btn-primary" (click)="open(add)"><i
                                                                    class="fa fa-plus"></i></button>

                                                            <div *ngIf="dataLoadOrganization"
                                                                class="spinner-border spinner-border-sm" role="status">
                                                            </div>
                                                        </div>
                                                        <div
                                                            *ngIf="organizationID.invalid && (organizationID.touched || organizationID.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2"
                                                                *ngIf="organizationID.errors?.required">*The
                                                                organization is
                                                                required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Selected Benefiary</label>
                                                    <div class="d-flex">
                                                        <select formControlName="principalCode"
                                                            [disabled]="dataLoadBeneficiary" class="form-control"
                                                            id="operators">
                                                            <option></option>
                                                            <option *ngFor="let items of activeBeneficiary"
                                                                value="{{items.code}}">{{items.designation}}</option>

                                                        </select>
                                                        <div *ngIf="dataLoadBeneficiary"
                                                            class="spinner-border spinner-border-sm" role="status">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="d-flex justify-content-center">
                                              
                                                <input type="checkbox" style="width: 18px;height: 18px;padding: 4px;" required formControlName="isPrivacyPolicyRead"
                                                 
                                                class="form-control" />I declare that I have read and understood the MoobilPay policy
                                             
                                            </div>
                                        </div>
                                    </div>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <div class="col-8 mx-auto" style="align-items: end;justify-content: flex-end;display: flex;">
            <button type="button" class="btn btn-primary" (click)="addproject()" [disabled]="submitted">Save<div
                    *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>

        </div>

    </form>

</main>




<ng-template #add let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Add Organization</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i
                class="bi bi-x-lg"></i></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="addorganisationValue">
            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="submitError">*{{submitError?.message}}.</span>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field" [class.error]="names.invalid && names.hasError('required','type')">
                            <label class="form-label" for="form3Example3">Name<span
                                    class="text-danger me-1">*</span></label>
                            <input type="text" formControlName="name" placeholder="name" required id="form3Example3"
                                class="form-control" />
                            <div *ngIf="names.invalid && (names.touched || names.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2" *ngIf="names.errors?.required">*The name
                                    is
                                    required</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field" [class.error]="address.invalid && address.hasError('required','type')">
                            <label class="form-label" for="form3Example3">address<span
                                    class="text-danger me-1">*</span></label>
                            <input type="text" formControlName="address" placeholder="address" required
                                id="form3Example3" class="form-control" />
                            <div *ngIf="address.invalid && (address.touched || address.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2" *ngIf="address.errors?.required">*The
                                    address is
                                    required</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field" [class.error]="city.invalid && city.hasError('required','type')">
                            <label class="form-label" for="form3Example3">city<span
                                    class="text-danger me-1">*</span></label>
                            <input type="text" formControlName="city" placeholder="city" required id="form3Example3"
                                class="form-control" />
                            <div *ngIf="city.invalid && (city.touched || city.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2" *ngIf="city.errors?.required">*The city
                                    is
                                    required</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field" [class.error]="email.invalid && email.hasError('required','type')">
                            <label class="form-label" for="form3Example3">email<span
                                    class="text-danger me-1">*</span></label>
                            <input type="email" formControlName="email" placeholder="email" required id="form3Example3"
                                class="form-control" />
                            <div *ngIf="email.invalid && (email.touched || email.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2" *ngIf="email.errors?.required">*The email
                                    is
                                    required</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field" [class.error]="phone.invalid && phone.hasError('required','type')">
                            <label class="form-label" for="form3Example3">phone<span
                                    class="text-danger me-1">*</span></label>
                            <input type="tel" formControlName="phone" placeholder="6514214778" required
                                id="form3Example3" class="form-control" />
                            <div *ngIf="phone.invalid && (phone.touched || phone.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2" *ngIf="phone.errors?.required">*The phone
                                    is required</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field" [class.error]="website.invalid && website.hasError('required','type')">
                            <label class="form-label" for="form3Example3">website<span
                                    class="text-danger me-1">*</span></label>
                            <input type="url" formControlName="website" placeholder="website" required
                                id="form3Example3" class="form-control" />
                            <div *ngIf="website.invalid && (website.touched || website.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2" *ngIf="website.errors?.required">*The
                                    website is
                                    required</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="form-group">
                <div class="input-field">
                    <label class="form-label" for="form3Example">document</label>
                    <input type="file" formControlName="document" placeholder="document" id="form3Example"
                        class="form-control" />

                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-primary" [disabled]="submitteds" (click)="addOrganization()">Save<div
                *ngIf="submitteds" class="spinner-border spinner-border-sm" role="status"></div></button>

    </div>
</ng-template>
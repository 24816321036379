import { Component, OnInit } from '@angular/core';
import { Currency } from '@/models/currency';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CurrencyService } from '@/services/currency.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent {
  data$: Observable<AppDataState<any[]>> |null = null;
  dtOptions: DataTables.Settings = {};

  datas$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;

  currencyForm!: FormGroup;
  currency?: Currency;
  addcurrencyModal: boolean=false;
  editcurrencyModal: boolean=false;
  currencies: Currency[] = [];
  disableCurrencie: Currency[] = [];
  addcurrencyValue!: any;
  updatecurrencyValue!: any;
  submitError!: any;
  submitted: boolean=false;
  dataLoad: boolean=true;

  temporaire!:any;
  edit: boolean=false;
  adddeleteModal: boolean=false;
  get designation(){return this.addcurrencyValue.get('designation'); }
  get code(){return this.addcurrencyValue.get('code'); }
  get designationupdate(){return this.updatecurrencyValue.get('designation'); }
  get codeupdate(){return this.updatecurrencyValue.get('code'); }
  constructor(
    private currencyService: CurrencyService,
    private router: Router,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private toastr: ToastrService

  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers'
    };
    // create value form
    this.addcurrencyValue= new FormGroup({
      "designation": new FormControl("", [Validators.required]),
      "code": new FormControl("", [Validators.required]),
    })


    // update value  form
    this.updatecurrencyValue= new FormGroup({
      "id": new FormControl("", [Validators.required]),
      "designation": new FormControl("", [Validators.required]),
      "code": new FormControl("", [Validators.required]),
    })



    this.getActiveCurrencies();
    this.getDisableCurrency();
  }
  
  showDialogedit(val:any,content:any) {
    this.edit=true;
    this.modalService.open(content);
    this.temporaire = {...val};

    this.editcurrencyModal = true;

  }

  showDialogadd(content: any) {
    this.modalService.open(content);
  }
  showDialogDelete(content: any) {
    this.modalService.open(content);
  }
  
  editcurrency(){
    this.submitted = true;

      this.currencyService.update(this.updatecurrencyValue.value).subscribe(data => {
         this.submitted = false;
        this.updatecurrencyValue.reset();
        this.closeDialofg()

        this.ngOnInit();
        this.toastr.success('The action has been performed successfully', 'Success');
      },
      (error)=>{
        this.submitted = false;
        this.toastr.error('Action Failed an errror has occured', 'Error');

      })
  }
  getActiveCurrencies() {
    this.datas$ = this.currencyService.getAll(true).pipe(
      map(data =>{
        this.currencies = data;
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );
  }
  getDisableCurrency(){
    this.data$ = this.currencyService.getAll(false).pipe(
      map(data =>{
        this.disableCurrencie = data;
        console.log(this.disableCurrencie)
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );

  }
  addcurrencie(){
    if( this.addcurrencyValue.valid && this.designation.value != null && this.code.value != null){
      this.submitted = true;
      console.log(this.addcurrencyValue.value);
      this.currencyService.create(this.addcurrencyValue.value).subscribe((result)=>{
       this.submitted = false;
       this.closeDialofg()

       this.ngOnInit();
       this.toastr.success('The action has been performed successfully', 'Success');
      },
      (error)=>{
       this.submitted = false;
       this.submitError = error;
       this.toastr.error('Action Failed an errror has occured', 'Error');
      }
      )
    }
  }
 setIsDefault(id:any, state:any){
  this.currencyService.changeDefault(id, state.currentTarget.checked).subscribe((result)=>{

    this.ngOnInit();

  },
  (error)=>{

  }

  )
 }

 
  delete_(value:any) {
    this.submitted = true;

    this.currencyService.disabled(value).subscribe(data => {
      this.submitted = false;
      this.closeDialofg()

      this.ngOnInit();
      this.toastr.warning('The action has been performed successfully', 'Success');

    },
    (error)=>{
      this.submitted = false;
      this.toastr.error('Action Failed an errror has occured', 'Error');
    })
  }
  currencyrestore(value:any) {
    this.submitted = true;

    this.currencyService.restoreState(value.id, true).subscribe(data => {
      this.submitted = false;
      this.closeDialofg()
      this.ngOnInit();
      this.toastr.success('The action has been performed successfully', 'Success');
    },
    (error)=>{
      this.submitted = false;
      this.toastr.error('Action Failed an errror has occured', 'Error');
    })
  }

  showDialogRestore(content:any,val:any) {
    this.edit = true;
    this.temporaire = {...val};
		this.modalService.open(content);


  }
  closeDialofg(){
    this.modalService.dismissAll()
  }
  applyFilterGlobal($event:any) {
    return ($event.target as HTMLInputElement).value
  }
}

<ul class="navbar-nav">
    <li class="nav-item">
        <a class="nav-link" (click)="onToggleMenuSidebar()" role="button"><i class="fas fa-bars"></i
        ></a>
    </li>
    <!-- <li class="nav-item d-none d-sm-inline-block">
        <a [routerLink]="['/']" class="nav-link">Home</a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
        <a [routerLink]="['/blank']" class="nav-link">Contact</a>
    </li> -->
</ul>

<!-- <form class="form-inline ml-3" [formGroup]="searchForm" *ngIf="false">
    <div class="input-group input-group-sm">
        <input
            class="form-control form-control-navbar"
            type="search"
            placeholder="Search"
            aria-label="Search"
        />
        <div class="input-group-append">
            <button class="btn btn-navbar" type="submit">
                <i class="fas fa-search"></i>
            </button>
        </div>
    </div>
</form> -->

<ul class="navbar-nav ml-auto">
    <!-- <app-messages></app-messages>
    <app-notifications></app-notifications> -->
    <!-- <app-language></app-language> -->
    <li class="nav-item">
        <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn" id="dropdownBasic1" ngbDropdownToggle>
                <i class="fas fa-user"></i>
			</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem routerLink="/dashbord/profile"><i class="fas fa-user"></i> Profile</button>
                <button ngbDropdownItem (click)="logout()"><i class="bi bi-box-arrow-left"></i> Logout</button>
            </div>
        </div>
    </li>

</ul>
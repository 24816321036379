import { DataStateEnum, AppDataState } from '@/State/load.state';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from '@services/parameter.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-withdraw-details',
  templateUrl: './withdraw-details.component.html',
  styleUrls: ['./withdraw-details.component.scss']
})
export class WithdrawDetailsComponent {
  constructor(config: NgbModalConfig, private modalService: NgbModal,private formBuilder: FormBuilder,
    private paramService: ParameterService, private toastr: ToastrService){

   }
   readonly DataStateEnum=DataStateEnum;
   datas$: Observable<AppDataState<any[]>> |null = null;
   updateForm: any;
  submitted = false;
  errormessage:any;
  params: any;
  temporaire!:any;

  ngOnInit(): void {
    this.getParam()
    this.updateForm= new FormGroup({
      "id": new FormControl("", [Validators.required]),
      "name": new FormControl("", [Validators.required]),
      "email": new FormControl("", [Validators.required]),
      "telephone": new FormControl("", [Validators.required]),
      "adresse": new FormControl("", [Validators.required]),
      "siteweb": new FormControl("", [Validators.required]),
      "rc": new FormControl(""),
      "nui": new FormControl(""),
    })
   }
   get name(){return this.updateForm.get('name'); }
   get email(){return this.updateForm.get('email'); }
   get telephone(){return this.updateForm.get('telephone'); }
   get adresse(){return this.updateForm.get('adresse'); }
   get siteweb(){return this.updateForm.get('siteweb'); }
   get rc(){return this.updateForm.get('rc'); }
   get nui(){return this.updateForm.get('nui'); }
  
  
   getParam(){
    this.submitted=true;
    this.paramService.getParams().subscribe(data =>{
      this.params = data;
      this.submitted=false;

      console.log(this.params)
    },
    (error)=>{
      this.submitted=false;

    }
    )
  
  }

   updateparam() {
    console.log(this.updateForm.value)
    if( this.updateForm.valid && this.name.value != null && this.email.value != null
        && this.telephone.value != null && this.siteweb.value != null 
         && this.adresse.value != null ){
      this.submitted = true;
      this.paramService.updateParams(this.updateForm.value).subscribe((result)=>{
        this.submitted = false;
        this.modalService.dismissAll();
        this.ngOnInit();
        this.toastr.success('The action has been performed successfully', 'Success');
      },
      (error)=>{
       this.submitted = false;
       this.errormessage = error;
       this.toastr.error('Action Failed an errror has occured', 'Error');
      }
      )
    }

  }

   showDialogadd(val:any, value:any){

    this.temporaire = value;
    console.log(this.temporaire)
    this.modalService.open(val);

   }
}

import { AppDataState, DataStateEnum } from '@/State/load.state';
import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OperatorService } from '@services/operator.service';
import { OrganizationService } from '@services/organization.service';
import { principalBeneficiaryService } from '@services/principalBeneficiary.service';
import { ProjectsService } from '@services/project.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, map, startWith, catchError, of } from 'rxjs';
import { Location } from '@angular/common'
@Component({
  selector: 'app-update-project',
  templateUrl: './update-project.component.html',
  styleUrls: ['./update-project.component.scss']
})
export class UpdateProjectComponent {
  addprojectValue:any;
  submitted: Boolean = false;
  submitError:any;
  activeOrganisation:any;
  activeBeneficiary:any;
  dataLoadBeneficiary:Boolean=false;
  dataLoadOrganization:Boolean=false;
  dataLoadOperator:Boolean=false;
  activeOperator:any;
  editProjetModal: boolean=false;
  selectedCities:any;

  userinfo: any;
  readonly DataStateEnum=DataStateEnum;
  datas$: Observable<AppDataState<any[]>> |null = null;
  projectInfo: any;
  userinformation = JSON.parse(localStorage.getItem("user")!);
  id!: any;
  myid: number;
  opera = ["OM"]
  get name(){return this.addprojectValue.get('name'); }
  get description(){return this.addprojectValue.get('description'); }
  get notifyUrl(){return this.addprojectValue.get('notifyUrl'); }
  get cancelUrl(){return this.addprojectValue.get('cancelUrl'); }
  get successUrl(){return this.addprojectValue.get('successUrl'); }
  get websiteUrl(){return this.addprojectValue.get('websiteUrl'); }
  get organizationID(){return this.addprojectValue.get('organizationID'); }
  get principalCode(){return this.addprojectValue.get('principalCode'); }
  get operateurs(){return this.addprojectValue.get('operateurs'); }


  constructor(private location: Location,private modalService: NgbModal,private operator: OperatorService,private beneficiary:principalBeneficiaryService,private project: ProjectsService,private organization:OrganizationService,private router: Router,private route:ActivatedRoute,private toastr: ToastrService){}
  ngOnInit(): void {
    this.id= +this.route.snapshot.paramMap.get('id');
    this.getProjectInfos()
    this.addprojectValue= new FormGroup({
      "id": new FormControl("", [Validators.required]),
      "name": new FormControl("", [Validators.required]),
      "description": new FormControl("", [Validators.required]),
      "notifyUrl": new FormControl("", [Validators.required]),
      "cancelUrl": new FormControl("", [Validators.required]),
      "successUrl": new FormControl("", [Validators.required]),
      "websiteUrl": new FormControl("", [Validators.required]),
      "itemTypeSold": new FormControl("", [Validators.required]),
      "organizationID": new FormControl("", [Validators.required]),
      "principalCode": new FormControl("", [Validators.required]),
      "operateurs": new FormControl("", [Validators.required]),
    })
    this.getOrganisation();
    this.getActiveBeneficiary();
    this.getOperator();
  }
  back(){
    this.location.back();
  }
  getProjectInfos(){
    this.datas$ = this.project.getById(this.id).pipe(
      map(data =>{
        this.projectInfo = data;
        console.log(this.projectInfo)
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );
  }
  enabledialog(){
    this.editProjetModal = false;
  }
  UpdateProject(){
    console.log("hello");
    console.log(this.addprojectValue.valid);
    console.log(this.addprojectValue.value);
    if( this.addprojectValue.valid && this.name.value != null && this.description.value != null){
      this.submitted = true;
      console.log(this.addprojectValue.value)

      this.project.update(this.addprojectValue.value).subscribe((result)=>{
       this.submitted = false;
       this.addprojectValue.reset();
       this.ngOnInit()
       this.router.navigateByUrl("/dashbord/projects");
       this.toastr.success('The action has been performed successfully', 'Success');
      },
      (error)=>{
       this.submitted = false;
       this.submitError = error;
       this.toastr.error('Action Failed an errror has occured', 'Error');
      }
      )
    }
  }

  closeDialofg(){
    this.modalService.dismissAll()
  }
  getOrganisation(){
    this.dataLoadOrganization = true;

    this.organization.getCreated(this.userinformation.id).subscribe(data =>{
        this.dataLoadOrganization = false;
        console.log(data)
        this.activeOrganisation = data;
      },
      
      (error)=>{
        console.log(error.message);
        this.dataLoadOrganization = false;
    
       })

    
    
    }

    getActiveBeneficiary(){
      this.dataLoadBeneficiary = true;

      this.beneficiary.getAll(true)
       .subscribe(data => {
        console.log(data)

        this.activeBeneficiary = data;
        this.dataLoadBeneficiary = false;
    
       },
       (error)=>{
        this.dataLoadBeneficiary = false;
    
       })
      }
      getOperator(){
        this.dataLoadOperator = true;
    
        this.operator.getAll(true)
        .subscribe(data => {
 
         this.activeOperator = data;
         this.dataLoadOperator = false;
     
        },
        (error)=>{
         this.dataLoadOperator = false;
     
        })}
        value(val:any){

          console.log(val)
        }
       
}

import { Injectable } from '@angular/core';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Account } from '../models/account/account';
import { Login } from '../models/account/login';
import { VerifyLink } from '../models/account/verifyLink';
import { ResetPassword } from '../models/account/resetPassword';
import { Register } from '../models/account/register';
import { environment } from 'environments/environment';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  })
};
@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private httpCient: HttpClient) { }

  login(login: Login): Observable<Login> {
    const url = `${environment.apiUrl}/account/login`;
    return this.httpCient.post<Account>(url, login)
      .pipe(
        retry(2),
        catchError(this.handleErrors)
      )
  }

  verifyLink(verifyLink: VerifyLink): Observable<VerifyLink> {
    const url = `${environment.apiUrl}/account/verify_link/resend`;
    return this.httpCient.post<Account>(url, verifyLink, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleErrors)
      )
  }

  resetPassword(resetPassword: ResetPassword): Observable<ResetPassword> {
    const url = `${environment.apiUrl}/account/reset_password`;
    return this.httpCient.post<ResetPassword>(url, resetPassword, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleErrors)
      )
  }

  register(register: Register): Observable<Register> {
    const url = `${environment.apiUrl}/account/register`;
    return this.httpCient.post<Register>(url, register, httpOptions).pipe(
      retry(2),
      catchError(this.handleErrors)
    );
  }

  sendLink(email: string) {
    const url = `${environment.apiUrl}/account/password/send_link`;
    return this.httpCient.post<Register>(url, email, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleErrors)
      )
  }

  verifyAccount(code: string) {
    return this.httpCient.get<Account[]>(`${environment.apiUrl}/account/verify?code=${code}`, httpOptions)
      .pipe(
        catchError(this.handleErrors)
      )
  }


  resetAndVerifyPassword(code: string) {
    return this.httpCient.get<Account[]>(`${environment.apiUrl}/account/verify/reset_password/${code}`, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleErrors)
      )
  }

  private handleErrors(error: HttpErrorResponse) {
    let errorMessage;
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      if (error.status == 200) {
        errorMessage = { "Code d'erreur": error.status, "message": "OK" };

      } else {
        errorMessage = { "Code d'erreur": error.status, "message": error.error.message };

      }
    }
    return throwError(errorMessage);
  }
}

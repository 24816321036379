import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Operator } from '@/models/operator';
import { OperatorService } from '@/services/operator.service';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-operators',
  templateUrl: './operators.component.html',
  styleUrls: ['./operators.component.scss'],
  providers: [NgbModalConfig, NgbModal]

})
export class OperatorsComponent {
  OperatorForm!: FormGroup;
  Operator?: Operator;
  addOperatorModal: boolean=false;
  editOperatorModal: boolean=false;
  operator: Operator[] = [];
  disableoperator: Operator[] = [];
  addOperatorValue!: any;
  updateOperatorValue!: any;
  submitError!: any;
  submitted: boolean=false;
  temporaire!:any;
  dataLoad: boolean=true;
  datas$: Observable<AppDataState<any[]>> |null = null;
  data$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  edit: boolean=false;
  dtOptions: DataTables.Settings = {};



  get design(){return this.addOperatorValue.get('designation'); }
  get code(){return this.addOperatorValue.get('code'); }
  get link(){return this.addOperatorValue.get('link'); }
  get subscribeKey(){return this.addOperatorValue.get('subscribeKey'); }
  get designationupdate(){return this.updateOperatorValue.get('designation'); }
  get codeupdate(){return this.updateOperatorValue.get('code'); }
  get linkupdate(){return this.updateOperatorValue.get('link'); }
  get subscribeKeyupdate(){return this.updateOperatorValue.get('subscribeKey'); }
  constructor(
    private OperatorService: OperatorService,
    private router: Router,config: NgbModalConfig, 
    private modalService: NgbModal,
    private toastr: ToastrService
  ) { }


  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers'
    };
    // create value form
    this.addOperatorValue= new FormGroup({
      "designation": new FormControl("", [Validators.required]),
      "code": new FormControl("", [Validators.required]),
      "logo": new FormControl(""),
      "link": new FormControl("", [Validators.required]),
      "subscribeKey": new FormControl("", [Validators.required]),

    })

    // update value  form
    this.updateOperatorValue= new FormGroup({
      "id": new FormControl("", [Validators.required]),
      "designation": new FormControl("", [Validators.required]),
      "code": new FormControl("", [Validators.required]),
      "logo": new FormControl("", [Validators.required]),
      "link": new FormControl("", [Validators.required]),
      "subscribeKey": new FormControl("", [Validators.required]),
    })



    this.getActiveoperator();
    this.getDisableOperator();
  }


  showDialogadd() {
    this.addOperatorModal = true;
  }
  editOperator(){
    this.submitted = true;

      this.OperatorService.update(this.updateOperatorValue.value).subscribe(data => {
        this.submitted = false;
        this.closeDialofg();

        this.updateOperatorValue.reset();
        this.ngOnInit();
        this.toastr.success('The action has been performed successfully', 'Success');
      },
      (error)=>{
        this.submitted = false;
        this.toastr.error('Action Failed an errror has occured', 'Error');

      })
  }
  getActiveoperator() {
    this.datas$ = this.OperatorService.getAll(true).pipe(
      map(data =>{
        this.operator = data;
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );
    // this.OperatorService.getAll(true).subscribe((response) => {
    //   this.operator = response;
    //   this.dataLoad = false;

    // });
  }
  getDisableOperator(){
    this.data$ = this.OperatorService.getAll(false).pipe(
      map(data =>{
        this.disableoperator = data;
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );
    // this.OperatorService.getAll(false).subscribe((response) => {
    //   this.disableoperator = response;
    //   this.dataLoad = false;

    // });
  }

  restoreOperator(value:any) {
    this.submitted = true;

    this.OperatorService.restoreState(value.id, true).subscribe(data => {
      this.closeDialofg();

      this.ngOnInit();
      this.submitted = false;
      this.toastr.success('The action has been performed successfully', 'Success');
      
    },
    (error)=>{
      this.submitted = false;
      this.toastr.error('Action Failed an errror has occured', 'Error');
    })
  }
  addoperator(){
    if( this.addOperatorValue.valid && this.design.value != null && this.code.value != null){
      this.submitted = true;
      console.log(this.addOperatorValue.value);
      this.OperatorService.create(this.addOperatorValue.value).subscribe((result)=>{
       this.submitted = false;
       this.closeDialofg();

       this.ngOnInit();
       this.toastr.success('The action has been performed successfully', 'Success');

      },
      (error)=>{
       this.submitted = false;
       this.submitError = error;
       this.toastr.error('Action Failed an errror has occured', 'Error');
      }
      )
    }
  }
 setIsDefault(id:any, state:any){
  this.OperatorService.changeDefault(id, state).subscribe((result)=>{
    this.ngOnInit();

  },
  (error)=>{

  }

  )
 }

 
  delete_(value:any) {
    this.submitted = true;

    this.OperatorService.disableds(value).subscribe(data => {
      this.submitted = false;
      this.closeDialofg();
      this.ngOnInit();
      this.toastr.warning('The action has been performed successfully', 'Success');

    },
    (error)=>{
      this.submitted = false;
      this.toastr.error('Action Failed an errror has occured', 'Error');
    })
  }



  applyFilterGlobal($event:any) {
    return ($event.target as HTMLInputElement).value
  }
  open(content: any) {
		this.modalService.open(content);
	}
  closeDialofg(){
    this.modalService.dismissAll()
  }
  showDialogedit(content:any,val:any) {
    this.edit = true;
    this.temporaire = {...val};
		this.modalService.open(content);


  }
}

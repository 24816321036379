import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DemoService extends BaseService<any>  {
  constructor(private httpCient: HttpClient) {
    super(
      httpCient,
      "payment/get_url"
    );
}
}

<div class="container-fluid">
    <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
        <ng-container *ngSwitchCase="DataStateEnum.LOADING">
            <app-spinner></app-spinner>
        </ng-container>
        <ng-container *ngSwitchCase="DataStateEnum.ERROR">


        </ng-container>
        <ng-container *ngSwitchCase="DataStateEnum.LOADED">

            <main>
                <div class="pagetitle">
                    <h1>Participants of project {{projectUsers.name}}</h1>
                    <nav style="display:flex;justify-content: space-between;">

                        <div>
                            <ol class="breadcrumb">

                                <li class="breadcrumb-item"><a routerLink="/dashbord">Home</a></li>
                                <li class="breadcrumb-item active">Project Participants </li>
                            </ol>
                        </div>
                        <div> <button class="btn btn-success" (click)="back()"> back</button></div>
                    </nav>


                </div>
                <div class="container">
                    <div class="card" *ngFor="let groups of projectUsers.listUsers;">
                        <div class="card-body" style="padding-bottom: 0px !important;">
                            <div class="row" style="display:flex;justify-content: space-between;">
                                <div class="col-md-5">
                                    <div class="d-flex flex-column align-items-center">
                                        <img src="assets/backend/images/avatar.png" alt="Profile" width="30%" height="30%" class="rounded-circle">
                                        <h5>{{groups.name}}</h5>
                                        <span>{{groups.city}} </span>
                                    </div>

                                    <hr>
                                    <div class=" " style="display:flex;justify-content: space-between; ">
                                        <div> <b>Account Status</b> </div>
                                        <div> <button [ngClass]="getStatus(groups.enabled) ">{{getState(groups.enabled)}}</button></div>
                                    </div>
                                    <hr>
                                </div>
                                <div class="col-md-5 ">

                                    <div style="display:flex;justify-content: space-between;margin-bottom: 5%; ">
                                        <div><label for="tel ">Email :</label></div>
                                        <div>{{groups.email}}"</div>
                                    </div>
                                    <div style="display:flex;justify-content: space-between;margin-bottom: 5%;">
                                        <div> <label for="tel">Phone :</label></div>
                                        <div>{{groups.phone}}</div>

                                    </div>
                                    <div style="display:flex;justify-content: space-between;margin-bottom: 5%;">
                                        <div><label for="tel">Birthday :</label></div>
                                        <div class="">{{groups.birthday}}</div>
                                    </div>
                                    <div style="display:flex;justify-content: space-between;">
                                        <div><label for="tel">Address :</label></div>
                                        <div class="">{{groups.adress}}</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </ng-container>
    </ng-container>

</div>


<ng-template #delete let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Delete Organization</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <p>Are you sure that you want to Delete {{temporaire?.name}}?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-danger" (click)="delete_(temporaire)" [disabled]="submitted">Delete<div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>

    </div>
</ng-template>
<ng-template #restore let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Restore Organization</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <p>Are you sure that you want to Restore {{temporaire?.name}}?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-warning" (click)="restoreProjet(temporaire)" [disabled]="submitted">Restore<div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>
    </div>
</ng-template>
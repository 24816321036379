<!-- Brand Logo -->
<a [routerLink]="['/dashbord']" class="brand-link">
    <img src="../../../../../assets/images/logo.png" width="50px" height="50px" alt="" srcset="">

    <span class="brand-text font-weight-light" routerLink="/dashbord">MoobilPay</span>
</a>

<!-- Sidebar -->
<div class="sidebar">
    <!-- Sidebar user (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
            <img src="../../../../../assets/img/default-profile.png" class="rounded-circle" width="50px" height="50px" alt="" srcset="">

           
        </div>

        <div class="info">
            <a [routerLink]="['/dashbord/profile']" class="d-block">
                {{ user.email }}
            </a>
        </div>
    </div>

    <!-- <div class="form-inline">
        <app-sidebar-search></app-sidebar-search>
    </div> -->

    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow-y: hidden">
        <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
        >
            <app-menu-item
                *ngFor="let item of dashbord"
                [menuItem]="item"
            ></app-menu-item>
            <div *ngIf='rol===super'>
                <app-menu-item 
                *ngFor="let items of supers"
                [menuItem]="items"
            ></app-menu-item>
            </div>
           
        <app-menu-item
        *ngFor="let item of menu"
        [menuItem]="item"
    ></app-menu-item>
        </ul>
    </nav>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { AccountService } from '@services/account.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  constructor(private router: Router,private route:ActivatedRoute,private info:UserService,private resetLinkpass: AccountService,  private toastr: ToastrService){}
  userinfo: any;
  datas$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  submitted: Boolean =false;
  submitte: Boolean =false;
  updateUsers!:any;
  emailResetPassword!:any;
  userinformation = JSON.parse(localStorage.getItem("user")!);
  id = this.userinformation.id;
  email= this.userinformation.sub;
  edit: Boolean =true;
   ngOnInit(){
    this.updateUsers= new FormGroup({
      "id": new FormControl("", [Validators.required]),
      "name": new FormControl("", [Validators.required],),
      "email": new FormControl("", [Validators.required]),
      "city": new FormControl(""),
      "phone": new FormControl(""),
      "address": new FormControl(""),
      "birthday": new FormControl(""),
      "picture": new FormControl(""),


    })
    this.emailResetPassword= new FormGroup({
      "email": new FormControl(this.email, [Validators.required,Validators.email]),
    })
    this.getUsers();
  }
  annuler(){
    this.edit = true;
  }
  edition(){
    this.edit = false;
  }
  add(){
    this.submitted = true;
    console.log(this.updateUsers.value)
      this.info.updateUsers(this.updateUsers.value).subscribe(data => {
        this.submitted = false;

        this.updateUsers.reset();

        this.ngOnInit();
        this.toastr.success('The action has been performed successfully', 'Success');

      },
      (error)=>{
        this.submitted = false;
        this.toastr.error('Action Failed an errror has occured', 'Error');
      })
  }
  getUsers(){
    this.datas$ = this.info.getById(this.id).pipe(
      map(data =>{
        this.userinfo = data;
        console.log(this.userinfo)
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );

 
     }
     changePassword(){
      this.submitted = true;

      this.resetLinkpass.sendLink(this.emailResetPassword.value).subscribe((result)=>{
        this.submitted = false;
        localStorage.clear();
        this.router.navigateByUrl('/resetpasslink')
      },
        (error)=>{
          this.submitted = false;
        }
      );


     }

}

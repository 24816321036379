<main id="main" class="main justify-content-center align-items-center">
    <div class=" container pagetitle" style="width: 100%;">
        <h2 style="padding: 20px;">Payement Details</h2>
        <nav style="display:flex;justify-content: space-between;">

            <div>
                <ol class="breadcrumb">

                    <li class="breadcrumb-item"><a routerLink="/dashbord">Finance</a></li>
                    <li class="breadcrumb-item active">payement details </li>
                </ol>
            </div>
            <div>
                <button class="btn btn-success" (click)="back()"> back</button>
            </div>
        </nav>
    </div>
    <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
        <ng-container *ngSwitchCase="DataStateEnum.LOADING">
            <app-spinner></app-spinner>
        </ng-container>
        <ng-container *ngSwitchCase="DataStateEnum.ERROR">


        </ng-container>
        <ng-container *ngSwitchCase="DataStateEnum.LOADED">
            <section class="justify-content-center align-items-center">
                <div class="container" style="border: 2px gray;overflow-y: scroll; background-color: white;">
                    <div style="display: flex;justify-content: space-between;padding: 2%;">
                        <div><img src="../../../../../assets/img/logo.png" alt="" height="20" width="20">
                            <b style="font-size: large;">  MOOBIL PAY</b></div>
                        <div style="font-size: large;">Date: 2023-06-01 09:08:23</div>
                    </div>
                    <div class="row" style="padding: 2%;">
                        <div class="col-md-4">
                            <div>From: <b>{{finance?.firstName}}</b></div>
                            <div>Country : {{finance?.countryCode}}</div>
                            <div>Phone : {{finance?.owner.phone}}</div>
                            <div>Email : {{finance?.email}}</div>
                        </div>
                        <div class="col-md-4">
                            <div>To : <b>{{finance?.owner.name}}</b></div>
                            <div>City : {{finance?.owner.city}}</div>
                            <div>Adresse : {{finance?.owner.address}}</div>
                            <div>Phone : {{finance?.owner.phone}}</div>
                            <div>Email : {{finance?.owner.email}}</div>
                        </div>
                        <div class="col-md-4">
                            <div>Project Name : {{finance?.project.name}}</div>
                            <div>Website: {{finance?.project.websiteUrl}}</div>
                            <div>Notify Url : {{finance?.project.notifyUrl}}</div>
                            <div>Success Url : {{finance?.project.succcessUrl}}</div>
                            <div>Cancel Url : {{finance?.project.cancelUrl}}</div>

                        </div>
                    </div>
                    <div style="padding: 2%;">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <td>Currency</td>
                                    <td>Item Name</td>
                                    <td>Description</td>
                                    <td>Earn</td>
                                    <td>Fees</td>
                                    <td>Amount</td>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{finance?.currencyCode}}</td>
                                    <td>{{finance?.itemName}}</td>
                                    <td>{{finance?.description}}</td>
                                    <td>{{finance?.earns}}</td>
                                    <td>{{finance?.fees}}</td>
                                    <td>{{finance?.amount}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row" style="padding: 2%;">
                        <div class="col-md-5">
                            <div>Payement Methods:</div>
                            <div>
                                <img src="../../../assets/images/orange.png" width="100px" height="70px" alt="" srcset="">
                            </div>
                            <span>Payement cancelled </span>
                        </div>
                        <div class="col-md-7">
                            <span style="font-size: larger;">Currency code</span>
                            <hr>
                            <div style="display: flex;justify-content: space-between;">
                                <div>Subtotal</div>
                                <div>{{finance?.earns}} XAF</div>
                            </div>
                            <hr>
                            <div style="display: flex;justify-content: space-between;">
                                <div>Fees</div>
                                <div>{{finance?.fees}} XAF</div>
                            </div>
                            <hr>
                            <div style="display: flex;justify-content: space-between;">
                                <div>Total:</div>
                                <div>{{finance?.amount}} 206 XAF</div>
                            </div>
                        </div>
                        <div style="width:100%;display: flex;justify-content: space-between;padding: 2%;">
                            <div><button class="btn btn-light"><i class="bi bi-printer-fill"></i> print</button></div>
                            <div>
                                <button class="btn btn-primary" style="margin-right: 10px;"><i class="bi bi-filetype-pdf"></i> Generate PDF</button>
                                <button class="btn btn-success"><i class="bi bi-envelope"></i> Send to client</button>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </ng-container>
    </ng-container>
</main>
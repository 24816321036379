import { Component } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AccountService } from '@services/account.service';
@Component({
  selector: 'app-validate-account',
  templateUrl: './validate-account.component.html',
  styleUrls: ['./validate-account.component.css']
})
export class ValidateAccountComponent {
  constructor(private route:ActivatedRoute,private validation: AccountService){}
  code!:any;
  status: Boolean =true;
  validate:any;
  err:any;
  verifie: Boolean;
  ngOnInit(): void {
   this.code= this.route.snapshot.paramMap.get('code');
   this.verification(this.code);
  }
  verification(value:any){
    this.validation.verifyAccount(value).subscribe((result)=>{
      console.log("this",result)
      this.status=false;



    },
    (error)=>{
      this.status=false;
      this.err =error.message
      if(this.err == "OK"){
        this.verifie =true;
      }else{
        this.verifie =false;
      }
    }
    )
    

  };
}

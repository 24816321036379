import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from './base.service';
import { Operator } from '../models/operator';

@Injectable({
  providedIn: 'root'
})
export class OperatorService extends BaseService<Operator> {

  constructor(private httpCient: HttpClient) {
    super(
      httpCient,
      "operators"
    );
  }
}

import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '@services/account.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
  constructor(private router:Router,private route:ActivatedRoute,private resetsPassword:AccountService) { }
  passwords!: any;
  code:any;
  submitted = false;
  submited = false;

  err:any;
  get password(){return this.passwords.get('password'); }
  get confirmPassword(){return this.passwords.get('confirmPassword'); }


  ngOnInit(): void {
    this.code= this.route.snapshot.paramMap.get('code');
    this.passwords= new FormGroup({
      "passwordResetCode": new FormControl(this.code, [Validators.required]),
      "password": new FormControl("", [Validators.required]),
      "confirmPassword": new FormControl("", [Validators.required]),

    })
  }
  resetPassword(){
    if (this.passwords.valid && this.confirmPassword.value != null && this.password.value != null){
      this.submitted = true;
      this.resetsPassword.resetPassword(this.passwords.value).subscribe((result)=>{
        this.submited = false;
        this.router.navigateByUrl('/login');
      },
        (error)=>{
          this.err=error;
          this.submitted = false;
        }
      );
    } else{
      this.submitted = false;
      this.validateform(this.passwords)
    }
  }


  private validateform(form: FormGroup ){
    Object.keys(form.controls).forEach(field =>{
      const controls =  form.get(field);
      if(controls instanceof FormControl){
        controls.markAsDirty({onlySelf: true});

      }else if(controls instanceof FormGroup){
        this.validateform(controls)
      }
    })

  }
}

import { AppDataState, DataStateEnum } from '@/State/load.state';
import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyService } from '@services/currency.service';
import { DemoService } from '@services/demo.service';
import { OrganizationService } from '@services/organization.service';
import { ProjectsService } from '@services/project.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-demo-payement',
  templateUrl: './demo-payement.component.html',
  styleUrls: ['./demo-payement.component.scss']
})
export class DemoPayementComponent {
  testprojectValue:any;
  submitteds: Boolean = false;
  submitted: Boolean = false;
  submitError:any;
  selectedCities:any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings:IDropdownSettings;
  activeProjects:any;
  activeCurrency: any;
  dataLoadBeneficiary:Boolean=false;
  dataLoadOrganization:Boolean=false;
  dataLoadOperator:Boolean=false;
  readonly DataStateEnum=DataStateEnum;
  userinformation = JSON.parse(localStorage.getItem("user")!);
  datas$: Observable<AppDataState<any[]>> |null = null;
  get amount(){return this.testprojectValue.get('amount'); }
  get currencyCode(){return this.testprojectValue.get('currencyCode'); }
  get countryCode(){return this.testprojectValue.get('countryCode'); }
  get lang(){return this.testprojectValue.get('lang'); }
  get itemRef(){return this.testprojectValue.get('itemRef'); }
  get itemName(){return this.testprojectValue.get('itemName'); }
  get description(){return this.testprojectValue.get('description'); }
  get email(){return this.testprojectValue.get('email'); }
  get phone(){return this.testprojectValue.get('phone'); }
  get firstName(){return this.testprojectValue.get('firstName'); }
  get lastName(){return this.testprojectValue.get('lastName'); }
  get publicKey(){return this.testprojectValue.get('publicKey'); }
  get logo(){return this.testprojectValue.get('logo'); }


  constructor(private route: Router,private currencie: CurrencyService,private project: ProjectsService,private toastr: ToastrService,private demo: DemoService){}
  ngOnInit(): void {
    this.testprojectValue = new FormGroup({
      "amount": new FormControl("", [Validators.required]),
      "currencyCode": new FormControl("", [Validators.required]),
      "countryCode": new FormControl("", [Validators.required]),
      "lang": new FormControl("", [Validators.required]),
      "itemRef": new FormControl("",[Validators.required]),
      "itemName": new FormControl("",[Validators.required]),
      "description": new FormControl(""),
      "email": new FormControl("",[Validators.required,Validators.email]),
      "phone": new FormControl("",[Validators.required,Validators.minLength(8),Validators.maxLength(13)]),

      "firstName": new FormControl(""),
      "lastName": new FormControl(""),
      "publicKey": new FormControl(""),
      "logo": new FormControl(""),
      "environment": new FormControl("test")
    })
    this.getProject()
  }


  addproject(){
    console.log(this.testprojectValue.value)
    if( this.testprojectValue.valid){
      this.submitted = true;
      this.demo.create(this.testprojectValue.value).subscribe((results)=>{
       this.submitted = false;

       window.location.href = results?.url;

       this.toastr.success('The action has been performed successfully', 'Success');
      },
      (error)=>{
       this.submitted = false;
       this.submitError = error;
       this.toastr.error('Action Failed an errror has occured', error.message);
      }
      )
    }
  }

  value(val:any){

    console.log(val)
  }

  
      getProject(){
        this.dataLoadBeneficiary = true;
  
        this.project.getAll(true)
         .subscribe(data => {
          console.log(data)
  
          this.activeProjects = data;
          this.dataLoadBeneficiary = false;
      
         },
         (error)=>{
          this.dataLoadBeneficiary = false;
      
         })
        }
  
  

        onItemSelect(item: any) {
          console.log(item);
        }
        onSelectAll(items: any) {
          console.log(items);
        }



       
}

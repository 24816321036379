<footer>
    <div class="row footer-border pb-5">
        <div class="col-sm-4 col-lg-4">
            <img src="assets/images/logo.png" width="90px" height="90px" alt="">
            <p class="text-muted">Moobil Pay your reference online payement service</p>
        </div>
        <div class="col-sm-4 col-lg-2 pl-lg-3">
            <h5>Learn More</h5>
            <ul class="pl-0 text-muted">
                <li><a href="#">Product</a></li>
                <li><a href="#">About</a></li>

                <li><a href="#">Blog</a></li>
            </ul>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-3">
            <h5>Support</h5>
            <ul class="pl-0 text-muted">
                <li><a href="#">Privacy Policy</a></li>
                <li><a href="#">FAQ</a></li>
                <li><a href="#">Terms & Conditions</a></li>
            </ul>
        </div>
        <div class="col-sm-4 col-md-6 col-lg-2">
            <h5>Social</h5>
            <div class="d-flex">
                <div class="social-round-box">
                    <a href="#"><span class="mdi mdi-facebook"></span></a>
                </div>
                <div class="social-round-box">
                    <a href="#"><span class="mdi mdi-linkedin"></span></a>
                </div>
                <div class="social-round-box">
                    <a href="#"><span class="mdi mdi-twitter"></span></a>
                </div>
                <div class="social-round-box">
                    <a href="#"><span class="mdi mdi-pinterest"></span></a>
                </div>
            </div>
        </div>
    </div>
    <p class="text-center text-muted pt-5">© 2023 KTC-CENTER. All rights reserved.</p>
</footer>

<a (click)="scrollTop()" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
<div class="container-fluid">
    <main>
        <div class="pagetitle">
            <h1>Organization</h1>
            <nav style="display: flex;justify-content: space-between;">

                <div>
                    <ol class="breadcrumb">

                        <li class="breadcrumb-item"><a routerLink="/dashbord">Home</a></li>
                        <li class="breadcrumb-item">Organization</li>
                        <li class="breadcrumb-item active">Owner Details</li>




                    </ol>
                </div>


            </nav>
            <div>
                <div class="container-fluid">
                    <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
                        <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                            <app-spinner></app-spinner>
                        </ng-container>
                        <ng-container *ngSwitchCase="DataStateEnum.ERROR">


                        </ng-container>
                        <ng-container *ngSwitchCase="DataStateEnum.LOADED">
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-12">
                                    <section class="section profile">
                                        <div class="row">
                                            <div class="col-xl-4">
                                                <div class="card" style="background-color: white;padding: 0px !important;">
                                                    <div class="card-body profile-card pt-4 d-flex flex-column align-items-center" style="padding: 10px !important;">

                                                        <img src="../../../../assets/images/profil.png" alt="Profile" class="rounded-circle" style="padding-bottom: 10px;">
                                                        <h5>{{userinfo.name}}</h5>

                                                        <div class="row" style="width: 100%;">
                                                            <div class="row mybox" style="width: 100%;">
                                                                <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                    <div class="size11"><b> Projects: </b></div>
                                                                    <div class="size12"> <b>0</b> </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="row" style="width: 100%;">
                                                            <div class="row mybox" style="width: 100%;">
                                                                <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                    <div class="size11"><b> Organisations: </b></div>
                                                                    <div class="size12"> <b>0</b> </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <br>


                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-xl-8">
                                                <div class="card" style="background-color: white;">
                                                    <div class="card-body pt-3">
                                                        <ul class="nav nav-tabs nav-tabs-bordered">

                                                            <li class="nav-item"> <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-edit">Profile</button></li>
                                                        </ul>
                                                        <div class="tab-content pt-2">

                                                            <div class="tab-pane fade show active profile-overview" id="profile-edit">
                                                                <form action="">
                                                                    <div class="row mb-3">
                                                                        <label for="name" class="col-sm-3 col-form-label">Name</label>
                                                                        <div class="col-sm-9">
                                                                            <input [disabled]="true" type="text" class="form-control" id="name" value="{{userinfo.name}}">
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-3">
                                                                        <label for="inputEmail" class="col-sm-3 col-form-label">Email</label>
                                                                        <div class="col-sm-9">
                                                                            <input [disabled]="true" type="email" class="form-control" id="inputEmail" value="{{userinfo.email}}">
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-3">
                                                                        <label for="telephone" class="col-sm-3 col-form-label">Phone Number</label>
                                                                        <div class="col-sm-9">
                                                                            <input [disabled]="true" type="number" class="form-control" id="phone" value="{{userinfo.phone}}">
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-3">
                                                                        <label for="ville" class="col-sm-3 col-form-label">City</label>
                                                                        <div class="col-sm-9">
                                                                            <input [disabled]="true" type="text" class="form-control" id="ville" value="{{userinfo.city}}">
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-3">
                                                                        <label for="Address" class="col-sm-3 col-form-label">Address</label>
                                                                        <div class="col-sm-9">
                                                                            <input [disabled]="true" type="text" class="form-control" id="address" value="{{userinfo.address}}">
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-3">
                                                                        <label for="birthday" class="col-sm-3 col-form-label">Birth day</label>
                                                                        <div class="col-sm-9">
                                                                            <input [disabled]="true" type="date" class="form-control" id="address" value="{{userinfo.birthday}}">
                                                                        </div>
                                                                    </div>

                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                </div>

            </div>
        </div>


    </main>

</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from '@/services/organization.service';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { UserService } from '@/services/user.service';

@Component({
  selector: 'app-owner-details',
  templateUrl: './owner-details.component.html',
  styleUrls: ['./owner-details.component.scss']
})
export class OwnerDetailsComponent {
  constructor(private router: Router,private route:ActivatedRoute,private info:UserService){}
  userinfo: any;
  readonly DataStateEnum=DataStateEnum;
  datas$: Observable<AppDataState<any[]>> |null = null;
  id!: any;

   ngOnInit(){
    this.id= this.route.snapshot.paramMap.get('id');
    this.getUsersInformation()
  }
     
  getUsersInformation(){
    this.datas$ = this.info.getById(this.id).pipe(
      map(data =>{
        this.userinfo = data;
        console.log(this.userinfo)
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );
  }
}

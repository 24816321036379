<div class="container-fluid">
    <main>
        <div class="pagetitle">
            <h1>Organization</h1>
            <nav style="display: flex;justify-content: space-between;">

                <div>
                    <ol class="breadcrumb">

                        <li class="breadcrumb-item"><a routerLink="/dashbord">Home</a></li>
                        <li class="breadcrumb-item active">Organization</li>




                    </ol>
                </div>
                <div>
                    <button type="button" class="btn btn-primary" (click)="open(add)"><i
                            class="fas fa-plus"></i>Save</button>

                </div>

            </nav>
            <div class="card" style="background-color: white;">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-12">
                            <div class="card-body pt-3">
                                <ul class="nav nav-tabs nav-tabs-bordered">

                                    <li class="nav-item"> <button class="nav-link active" data-bs-toggle="tab"
                                            data-bs-target="#profile-edit">Active Organization</button></li>

                                    <li class="nav-item"> <button class="nav-link" data-bs-toggle="tab"
                                            data-bs-target="#profile-change-password">Disabled Organization</button>
                                    </li>
                                </ul>
                                <div class="tab-content pt-2">
                                    <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
                                        <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                                            <app-spinner></app-spinner>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="DataStateEnum.ERROR">


                                        </ng-container>
                                        <ng-container *ngSwitchCase="DataStateEnum.LOADED">


                                            <div class="tab-pane fade show active profile-overview" id="profile-edit">
                                                <table [dtOptions]="dtOptions" datatable class="row-border hover">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Website</th>
                                                            <th>CreatedBy</th>

                                                            <th>Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let groups of activeOrganisation; let i = index">
                                                            <td>{{i+1}}</td>
                                                            <td>{{groups?.name}}</td>
                                                            <td>{{groups?.email}}</td>
                                                            <td> <a href="{{groups?.website}}">{{groups?.website}}</a>
                                                            </td>
                                                            <td style="cursor:pointer"
                                                                [routerLink]="['/dashbord/ownwer-details', groups?.createdBy?.id]">
                                                                <a class="link">{{groups?.createdBy?.name}}</a> </td>
                                                            <td> <button type="button"
                                                                    [routerLink]="['/dashbord/organisation-details', groups.id]"
                                                                    class="btn btn-success btn-circle"><i
                                                                        class="fas fa-eye"></i></button>
                                                                <button type="button"
                                                                    (click)="showDialogedit(edit,groups)"
                                                                    class="btn btn-warning btn-circle"><i
                                                                        class="bi bi-pencil"></i></button>
                                                                <button type="button"
                                                                    (click)="showDialogedit(delete,groups)"
                                                                    class="btn btn-danger btn-circle"><i
                                                                        class="fas fa-trash"></i></button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="data$ | async as result" [ngSwitch]="result.dataState">
                                        <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                                            <app-spinner></app-spinner>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="DataStateEnum.ERROR">
                                        </ng-container>
                                        <ng-container *ngSwitchCase="DataStateEnum.LOADED">

                                            <div class="tab-pane fade pt-3" id="profile-change-password">
                                                <table datatable [dtOptions]="dtOptions" class="row-border hover">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Website</th>
                                                            <th>CreatedBy</th>

                                                            <th>Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let groups of disabledOrganisation; let i = index">
                                                            <td>{{i+1}}</td>
                                                            <td>{{groups?.name}}</td>
                                                            <td>{{groups?.email}}</td>
                                                            <td> <a href="{{groups?.website}}">{{groups?.website}}</a>
                                                            </td>
                                                            <td style="cursor:pointer"
                                                                [routerLink]="['/dashbord/ownwer-details', groups?.createdBy?.id]">
                                                                <a class="link">{{groups?.createdBy?.name}}</a> </td>
                                                            <td>
                                                                <button type="button"
                                                                    (click)="showDialogedit(restore,groups)"
                                                                    class="btn btn-warning btn-circle"><i
                                                                        class="fas fa-recycle"></i></button>


                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>



                                            </div>

                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    </main>

</div>

<ng-template #add let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Add Organization</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i
                class="bi bi-x-lg"></i></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="addorganisationValue">
            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="submitError">*{{submitError?.message}}.</span>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field" [class.error]="name.invalid && name.hasError('required','type')">
                            <label class="form-label" for="form3Example3">Name<span
                                    class="text-danger me-1">*</span></label>
                            <input type="text" formControlName="name" placeholder="name" required id="form3Example3"
                                class="form-control" />
                            <div *ngIf="name.invalid && (name.touched || name.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2" *ngIf="name.errors?.required">*The name
                                    is
                                    required</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field" [class.error]="address.invalid && address.hasError('required','type')">
                            <label class="form-label" for="form3Example3">address<span
                                    class="text-danger me-1">*</span></label>
                            <input type="text" formControlName="address" placeholder="address" required
                                id="form3Example3" class="form-control" />
                            <div *ngIf="address.invalid && (address.touched || address.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2" *ngIf="address.errors?.required">*The
                                    address is
                                    required</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field" [class.error]="city.invalid && city.hasError('required','type')">
                            <label class="form-label" for="form3Example3">city<span
                                    class="text-danger me-1">*</span></label>
                            <input type="text" formControlName="city" placeholder="city" required id="form3Example3"
                                class="form-control" />
                            <div *ngIf="city.invalid && (city.touched || city.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2" *ngIf="city.errors?.required">*The city
                                    is
                                    required</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field" [class.error]="email.invalid && email.hasError('required','type')">
                            <label class="form-label" for="form3Example3">email<span
                                    class="text-danger me-1">*</span></label>
                            <input type="email" formControlName="email" placeholder="email" required id="form3Example3"
                                class="form-control" />
                            <div *ngIf="email.invalid && (email.touched || email.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2" *ngIf="email.errors?.required">*The email
                                    is
                                    required</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field" [class.error]="phone.invalid && phone.hasError('required','type')">
                            <label class="form-label" for="form3Example3">phone<span
                                    class="text-danger me-1">*</span></label>
                            <input type="tel" formControlName="phone" placeholder="6514214778" required
                                id="form3Example3" class="form-control" />
                            <div *ngIf="phone.invalid && (phone.touched || phone.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2" *ngIf="phone.errors?.required">*The phone
                                    is required</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field" [class.error]="website.invalid && website.hasError('required','type')">
                            <label class="form-label" for="form3Example3">website<span
                                    class="text-danger me-1">*</span></label>
                            <input type="url" formControlName="website" placeholder="website" required
                                id="form3Example3" class="form-control" />
                            <div *ngIf="website.invalid && (website.touched || website.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2" *ngIf="website.errors?.required">*The
                                    website is
                                    required</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="form-group">
                <div class="input-field">
                    <label class="form-label" for="form3Example">document</label>
                    <input type="file" formControlName="document" placeholder="document" id="form3Example"
                        class="form-control" />

                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-primary" [disabled]="submitted" (click)="addOrganization()">Save<div
                *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>

    </div>
</ng-template>


<ng-template #edit let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Edit Organization</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i
                class="bi bi-x-lg"></i></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="organisationUpdate" novalidate *ngIf="edit">
            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="submitError">*{{submitError?.message}}.</span>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field" style="display:none">
                            <label class="form-label" for="form3Example3">id<span
                                    class="text-danger me-1">*</span></label>
                            <input type="text" formControlName="id" [(ngModel)]="temporaire.id" required
                                id="form3Example1" class="form-control" />
                        </div>
                        <div class="input-field"
                            [class.error]="nameupdate.invalid && nameupdate.hasError('required','type')">
                            <label class="form-label" for="form3Example3">name<span
                                    class="text-danger me-1">*</span></label>
                            <input type="text" [(ngModel)]="temporaire.name" formControlName="name"
                                placeholder="franc CFA" required id="form3Example3" class="form-control" />
                            <div *ngIf="nameupdate.invalid && (nameupdate.touched || nameupdate.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2" *ngIf="nameupdate.errors?.required">*The
                                    name is
                                    required</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field"
                            [class.error]="addressupdate.invalid && address.hasError('required','type')">
                            <label class="form-label" for="form3Example3">address<span
                                    class="text-danger me-1">*</span></label>
                            <input type="text" [(ngModel)]="temporaire.address" formControlName="address"
                                placeholder="XAF" required id="form3Example2" class="form-control" />
                            <div *ngIf="addressupdate.invalid && (addressupdate.touched || addressupdate.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2"
                                    *ngIf="addressupdate.errors?.required">*The address is required</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field" [class.error]="cityupdate.invalid && city.hasError('required','type')">
                            <label class="form-label" for="form3Example3">city<span
                                    class="text-danger me-1">*</span></label>
                            <input type="text" [(ngModel)]="temporaire.city" formControlName="city" placeholder="XAF"
                                required id="form3Example2" class="form-control" />
                            <div *ngIf="cityupdate.invalid && (cityupdate.touched || cityupdate.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2" *ngIf="cityupdate.errors?.required">*The
                                    city is required</span>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-md-6">

                    <div class="form-group">
                        <div class="input-field"
                            [class.error]="emailupdate.invalid && email.hasError('required','type')">
                            <label class="form-label" for="form3Example3">email<span
                                    class="text-danger me-1">*</span></label>
                            <input type="email" [(ngModel)]="temporaire.email" formControlName="email" placeholder="XAF"
                                required id="form3Example2" class="form-control" />
                            <div *ngIf="emailupdate.invalid && (emailupdate.touched || emailupdate.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2" *ngIf="emailupdate.errors?.required">*The
                                    email is required</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field"
                            [class.error]="phoneupdate.invalid && phone.hasError('required','type')">
                            <label class="form-label" for="form3Example3">phone<span
                                    class="text-danger me-1">*</span></label>
                            <input type="tel" [(ngModel)]="temporaire.phone" formControlName="phone" placeholder="XAF"
                                required id="form3Example2" class="form-control" />
                            <div *ngIf="phoneupdate.invalid && (phoneupdate.touched || phoneupdate.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2" *ngIf="phoneupdate.errors?.required">*The
                                    phone is required</span>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field"
                            [class.error]="websiteupdate.invalid && website.hasError('required','type')">
                            <label class="form-label" for="form3Example3">website<span
                                    class="text-danger me-1">*</span></label>
                            <input type="url" [(ngModel)]="temporaire.website" formControlName="website"
                                placeholder="XAF" required id="form3Example2" class="form-control" />
                            <div *ngIf="websiteupdate.invalid && (websiteupdate.touched || websiteupdate.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2"
                                    *ngIf="websiteupdate.errors?.required">*The website is required</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div class="form-group">
                <div class="input-field">
                    <label class="form-label" for="form3Example3">document</label>
                    <input type="file" formControlName="document" id="form3Example2" class="form-control" />

                </div>

            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-primary" (click)="editOrganization()" [disabled]="submitted">Edit <div
                *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>

    </div>
</ng-template>
<ng-template #delete let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Delete Organization</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i
                class="bi bi-x-lg"></i></button>
    </div>
    <div class="modal-body">
        <p>Are you sure that you want to Delete {{temporaire?.name}}?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-danger" (click)="delete_(temporaire)" [disabled]="submitted">Delete<div
                *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>

    </div>
</ng-template>
<ng-template #restore let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Restore Organization</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i
                class="bi bi-x-lg"></i></button>
    </div>
    <div class="modal-body">
        <p>Are you sure that you want to Restore {{temporaire?.name}}?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-warning" (click)="restoreOrganization(temporaire)"
            [disabled]="submitted">Restore<div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status">
            </div></button>
    </div>
</ng-template>
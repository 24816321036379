<div class="container-fluid">
    <main>
        <div class="pagetitle">
            <h1>Payement History</h1>
            <nav style="display: flex;justify-content: space-between;">

                <div>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/dashbord">Home</a></li>
                        <li class="breadcrumb-item active">Finance</li>
                        <li class="breadcrumb-item active">Payement history</li>
                    </ol>
                </div>
            </nav>


            <div class="card" style="background-color: white;">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-12">
                            <div class="card-body pt-3">
                                <div class="tab-content pt-2">


                                    <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
                                        <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                                            <app-spinner></app-spinner>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="DataStateEnum.ERROR">


                                        </ng-container>
                                        <ng-container *ngSwitchCase="DataStateEnum.LOADED">

                                            <div class="tab-pane fade show active profile-overview" id="profile-edit">
                                                <table datatable [dtOptions]="dtOptions" class="row-border hover">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Code</th>
                                                            <th>Amount</th>
                                                            <th>Fees</th>
                                                            <th>Earn</th>
                                                            <th>Project</th>
                                                            <th>State</th>
                                                            <th>Action</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let val of finance, let i = index">
                                                            <th>{{i+1}}</th>
                                                            <th>{{val?.countryCode}}</th>
                                                            <th>{{val?.amount}}</th>
                                                            <th>{{val?.fees}}</th>
                                                            <th>{{val?.earns}}</th>
                                                            <th>{{val?.project.name}}</th>
                                                            <th>{{val?.state}}</th>
                                                            <th><button type="button"
                                                                [routerLink]="['/dashbord/payement-details/', val.id]"
                                                                class="btn btn-success btn-circle"><i
                                                                    class="fas fa-eye"></i></button></th>


                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Code</th>
                                                            <th>Amount</th>
                                                            <th>Fees</th>
                                                            <th>Earn</th>
                                                            <th>Project</th>
                                                            <th>State</th>
                                                            <th>Action</th>


                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>




                                        </ng-container>
                                    </ng-container>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>


    </main>

</div>
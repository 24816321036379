<main id="main" class="main justify-content-center align-items-center">
    <div class=" container pagetitle" style="width: 100%;">
        <h2 style="padding: 20px;"> PROJECT DETAILS</h2>
        <nav style="display:flex;justify-content: space-between;">

            <div>
                <ol class="breadcrumb">

                    <li class="breadcrumb-item"><a routerLink="/dashbord">Projet</a></li>
                    <li class="breadcrumb-item active">details </li>
                </ol>
            </div>
            <div>
                <button class="btn btn-success" (click)="back()"> back</button>
            </div>
        </nav>
    </div>
    <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
        <ng-container *ngSwitchCase="DataStateEnum.LOADING">
            <app-spinne></app-spinne>
        </ng-container>
        <ng-container *ngSwitchCase="DataStateEnum.ERROR">


        </ng-container>
        <ng-container *ngSwitchCase="DataStateEnum.LOADED">
            <section>
                <form action="createfond">
                    <div class="card bg-white border border-dark-subtle mydiv">
                        <div class="card-body firstdiv">
                            <div class="row">
                                <p>PRIVATE KEY : <i class="bi bi-file-earmark" style="height: 30px; width: 20px;color: aqua;"></i> <button class="btn btn-success">{{detailsProjet.privateKey}}</button></p>

                            </div>
                            <hr>
                            <div class="row">
                                <p>PUBLIC KEY : <i class="bi bi-file-earmark" style="height: 30px; width: 20px;color: aqua;"></i> <button class="btn btn-success">{{detailsProjet.publicKey}}</button></p>

                            </div>
                            <div class="row">
                                <p>Status : <i class="bi bi-file-earmark" style="height: 30px; width: 20px;color: aqua;"></i> <button [ngClass]="getClassOf(detailsProjet.status)">{{detailsProjet.status}}</button></p>

                            </div>
                            <hr>
                        </div>
                    </div>
                    <div class="card bg-white border border-dark-subtle mydiv">
                        <h5 class="align text-left" style="padding: 20px;">INFORMATIONS GENERALE</h5>
                        <hr>
                        <div class="card-body">



                            <div class="form-group">
                                <label for="nomdelacollecte" class="label required"> Project name</label>
                                <input class="form-control" type="text" value="{{detailsProjet.name}}">
                            </div>
                            <div class="form-group">
                                <label for="labeldelacollecte" class="label required"> Type of items sold</label>
                                <input name="" id="" class="form-control" value="{{detailsProjet.itemTypeSold}}">
                            </div>
                            <div class="form-group">
                                <label for="labeldelacollecte" class="label required"> Project description</label>
                                <textarea class="form-control" type="text" style="height: 100px;" value="{{detailsProjet.description}}"></textarea>
                            </div>

                        </div>

                    </div>
                    <div class="card bg-white border border-dark-subtle mydiv">
                        <h5 class="align text-left" style="padding: 20px;">TECHNICAL INFORMATIONS</h5>
                        <hr>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="labeldelacollecte" class="label required"> Notify URL</label>
                                <input class="form-control" type="text" required value="{{detailsProjet.notifyUrl}}">

                            </div>
                            <div class="form-group">
                                <label for="labeldelacollecte" class="label"> Cancel Url(Recommended)</label>
                                <input class="form-control" type="text" value="{{detailsProjet.cancelUrl}}">

                            </div>
                            <div class="form-group">
                                <label for="labeldelacollecte" class="label"> Sucess Url(Recommended)</label>
                                <input class="form-control" type="text" value="{{detailsProjet.successUrl}}">
                            </div>
                        </div>

                    </div>
                    <div class="card bg-white border border-dark-subtle">
                        <h5 class="align text-left" style="padding: 20px;">ADDITIONAL INFORMATIONS</h5>
                        <hr>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="labeldelacollecte" class="label required">Website URL</label>
                                <input class="form-control" type="text" required value="{{detailsProjet.websiteUrl}}">

                            </div>
                            <div class="form-group">
                                <label for="labeldelacollecte" class="label required" required>Additional charges</label>
                                <input class="form-control" type="text" placeholder="" value="{{detailsProjet.isCustomerCharge}}">
                            </div>
                            <div class="form-group">
                                <label>Selected Organization</label>
                                <div class="d-flex">
                                    <select formControlName="organizationID" value="{{detailsProjet.organization.name
                                    }}" class="form-control" id="operators">
                                <option></option>

                            </select>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="labeldelacollecte" class="label required">Primary beneficiary</label>
                                <input class="form-control" type="text" required value="{{detailsProjet.principalB.designation}}">
                            </div>

                            <div class="row">
                                <div class="col-12 col-md-6 justify-content-left">
                                    <div class="form-group">
                                        <label for="labeldelacollecte" class="label required">Operators used for available payments</label>
                                        <select class="form-control" type="text"></select>
                                        <button class="btn btn-primary"><i class="bi bi-plus">add</i></button>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 align-content-left">
                                    <div class="form-group">
                                        <label for="labeldelacollecte" class="label required">Selected operators </label>
                                        <textarea class="form-control" style="overflow:scroll;height: 100px;" *ngFor="let groups of detailsProjet.operators;">
                                        {{groups.designation}}
                                      </textarea>
                                        <button class="btn btn-primary "><i class="bi bi-dash">Delete</i></button>

                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="labeldelacollecte" class="label">Project closing date(optional)</label>
                                <input class="form-control" type="date" value="{{detailsProjet.projectClosingDate}}">
                            </div>

                        </div>

                    </div>
                    <div class="card bg-white border border-dark-subtle mydiv">

                        <h5 class="align text-left" style="padding: 20px">INFORMATIONS ABOUT THE PROJECT ORGANIZER OR THE COMPANY</h5>
                        <hr>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="nomdelacollecte" class="label required"> Name</label>
                                <input class="form-control" type="text" value="{{detailsProjet.createdBy.name}}" required>
                            </div>
                            <div class="form-group">
                                <label for="labeldelacollecte" class="label required"> Address</label>
                                <input class="form-control" type="text" value="{{detailsProjet.createdBy.adress}}" required>
                            </div>
                            <div class="form-group">
                                <label for="labeldelacollecte" class="label required"> City</label>
                                <input class="form-control" type="text" value="{{detailsProjet.createdBy.city}}" required>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="labeldelacollecte" class="label required">Email</label>
                                        <input class="form-control" type="email" value="{{detailsProjet.createdBy.email}}" required>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="labeldelacollecte" class="label required">Numero de telephone </label>
                                        <input class="form-control" type="select" value="{{detailsProjet.createdBy.phone}}" required>

                                    </div>
                                </div>
                            </div>



                            <div class="form-group">
                                <label for="labeldelacollecte" class="label required"> Website URL if applicable</label>
                                <input class="form-control" type="text" value="{{detailsProjet.websiteUrl}}" required>
                            </div>

                        </div>
                    </div>

                    <div class="card bg-white">
                        <h5 class="align text-left" style="padding: 20px;">VALIDATIONS DES INFORMATIONS</h5>
                        <hr>
                        <div class="card-body">
                            <div class="form-group">
                                <input type="checkbox" class="mycheckbox" name="validate" checked disabled> <b>je declare lu et compris l'accord de confidentialite</b>
                            </div>

                        </div>

                    </div>

                </form>
            </section>
        </ng-container>
    </ng-container>
</main>
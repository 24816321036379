import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class MomoService extends BaseService<any> {

  constructor(private httpCient: HttpClient) {
    super(
      httpCient,
      "payment/process/payMoMo"
    );
  }
}

<div class="container-fluid">
    <main>
        <div class="pagetitle">
            <h1>Beneficiary</h1>
            <nav style="display: flex;justify-content: space-between;">

                <div>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/dashbord">Home</a></li>
                        <li class="breadcrumb-item active">Beneficiary</li>
                    </ol>
                </div>
                <div>
                    <app-toasts></app-toasts>
                    <button type="button" class="btn btn-primary" (click)="showDialog(add)"><i class="fas fa-plus"></i>Add</button>

                </div>

            </nav>



            <div class="card" style="background-color: white;">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-12">
                            <div class="card-body pt-3">
                                <ul class="nav nav-tabs nav-tabs-bordered">

                                    <li class="nav-item"> <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-edit">Active Beneficiary</button></li>

                                    <li class="nav-item"> <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password">Disabled
                                            Beneficiary</button></li>
                                </ul>
                                <div class="tab-content pt-2">

                    <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
                        <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                            <app-spinner></app-spinner>
                        </ng-container>
                        <ng-container *ngSwitchCase="DataStateEnum.ERROR">


                        </ng-container>
                        <ng-container *ngSwitchCase="DataStateEnum.LOADED">
                        

                                            <div class="tab-pane fade show active profile-overview" id="profile-edit">
                                                <table datatable [dtOptions]="dtOptions"  class="row-border hover">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Description</th>
                                                            <th>Code</th>
                                                            <th>Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let group of activeBeneficiary; let i = index">
                                                            <td>{{i+1}}</td>
                                                            <td>{{group.designation}}</td>
                                                            <td>{{group.code}}</td>
                                                            <td> <button type="button" (click)="showDialogVal(Edit,group)" class="btn btn-warning btn-circle" style="margin-right: 5px;"><i
                                                                class="bi bi-pencil"></i></button>
                                                                <button type="button" (click)="showDialogVal(delete,group)" class="btn btn-danger btn-circle"><i
                                                                class="fas fa-trash"></i></button></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>


                                           
                               
                        </ng-container>
                    </ng-container>


                    <ng-container *ngIf="data$ | async as result" [ngSwitch]="result.dataState">
                        <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                            <app-spinner></app-spinner>
                        </ng-container>
                        <ng-container *ngSwitchCase="DataStateEnum.ERROR">


                        </ng-container>
                        <ng-container *ngSwitchCase="DataStateEnum.LOADED">
                                         <div class="tab-pane fade pt-3" id="profile-change-password">
                                                <table datatable [dtOptions]="dtOptions"  class="row-border hover">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Description</th>
                                                            <th>Code</th>
                                                            <th>Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let groups of enableBeneficiary; let i = index">
                                                            <td>{{i+1}}</td>
                                                            <td>{{groups.designation}}</td>
                                                            <td>{{groups.code}}</td>
                                                            <td> <button type="button" (click)="showDialogVal(restore,groups)" class="btn btn-warning btn-circle"><i
                                                                class="fas fa-recycle" ></i></button></td>
                                                        </tr>
                                                    </tbody>
                                                </table>



                                            </div>
                               
                        </ng-container>
                    </ng-container>
                        </div>
                    </div>
                </div>

                </div>
                </div>

            </div>
        </div>


    </main>

</div>

<ng-template #add let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Add Beneficiary</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="bi bi-x-lg"></i></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="addbeneficiaryvalue" novalidate>
            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="submitError">*{{submitError?.message}}.</span>
            <div class="form-group">
                <div class="input-field" [class.error]="designation.invalid && designation.hasError('required','type')">
                    <label class="form-label" for="form3Example3">Designation<span class="text-danger me-1">*</span></label>
                    <input type="text" formControlName="designation" placeholder="beneficiary name" required id="form3Example3" class="form-control" />
                    <div *ngIf="designation.invalid && (designation.touched || designation.dirty)">
                        <span class="error fs-6 text-danger mx-2 my-2" *ngIf="designation.errors?.required">*The designation is required</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="input-field" [class.error]="code.invalid && code.hasError('required','type')">
                    <label class="form-label" for="form3Example3">Code<span class="text-danger me-1">*</span></label>
                    <input type="text" formControlName="code" placeholder="beneficiary code" s required id="form3Example3" class="form-control" />
                    <div *ngIf="code.invalid && (code.touched || code.dirty)">
                        <span class="error fs-6 text-danger mx-2 my-2" *ngIf="code.errors?.required">*The code is required</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-primary" [disabled]="submitted" (click)="addBeneficiary()">Save<div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>

    </div>
</ng-template>

<ng-template #Edit let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Edit Beneficiary</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="bi bi-x-lg"></i></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="updateBeneficiary" novalidate>
            <div class="form-group">
                <div class="input-field" style="display:none">
                    <label class="form-label" for="form3Example3">id<span class="text-danger me-1">*</span></label>
                    <input type="text" formControlName="id" [ngModel]="temporaire.id" required id="form3Example1" class="form-control" />
                </div>
                <div class="input-field" [class.error]="designationupdate.invalid && designationupdate.hasError('required','type')">
                    <label class="form-label" for="form3Example3">Designation<span class="text-danger me-1">*</span></label>
                    <input type="text" [ngModel]="temporaire.designation" formControlName="designation" placeholder="franck" required id="form3Example3" class="form-control" />
                    <div *ngIf="designationupdate.invalid && (designationupdate.touched || designationupdate.dirty)">
                        <span class="error fs-6 text-danger mx-2 my-2" *ngIf="designationupdate.errors?.required">*The designation is required</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="input-field" [class.error]="codeupdate.invalid && code.hasError('required','type')">
                    <label class="form-label" for="form3Example3">Code<span class="text-danger me-1">*</span></label>
                    <input type="text" [ngModel]="temporaire.code" formControlName="code" placeholder="CA123" required id="form3Example2" class="form-control" />
                    <div *ngIf="codeupdate.invalid && (codeupdate.touched || codeupdate.dirty)">
                        <span class="error fs-6 text-danger mx-2 my-2" *ngIf="codeupdate.errors?.required">*The code is required</span>
                    </div>
                </div>

            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-primary" (click)="editBeneficiary()" [disabled]="submitted">Edit <div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>

    </div>
</ng-template>


<ng-template #delete let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Delete Beneficiary</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="bi bi-x-lg"></i></button>
    </div>
    <div class="modal-body">
        <p>Are you sure that you want to Delete {{temporaire?.name}}?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-danger" (click)="delete_(temporaire)" [disabled]="submitted">Delete<div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>

    </div>
</ng-template>

<ng-template #restore let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Restore this Beneficiary</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="bi bi-x-lg"></i></button>
    </div>
    <div class="modal-body">
        <p>Are you sure that you want to Restore {{temporaire?.name}}?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-warning" (click)="beneficiaryrestore(temporaire)" [disabled]="submitted">Restore<div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>
    </div>
</ng-template>
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { AuthentificationService } from '@services/authentification.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HasRoleGuard implements CanActivate {
  constructor(private authService: AuthentificationService) {}
    admin:any;
    serveur:any;
    auth:any
    validate = false;
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // const isAuthorizedp = this.authService.role.includes(route.data['role'][0]);
    // const isAuthorizeds = this.authService.test?.user?.role?.title.includes(route.data['role'][1]);
    if(this.authService.role){

       this.auth = this.authService.role.includes(route.data['role'])
        if(this.auth == true){
          this.validate= this.auth;
   
      }
    }
    if (!this.validate) {
      // redirect
      // display a message
      window.alert("Vous n'etes pas autorisés");
      this.authService.logout()
    }

    return this.validate || false;
  }
}

import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { OrganizationService } from '@services/organization.service';
import { Organization } from '@/models/organization';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-user-organization',
  templateUrl: './user-organization.component.html',
  styleUrls: ['./user-organization.component.scss'],
  providers: [NgbModalConfig, NgbModal]

})
export class UserOrganizationComponent {
  datas$: Observable<AppDataState<any[]>> |null = null;
  data$: Observable<AppDataState<any[]>> |null = null;
  enableOrganization!: Organization[];
  activeOrganization!: Organization[];
  addorganizationModal: boolean=false;
  editorganizationModal: boolean=false;
  submitError!: any;
  submitted: boolean=false;
  temporaire!:any;
  edit: boolean=false;
  addorganisationValue:any;
  organisationUpdate:any;
  userinformation = JSON.parse(localStorage.getItem("user")!);
  id = this.userinformation.id;
  invalid= false;
  activeOrganisation!:any;
  disabledOrganisation!: any;
  dataLoad: boolean=true;
  readonly DataStateEnum=DataStateEnum;
  dtOptions: DataTables.Settings = {};

  get name(){return this.addorganisationValue.get('name'); }
  get address(){return this.addorganisationValue.get('address'); }
  get city(){return this.addorganisationValue.get('city'); }
  get email(){return this.addorganisationValue.get('email'); }
  get phone(){return this.addorganisationValue.get('phone'); }
  get website(){return this.addorganisationValue.get('website'); }


  get nameupdate(){return this.organisationUpdate.get('name'); }
  get addressupdate(){return this.organisationUpdate.get('address'); }
  get cityupdate(){return this.organisationUpdate.get('city'); }
  get emailupdate(){return this.organisationUpdate.get('email'); }
  get phoneupdate(){return this.organisationUpdate.get('phone'); }
  get websiteupdate(){return this.organisationUpdate.get('website'); }
  get documentupdate(){return this.organisationUpdate.get('document'); }


  constructor(
    private route: Router,private organization:OrganizationService,config: NgbModalConfig, private modalService: NgbModal,private toastr: ToastrService) { 
      config.backdrop = 'static';
		config.keyboard = false;
    }

  async ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers'
    };
    this.addorganisationValue= new FormGroup({
      "name": new FormControl("", [Validators.required]),
      "address": new FormControl("", [Validators.required]),
      "city": new FormControl(""),
      "email": new FormControl(""),
      "phone": new FormControl(""),
      "website": new FormControl(""),
      "document": new FormControl(null),


    })

    this.organisationUpdate= new FormGroup({
      "id": new FormControl("", [Validators.required]),
      "name": new FormControl("", [Validators.required]),
      "address": new FormControl("", [Validators.required]),
      "city": new FormControl(""),
      "email": new FormControl(""),
      "phone": new FormControl(""),
      "website": new FormControl(""),
      "document": new FormControl(""),
    })

    this.getOrganisation();
  }


  getOrganisation(){

    this.datas$ = this.organization.getBycreated(this.id).pipe(
      map(data =>{
        this.activeOrganisation = data;
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );

    // this.organization.getAll(true)
    //  .subscribe(data => {
    //   this.activeOrganisation = data;
    //   console.log(this.activeOrganisation)

    //   this.dataLoad = false;


    //  },
    //  (error)=>{
    //   console.log(error.message);
    //   this.dataLoad = false;

    //  })
    }


  applyFilterGlobal($event:any) {
    return ($event.target as HTMLInputElement).value
  }
  showDialogadd() {
    this.addorganizationModal = true;
  }

  enabledialog(){
    this.addorganizationModal = false;

    this.editorganizationModal = false;

  }
  addOrganization(){

    if( this.addorganisationValue.valid && this.name.value != null && this.address.value != null){
      this.submitted = true;
      this.organization.create(this.addorganisationValue.value).subscribe((result)=>{
       this.enabledialog();
       this.submitted = false;

       this.ngOnInit();
       this.toastr.success('The action has been performed successfully', 'Success');
      },
      (error)=>{
       this.submitted = false;
       this.submitError = error;
       this.toastr.error('Action Failed an errror has occured', 'Error');
      }
      )
    }
  }
  delete_(value:any) {
    this.submitted = true;

    this.organization.delete(value).subscribe(data => {
      this.ngOnInit();
      this.closeDialofg()
      this.submitted = false;  
      this.toastr.warning('The action has been performed successfully', 'Success');


    },
    (error)=>{
      this.submitted = false;
      this.toastr.error('Action Failed an errror has occured', 'Error');
    })
  }

  restoreOrganization(value:any){
    this.submitted = true;

    this.organization.restoreState(value.id,true).subscribe(data => {
      this.closeDialofg()
      this.submitted = false;

      this.ngOnInit();
      this.toastr.success('The action has been performed successfully', 'Success');
    },
    (error)=>{
      this.submitted = false;
      this.toastr.error('Action Failed an errror has occured', 'Error');
    })
  }
  editOrganization(){
    this.submitted = true;
    console.log(this.organisationUpdate.value)
      this.organization.update(this.organisationUpdate.value).subscribe(data => {
        this.enabledialog();
        this.submitted = false;

        this.organisationUpdate.reset();
        this.closeDialofg()

        this.ngOnInit();
        this.toastr.success('The action has been performed successfully', 'Success');

      },
      (error)=>{
        this.submitted = false;
        this.submitError =error
        this.toastr.error('Action Failed an errror has occured', 'Error');
      })
  }
  open(content: any) {
		this.modalService.open(content);
	}
  closeDialofg(){
    this.modalService.dismissAll()
  }
  showDialogedit(content:any,val:any) {
    this.edit = true;
    this.temporaire = {...val};
		this.modalService.open(content);


  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OrganizationService } from '@services/organization.service';
import { ProjectsService } from '@services/project.service';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { principalBeneficiaryService } from '@services/principalBeneficiary.service';
import { OperatorService } from '@services/operator.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
  providers: [NgbModalConfig, NgbModal]

})
export class CreateProjectComponent implements OnInit{
  addprojectValue:any;
  addorganisationValue:any;
  submitteds: Boolean = false;

  submitted: Boolean = false;
  submitError:any;
  selectedCities:any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings:IDropdownSettings;
  activeOrganisation:any;
  activeBeneficiary:any;
  activeOperator:any;
  dataLoadBeneficiary:Boolean=false;
  dataLoadOrganization:Boolean=false;
  dataLoadOperator:Boolean=false;
  readonly DataStateEnum=DataStateEnum;
  userinformation = JSON.parse(localStorage.getItem("user")!);

  get name(){return this.addprojectValue.get('name'); }
  get description(){return this.addprojectValue.get('description'); }
  get notifyUrl(){return this.addprojectValue.get('notifyUrl'); }
  get cancelUrl(){return this.addprojectValue.get('cancelUrl'); }
  get successUrl(){return this.addprojectValue.get('successUrl'); }
  get websiteUrl(){return this.addprojectValue.get('websiteUrl'); }
  get organizationID(){return this.addprojectValue.get('organizationID'); }
  get principalCode(){return this.addprojectValue.get('principalCode'); }
  get operateurs(){return this.addprojectValue.get('operateurs'); }
  get itemTypeSold(){return this.addprojectValue.get('itemTypeSold'); }


  get names(){return this.addorganisationValue.get('name'); }
  get address(){return this.addorganisationValue.get('address'); }
  get city(){return this.addorganisationValue.get('city'); }
  get email(){return this.addorganisationValue.get('email'); }
  get phone(){return this.addorganisationValue.get('phone'); }
  get website(){return this.addorganisationValue.get('website'); }


  constructor(private operator: OperatorService ,private beneficiary:principalBeneficiaryService,private project: ProjectsService,private organization:OrganizationService,private modalService: NgbModal,private toastr: ToastrService){}
  ngOnInit(): void {
    this.addorganisationValue= new FormGroup({
      "name": new FormControl("", [Validators.required]),
      "address": new FormControl("", [Validators.required]),
      "city": new FormControl(""),
      "email": new FormControl(""),
      "phone": new FormControl(""),
      "website": new FormControl(""),
      "document": new FormControl(null),


    })
    this.addprojectValue= new FormGroup({
      "name": new FormControl("", [Validators.required]),
      "description": new FormControl("", [Validators.required]),
      "notifyUrl": new FormControl("", [Validators.required]),
      "cancelUrl": new FormControl("", [Validators.required]),
      "successUrl": new FormControl("", [Validators.required]),
      "websiteUrl": new FormControl("", [Validators.required]),
      "itemTypeSold": new FormControl(""),
      "nameOfItemSold": new FormControl(""),
      "organizationID": new FormControl("", [Validators.required]),
      "principalCode": new FormControl("", [Validators.required]),
      "operateurs": new FormControl("", [Validators.required]),
      "isPrivacyPolicyRead": new FormControl(false, [Validators.required]),
      "isCustomerCharge": new FormControl(true),

    })
    this.getOrganisation();
    this.getActiveBeneficiary();
    this.getOperator()
  }


  addproject(){
    console.log(this.addprojectValue.value)
    if( this.addprojectValue.valid && this.name.value != null && this.description.value != null){
      this.submitted = true;
      this.project.create(this.addprojectValue.value).subscribe((result)=>{
       this.submitted = false;

       this.ngOnInit();
       this.toastr.success('The action has been performed successfully', 'Success');
      },
      (error)=>{
       this.submitted = false;
       this.submitError = error;
       this.toastr.error('Action Failed an errror has occured', 'Error');
      }
      )
    }
  }

  value(val:any){

    console.log(val)
  }
  getOrganisation(){
    this.dataLoadOrganization = true;

    this.organization.getCreated(this.userinformation.id).subscribe(data =>{
        this.dataLoadOrganization = false;
        console.log(data)
        this.activeOrganisation = data;
      },
      
      (error)=>{
        console.log(error.message);
        this.dataLoadOrganization = false;
    
       })

    
    
    }

    getActiveBeneficiary(){
      this.dataLoadBeneficiary = true;

      this.beneficiary.getAll(true)
       .subscribe(data => {
        console.log(data)

        this.activeBeneficiary = data;
        this.dataLoadBeneficiary = false;
    
       },
       (error)=>{
        this.dataLoadBeneficiary = false;
    
       })
      }

      getOperator(){
        this.dataLoadOperator = true;
    
        this.operator.getAll(true)
        .subscribe(data => {
          console.log(data)
         this.activeOperator = data;
         this.dataLoadOperator = false;
     
        },
        (error)=>{
         this.dataLoadOperator = false;
     
        })
    
        
        
        }


        onItemSelect(item: any) {
          console.log(item);
        }
        onSelectAll(items: any) {
          console.log(items);
        }



        open(content: any) {
          this.modalService.open(content);
        }
        closeDialofg(){
          this.modalService.dismissAll()
        }
        addOrganization(){

          if( this.addorganisationValue.valid && this.name.value != null && this.address.value != null){
            this.submitteds = true;
            this.organization.create(this.addorganisationValue.value).subscribe((result)=>{
             this.closeDialofg();
             this.submitteds = false;
      
             this.getOrganisation();
            },
            (error)=>{
             this.submitteds = false;
             this.submitError = error;
            }
            )
          }
        }
}

import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';

import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserComponent} from '@modules/main/header/user/user.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {MainMenuComponent} from './pages/main-menu/main-menu.component';
import {SubMenuComponent} from './pages/main-menu/sub-menu/sub-menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {ControlSidebarComponent} from './modules/main/control-sidebar/control-sidebar.component';
import {StoreModule} from '@ngrx/store';
import {ProfabricComponentsModule} from '@profabric/angular-components';
import {defineCustomElements} from '@profabric/web-components/loader';
import {SidebarSearchComponent} from './components/sidebar-search/sidebar-search.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { CheckmailValidateAccountComponent } from './site/auth/checkmail-validate-account/checkmail-validate-account.component';
import { EmailActivateAccountComponent } from './site/auth/email-activate-account/email-activate-account.component';
import { EmailResetPasswordComponent } from './site/auth/email-reset-password/email-reset-password.component';
import { LoginComponent } from './site/auth/login/login.component';
import { RegisterComponent } from './site/auth/register/register.component';
import { ResetPasswordComponent } from './site/auth/reset-password/reset-password.component';
import { ValidateAccountComponent } from './site/auth/validate-account/validate-account.component';
import { DocumentationComponent } from './site/documentation/documentation.component';
import { HomeComponent } from './site/home/home.component';
import { authReducer } from './store/auth/reducer';
import { uiReducer } from './store/ui/reducer';
import { httpsqInterceptor } from './intercepteur/http.interceptor';
import { HeaderComponent } from '@modules/main/header/header.component';
import { HeadersComponent } from './layouts/headers/headers.component';
import { OrganizationComponent } from './components/organization/organization.component';
import { ProfileComponent } from './components/profile/profile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner";
import { SpinnerComponent } from './layouts/spinner/spinner.component';
import { DataTablesModule } from "angular-datatables";
import { CurrencyComponent } from './components/super/currency/currency.component';
import { OperatorsComponent } from './components/super/operators/operators.component';
import { UsersComponent } from './components/super/users/users.component';
import { BeneficiaryComponent } from './components/super/beneficiary/beneficiary.component';
import { SettingsComponent } from './components/super/settings/settings.component';
import { UserDetailsComponent } from './components/super/user-details/user-details.component';
import { OwnerDetailsComponent } from './components/organization/owner-details/owner-details.component';
import { DetailOrganizationComponent } from './components/organization/detail-organization/detail-organization.component';
import { ProjetsComponent } from './components/projets/projets.component';
import { CreateProjectComponent } from './components/projets/create-project/create-project.component';
import { ProjectDetailsComponent } from './components/projets/project-details/project-details.component';
import { UpdateProjectComponent } from './components/projets/update-project/update-project.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { UserProjectComponent } from './components/projets/user-project/user-project.component';
import { UserOrganizationComponent } from './components/organization/user-organization/user-organization.component';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { SpecificUserProjectComponent } from './components/projets/specific-user-project/specific-user-project.component';
import { PayementComponent } from './components/payement/payement.component';
import { FinanceComponent } from './components/super/finance/finance.component';
import { UserfinanceComponent } from './components/userfinance/userfinance.component';
import { PayementHistoryComponent } from './components/payement-history/payement-history.component';
import { WithdrawHistoryComponent } from '@components/withdraw-history/withdraw-history.component';
import { WithdrawDetailsComponent } from './components/withdraw-history/withdraw-details/withdraw-details.component';
import { PayementDetailsComponent } from './components/payement-history/payement-details/payement-details.component';
import { DemoPayementComponent } from './components/demo-payement/demo-payement.component';
import { AdminFinanceComponent } from './components/payement/admin-finance/admin-finance.component';


interface NgxSpinnerConfig {
    type?: string;
  }


@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        MenuSidebarComponent,
        BlankComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        LanguageComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        ControlSidebarComponent,
        SidebarSearchComponent,
        FooterComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        ValidateAccountComponent,
        EmailActivateAccountComponent,
        EmailResetPasswordComponent,
        ResetPasswordComponent,
        CheckmailValidateAccountComponent,
        DocumentationComponent,
        HeadersComponent,
        OrganizationComponent,
        ProfileComponent,
        SpinnerComponent,
        CurrencyComponent,
        OperatorsComponent,
        UsersComponent,
        BeneficiaryComponent,
        SettingsComponent,
        UserDetailsComponent,
        OwnerDetailsComponent,
        DetailOrganizationComponent,
        ProjetsComponent,
        CreateProjectComponent,
        ProjectDetailsComponent,
        UpdateProjectComponent,
        UserProjectComponent,
        UserOrganizationComponent,
        SpecificUserProjectComponent,
        PayementComponent,
        FinanceComponent,
        UserfinanceComponent,
        PayementHistoryComponent,
        WithdrawHistoryComponent,
        WithdrawDetailsComponent,
        PayementDetailsComponent,
        AdminFinanceComponent,
        DemoPayementComponent,
    ],
    imports: [
        NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
        MultiSelectModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        DataTablesModule,
        MatSelectModule,
        MatFormFieldModule,
        ProfabricComponentsModule,
        NgMultiSelectDropDownModule.forRoot(),
        StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        NgbModule,
        NgbPaginationModule, 
        NgbAlertModule,
       

        
 
    ],
    providers: [{provide: HTTP_INTERCEPTORS, useClass:httpsqInterceptor,multi:true},
        {provide: LocationStrategy, useClass: HashLocationStrategy}],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule {}

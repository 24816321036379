<div class="container-fluid">
    <main>
        <div class="pagetitle">
            <h3>Users Details</h3>
            <nav style="display: flex;justify-content: space-between;">

                <div>
                    <ol class="breadcrumb">

                        <li class="breadcrumb-item"><a routerLink="/dashbord">Home</a></li>
                        <li class="breadcrumb-item">users</li>
                        <li class="breadcrumb-item active">Details</li>
                    </ol>
                </div>

                <div> <button class="btn btn-success" (click)="back()"> back</button></div>
            </nav>
            <div>
                <div class="container-fluid">
                    <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
                        <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                            <app-spinner></app-spinner>
                        </ng-container>
                        <ng-container *ngSwitchCase="DataStateEnum.ERROR">


                        </ng-container>
                        <ng-container *ngSwitchCase="DataStateEnum.LOADED">
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-12">
                                    <section class="section profile">
                                        <div class="row">
                                            <div class="col-xl-4">
                                                <div class="card" style="padding: 20px 20px 20px 20px;" style="background-color: white;margin-right: 0px !important;">
                                                    <div class="card-body profile-card pt-4 d-flex flex-column align-items-center" style="padding-left: 10px;padding-right: 10px;">

                                                        <img src="assets/backend/images/avatar.png" alt="Profile" width="40px" height="40px" class="rounded-circle" style="padding-bottom: 10px;">
                                                        <h5>{{userinfo.name}} | {{userinfo.city}}</h5>
                                                        <h3>{{userinfo.role}}</h3>
                                                        <div class="row mybox" style="width: 100%;">
                                                            <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                <div class="size11"><b> Email: </b></div>
                                                                <div class="size12">{{userinfo.email}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="row mybox" style="width: 100%;">
                                                            <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                <div class="size11"><b> Phone: </b></div>
                                                                <div class="size12">{{userinfo.phone}}</div>
                                                            </div>
                                                        </div>

                                                        <div class="row mybox" style="width: 100%;">
                                                            <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                <div class="size11"><b> Adress: </b></div>
                                                                <div class="size12">{{userinfo.address}}</div>
                                                            </div>
                                                        </div>

                                                        <div class="row mybox" style="width: 100%;">
                                                            <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                <div class="size11"><b> Birthday: </b></div>
                                                                <div class="size12">{{userinfo.birthday}}</div>
                                                            </div>
                                                        </div>

                                                        <div class="row mybox" style="width: 100%;">
                                                            <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                <div class="size11"><b> Created: </b></div>
                                                                <div class="size12">{{userinfo.updated}}</div>
                                                            </div>
                                                        </div>

                                                        <div class="row mybox" style="width: 100%;">
                                                            <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                <div class="size11"><b> Updated: </b></div>
                                                                <div class="size12">{{userinfo.updated}}</div>
                                                            </div>
                                                        </div>

                                                        <div class="row mybox" style="width: 100%;">
                                                            <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                <div class="size11"><b> Owned: </b></div>
                                                                <div class="size12">{{userinfo.role}}</div>
                                                            </div>
                                                        </div>




                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-xl-8">
                                                <div class="card">
                                                    <div class="card-body pt-3">
                                                        <ul class="nav nav-tabs nav-tabs-bordered">

                                                            <li class="nav-item"> <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-edit">Related Users</button></li>

                                                            <li class="nav-item"> <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password">User Projects</button></li>
                                                        </ul>
                                                        <div class="tab-content pt-2">

                                                            <div class="tab-pane fade show active profile-overview" id="profile-edit">
                                                                <div style="align-items:end;justify-content:end">
                                                                    <span class="p-input-icon-left">
                                                            <i class="pi pi-search"></i>
                                                            <input type="text" pInputText  placeholder="Search keyword"/>
                                                       </span>

                                                                </div>
                                                                <br id="">
                                                                <table class="table">



                                                                    <thead>
                                                                        <tr>
                                                                            <th style="width:5%">#</th>
                                                                            <th>Name</th>
                                                                            <th>Email</th>
                                                                            <th>Role</th>


                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>


                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>


                                                            <div class="tab-pane fade pt-3" id="profile-change-password">
                                                                <div style="align-items:end;justify-content:end">
                                                                    <span class="p-input-icon-left">
                                                            <i class="pi pi-search"></i>
                                                            <input type="text" pInputText  placeholder="Search keyword"/>
                                                       </span>

                                                                </div>
                                                                <br>
                                                                <table class="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style="width:5%">#</th>
                                                                            <th> Project Name</th>
                                                                            <th>Created Date</th>
                                                                            <th>Role</th>


                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>


                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                </div>

            </div>
        </div>


    </main>

</div>
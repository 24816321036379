import { Component } from '@angular/core';

@Component({
  selector: 'app-checkmail-validate-account',
  templateUrl: './checkmail-validate-account.component.html',
  styleUrls: ['./checkmail-validate-account.component.css']
})
export class CheckmailValidateAccountComponent {

}

import { AppDataState, DataStateEnum } from '@/State/load.state';
import { Currency } from '@/models/currency';
import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyService } from '@services/currency.service';
import { FinanceService } from '@services/finance.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, map, startWith, catchError, of } from 'rxjs';
@Component({
  selector: 'app-admin-finance',
  templateUrl: './admin-finance.component.html',
  styleUrls: ['./admin-finance.component.scss']
})
export class AdminFinanceComponent {
  data$: Observable<AppDataState<any[]>> |null = null;
  dtOptions: DataTables.Settings = {};

  datas$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  submitError!: any;
  submitted: boolean=false;
  dataLoad: boolean=true;
  finance!:any;
  temporaire!:any;
  edit: boolean=false;
  adddeleteModal: boolean=false;
  state: string = "INITIAL";
  constructor(
    private financeService: FinanceService,
    private router: Router,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private toastr: ToastrService

  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers'
    };
    // create value form



    this.getUsersFinance();
  }
  
  setState(val:any){
    this.state = val;
    console.log('ok')
    this.getUsersFinance();
  }
  

  getUsersFinance() {
    console.log('getUsersFinance');
    this.datas$ = this.financeService.getPayementState(this.state).pipe(
      map(data =>{
        console.log('getUsersFinance');
        this.finance = data;
        console.log(this.finance)
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );
  }
  
}

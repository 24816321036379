import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from '@services/payment.service';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MomoService } from '@services/momo.service';

@Component({
  selector: 'app-payement',
  templateUrl: './payement.component.html',
  styleUrls: ['./payement.component.scss']
})
export class PayementComponent  implements OnInit{
data: any;
paymentError: any;

code: string;
paymentInfo: any;
mtn:string = "MOMO";
status: boolean = false;
orange:string= "OM";
mobile:string;
readonly DataStateEnum=DataStateEnum;
datas$: Observable<AppDataState<any[]>> |null = null;
constructor(private paymentService:PaymentService,private router: Router,private route:ActivatedRoute,private momoService: MomoService){

}

ngOnInit() {
  this.paymentInfo = new FormGroup({
    "amount": new FormControl("", [Validators.required]),
    "currency": new FormControl("", [Validators.required]),
    "externalId": new FormControl("1234-0458784", [Validators.required]),
    "paymentKey": new FormControl("", [Validators.required]),
    "operator": new FormControl("MoMo",[Validators.required]),
    "payer": new FormGroup({
      "partyIdType": new FormControl("MSISDN",[Validators.required]),
      "partyId": new FormControl("",[Validators.required]),
    }),
    "payerMessage": new FormControl("TEST"),
    "payeeNote": new FormControl("test2",[Validators.required,Validators.email]),
  })
  this.code= this.route.snapshot.paramMap.get('code');
  this.getPayment();

}
getPayment(){
  this.paymentService.getPayement(this.code)

  this.datas$ = this.paymentService.getPayement(this.code).pipe(
    map(val =>{
      this.data = val;
      console.log(this.data)
      return ({dataState:DataStateEnum.LOADED})
    }),
    startWith({dataState:DataStateEnum.LOADING}),
    catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
  );
}
 getActive(val: string){

  this.mobile = val

}
showvalue(){
  console.log(this.paymentInfo.value)
}
sendPayment(){
  this.status = true
  this.momoService.create(this.paymentInfo.value).subscribe((value)=>{
    console.log(value)
  },
  (error: any)=>{
    this.status = false
    this.paymentError = error;
  }
  )
 }
}

import { DataStateEnum, AppDataState } from '@/State/load.state';
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectsService } from '@services/project.service';
import { Observable, map, startWith, catchError, of } from 'rxjs';
import { Location } from '@angular/common'

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent {
  readonly DataStateEnum=DataStateEnum;
  datas$: Observable<AppDataState<any[]>> |null = null;
  id:any;
  detailsProjet:any;
  constructor(private location: Location,private router: Router,private route:ActivatedRoute,private detailsProjetService:ProjectsService){}

  ngOnInit(){
    this.id= this.route.snapshot.paramMap.get('id');
    this.getProjectInfo()
  }
  back(){
    this.location.back();
  }
  getClassOf(val) {
    if (val === "CONFIRM") {
      return 'btn btn-success';
    } 
     else {
      return 'btn btn-warning'
    }
  }



  getProjectInfo(){

    this.datas$ = this.detailsProjetService.getById(this.id).pipe(
      map(data =>{
        console.log(data)
        this.detailsProjet = data;
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );
  }
}



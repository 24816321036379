import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthentificationService } from '@services/authentification.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit{
  userInfo!:any;
  invalid= false;
  submitted = false;
  subscribError: any;
  userInvalidError: any;
  passInvalidError: any;
  get username(){return this.userInfo.get('email'); }
  get password(){return this.userInfo.get('password'); }
  constructor(private authentification: AuthentificationService,private route: Router) { }

  ngOnInit() {
    this.userInfo= new FormGroup({
      "email": new FormControl("", [Validators.required,Validators.email,Validators.pattern(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)]),
      "password": new FormControl("", [Validators.required,Validators.minLength(8)])
    })
  }
  login(){
    if( this.userInfo.valid && this.username.value != null && this.password.value != null){
      this.submitted = true;
      this.authentification.login(this.userInfo.value).subscribe((result)=>{
        this.route.navigateByUrl('/dashbord');
      },
      (error)=>{
       this.invalid = true;
       this.submitted = false;
       this.subscribError = error;
       console.log(error.message);
      }
      )
    }else if(this.username.value == null){
      this.userInvalidError = true;
    }else if(this.password.value == null){
      this.passInvalidError = true;

  }else{
    this.userInvalidError = true;
    this.passInvalidError = true;
  }
}
}

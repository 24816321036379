<main class="containe">

    <div class="container-fluid" id="cont" style="height: 100%;">
        <div style="width: 100%;height: 100%;">
            <img src="../../../assets/svg/hero-bg-shape.svg" style="position: absolute;bottom: 0px;right: 0px;" alt="" srcset="">
            <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
                <div class="cont">
                    <div class="left" style="align-items: center;justify-content: center;display: flex;">
                        <div class="pt-4 pb-2">
                            <h1 class="card-title text-Start pb-0 fs-1 size2" style="color: white;">Welcome !</h1>
                            <p class="text-Start small size1" style="color: white;">Confirm Your E-mail</p>
                        </div>
                    </div>
                    <div class="rigth" style=" align-items: center;justify-content: center;display: flex;">
                        <div class="card col bg-white shadow-md p-5" style="min-width: 318px;max-width: 640px;">
                            <div class="mb-4 text-center">
                                <img src="../../../assets/images/email.jpg" width="75px" height="75px" alt="" srcset="">

                            </div>
                            <div class="text-center">
                                <h1>Confirmation !</h1>
                                <p><b><i>  A confirmation email has been sent to you check your Inbox</i></b>
                                </p>
                                <div class="col-12">

                                    I did not receive a <a (click)="redirect()" style="color: blue; cursor: pointer;">Resend Link</a>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</main>
<div class="container-fluid">
    <main>
        <div class="pagetitle">
            <h1>Withdraw Details</h1>
            <nav>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/dashbord">Finance</a></li>
                    <li class="breadcrumb-item active">Withdraw detail</li>
                </ol>
            </nav>
            <section class="section profile" style="justify-content: center;align-items: center;display: flex;">
                <div class="card" style="width: 80%;">
                    <div class="card-header">Transaction Details</div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4 d-flex justify-content-center align-items-center">
                                <div class="card-body profile-card pt-4 d-flex flex-column align-items-center pad">
                                    <h3>Operator</h3>
                                    <img src="../../../assets/images/orange.png" width="100%" height="100%" alt="" srcset="">

                                </div>
                            </div>
                            <div class="col-md-8">
                                <div style="display: flex;justify-content: space-between;margin-bottom: 7px;">
                                    <div class="size11">Phone :</div>
                                    <div class="size12">{{params?.name}}</div>
                                </div>
                                <div style="display: flex;justify-content: space-between;margin-bottom: 7px;">
                                    <div class="size11">Amount :</div>
                                    <div class="size12">{{params?.name}}</div>
                                </div>
                                <div style="display: flex;justify-content: space-between;margin-bottom: 7px;">
                                    <div class="size11">Currency :</div>
                                    <div class="size12">{{params?.name}}</div>
                                </div>
                                <div style="display: flex;justify-content: space-between;margin-bottom: 7px;">
                                    <div class="size11">WithdrawBy :</div>
                                    <div class="size12">{{params?.name}}</div>
                                </div>
                                <div style="display: flex;justify-content: space-between;margin-bottom: 7px;">
                                    <div class="size11">Date :</div>
                                    <div class="size12">{{params?.name}}</div>
                                </div>
                                <div style="display: flex;justify-content: space-between;margin-bottom: 7px;">
                                    <div class="size11">Transaction UUID :</div>
                                    <div class="size12">{{params?.name}}</div>
                                </div>
                                <div style="display: flex;justify-content: space-between;margin-bottom: 6px;">
                                    <div class="size11">Project :</div>
                                    <div class="size12">{{params?.name}}</div>
                                </div>
                                <div style="display: flex;justify-content: space-between;margin-bottom: 6px;">
                                    <div class="size11">State :</div>
                                    <div class="size12">{{params?.name}}</div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div style="width:100%;display: flex;justify-content: space-between;padding: 2%;">
                        <div><button class="btn btn-light"><i class="bi bi-printer-fill"></i> print</button></div>
                        <div>
                            <button class="btn btn-primary" style="margin-right: 10px;"><i class="bi bi-filetype-pdf"></i> Generate PDF</button>
                            <button class="btn btn-success"><i class="bi bi-envelope"></i> Send to client</button>
                        </div>
                    </div>
                </div>

            </section>
        </div>


    </main>

</div>
<app-spinner *ngIf="submitted"></app-spinner>
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { AppDataState, DataStateEnum } from '@/State/load.state';

import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Project } from '@/models/project';
import { ProjectsService } from '@services/project.service';
import { ProjectService } from '@services/projects.service';
import { Location } from '@angular/common'
@Component({
  selector: 'app-user-project',
  templateUrl: './user-project.component.html',
  styleUrls: ['./user-project.component.scss'],
  providers: [NgbModalConfig, NgbModal]

})
export class UserProjectComponent {
  datas$: Observable<AppDataState<any[]>> |null = null;
  data$: Observable<AppDataState<any[]>> |null = null;
  enableProjet!: Project[];
  activeProjet!: any;
  addProjetModal: boolean=false;
  editProjetModal: boolean=false;
  submitError!: any;
  submitted: boolean=false;
  temporaire!:any;
  edit: boolean=false;
  addProjetValue:any;
  ProjetUpdate:any;
  invalid= false;
  projectUsers!:any;
  disabledProjets!: any;
  dataLoad: boolean=true;
  readonly DataStateEnum=DataStateEnum;
  dtOptions: DataTables.Settings = {};
  userinformation = JSON.parse(localStorage.getItem("user")!);
  id: any;

  constructor(
    private location: Location,
    private route:ActivatedRoute,
    private router: Router,private projetService:ProjectsService,config: NgbModalConfig, private modalService: NgbModal) { 
      config.backdrop = 'static';
		config.keyboard = false;
    }

  async ngOnInit() {
    this.id= this.route.snapshot.paramMap.get('id')
    this.dtOptions = {
      pagingType: 'full_numbers'
    };
    this.addProjetValue= new FormGroup({
      "name": new FormControl("", [Validators.required]),
      "address": new FormControl("", [Validators.required]),
      "city": new FormControl(""),
      "email": new FormControl(""),
      "phone": new FormControl(""),
      "website": new FormControl(""),
      "document": new FormControl(""),


    })

    this.ProjetUpdate= new FormGroup({
      "id": new FormControl("", [Validators.required]),
      "name": new FormControl("", [Validators.required]),
      "address": new FormControl("", [Validators.required]),
      "city": new FormControl(""),
      "email": new FormControl(""),
      "phone": new FormControl(""),
      "website": new FormControl(""),
      "document": new FormControl(""),
    })

    this.getProjet();
  }

  back(){
    this.location.back();
  }
  getProjet(){

    this.datas$ = this.projetService.getById(this.id).pipe(
      map(data =>{
        console.log(data)
        this.projectUsers = data;
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );
  }



  applyFilterGlobal($event:any) {
    return ($event.target as HTMLInputElement).value
  }
  showDialogadd() {
    this.addProjetModal = true;
  }

  enabledialog(){
    this.addProjetModal = false;

    this.editProjetModal = false;

  }

  delete_(value:any) {
    this.submitted = true;

    this.projetService.deleteProject(value).subscribe(data => {
      this.closeDialofg()
      this.submitted = false;
      this.ngOnInit();


    },
    (error)=>{
      this.submitted = false;

    })
  }

  restoreProjet(value:any){
    this.submitted = true;

    this.projetService.restoreState(value.id,true).subscribe(data => {
      this.ngOnInit();

      this.closeDialofg()
      this.submitted = false;

    },
    (error)=>{
      this.submitted = false;

    })
  }
  open(content: any) {
		this.modalService.open(content);
	}
  closeDialofg(){
    this.modalService.dismissAll()
  }
  showDialogedit(content:any,val:any) {
    this.edit = true;
    this.temporaire = {...val};
		this.modalService.open(content);


  }
  getStatus(val:boolean){
    if (val == true){
      return "btn btn-success";
    }
    else{return "btn btn-danger";}
  }
  getState(val:boolean){
    if (val == true){
      return "Enabled";
    }
    else{return "Disabled";}
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@/models/user';
import { UserService } from '@/services/user.service';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { AppDataState, DataStateEnum } from '@/State/load.state';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit{
  activeUsers!: User[];
  desactiveUsers!: User[];
  dataLoad: boolean=true;
  datas$: Observable<AppDataState<any[]>> |null = null;
  data$: Observable<AppDataState<any[]>> |null = null;
  dtOptions: DataTables.Settings = {};
  readonly DataStateEnum=DataStateEnum;
  constructor(private users: UserService,private router: Router) { }

  ngOnInit(){
    this.dtOptions = {
      pagingType: 'full_numbers'
    };
    this.getActiveUser();
    this.getDisableuser();
   }

   getActiveUser(){
    // this.users.getAll(true).pipe(
    //   startWith(this.users.activeUsers),
    //   map(users => users.map(user => new User(user))),
    //   catchError(error => Observable.of(new DataStateEnum(error)))
    // ).subscribe(
    this.datas$ = this.users.getAll(true).pipe(
      map(data =>{
        this.activeUsers = data;
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );
    // this.users.getAll(true)
    //  .subscribe(data => {
    //   this.activeUsers = data;
    //   this.dataLoad = false;

    //  },
    //  (error)=>{
    //   console.log(error.message);
    //   this.dataLoad = false;

    //  })
    }

    getDisableuser(){
      this.data$ = this.users.getAll(false).pipe(
        map(data =>{
          this.desactiveUsers = data;
          return ({dataState:DataStateEnum.LOADED})
        }),
        startWith({dataState:DataStateEnum.LOADING}),
        catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
      );
  //  this.users.getAll(false)
  //     .subscribe(data => {
  //      this.desactiveUsers = data;
  //      this.dataLoad = false;

  //     },
  //     (error)=>{
  //      console.log(error.message);
  //      this.dataLoad = false;

  //     })

    }

  applyFilterGlobal($event:any) {
    return ($event.target as HTMLInputElement).value
  }
  onPress(){
    this.router.navigate(['/dashboard/super/user-details']);
  }
}

<div class="container-fluid">
    <main>
        <div class="pagetitle">
            <h1>Organization</h1>
            <nav style="display: flex;justify-content: space-between;">

                <div>
                    <ol class="breadcrumb">

                        <li class="breadcrumb-item"><a routerLink="/dashbord">Home</a></li>
                        <li class="breadcrumb-item">Organization</li>
                        <li class="breadcrumb-item active">Details</li>




                    </ol>

                </div>

                <div>
                    <button class="btn btn-success" (click)="back()"> back</button>
                </div>

            </nav>
            <div>
                <div class="container-fluid">
                    <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
                        <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                            <app-spinner></app-spinner>
                        </ng-container>
                        <ng-container *ngSwitchCase="DataStateEnum.ERROR">


                        </ng-container>
                        <ng-container *ngSwitchCase="DataStateEnum.LOADED">
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-12">
                                    <section class="section profile">
                                        <div class="row">
                                            <div class="col-xl-8">
                                                <div class="card" style="padding: 20px 20px 20px 20px;" style="background-color: white;">
                                                    <div class="card-header">
                                                        <h2 class="size2" style="color:black">Organisation Details</h2>

                                                    </div>
                                                    <div class="card-body pt-4 d-flex flex-column align-items-center">

                                                        <div class="row mybox" style="width: 100%;">
                                                            <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                <div class="size11"><b> Name: </b></div>
                                                                <div class="size12">{{detailsOrganisation.name}}</div>
                                                            </div>
                                                        </div>

                                                        <div class="row mybox" style="width: 100%;">
                                                            <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                <div class="size11"><b> Adresse: </b></div>
                                                                <div class="size12">{{detailsOrganisation.adresse}}</div>
                                                            </div>
                                                        </div>

                                                        <div class="row mybox" style="width: 100%;">
                                                            <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                <div class="size11"><b> City: </b></div>
                                                                <div class="size12">{{detailsOrganisation.city}}</div>
                                                            </div>
                                                        </div>

                                                        <div class="row mybox" style="width: 100%;">
                                                            <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                <div class="size11"><b> Email: </b></div>
                                                                <div class="size12">{{detailsOrganisation.email}}</div>
                                                            </div>
                                                        </div>

                                                        <div class="row mybox" style="width: 100%;">
                                                            <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                <div class="size11"><b> Phone: </b></div>
                                                                <div class="size12">{{detailsOrganisation.phone}}</div>
                                                            </div>
                                                        </div>

                                                        <div class="row mybox" style="width: 100%;">
                                                            <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                <div class="size11"><b> Web Site: </b></div>
                                                                <div class="size12">{{detailsOrganisation.website}}</div>
                                                            </div>
                                                        </div>

                                                        <div class="row mybox" style="width: 100%;">
                                                            <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                <div class="size11"><b> Document: </b></div>
                                                                <div class="size12">{{detailsOrganisation.document}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="row mybox" style="width: 100%;">
                                                            <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                <div class="size11"><b> Status: </b></div>
                                                                <div class="size12">{{detailsOrganisation.isEnabled}}</div>
                                                            </div>
                                                        </div>






                                                    </div>

                                                </div>

                                            </div>
                                            <div class="col-xl-4 .mybox">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h2 class="size2">Owner Infos</h2>
                                                    </div>
                                                    <div class="card-body profile-card pt-4 d-flex flex-column align-items-center pad" style="background-color: white;">

                                                        <div class="row mybox" style="width: 100%;">
                                                            <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                <div class="size11"><b> Name: </b></div>
                                                                <div class="size12">{{detailsOrganisation.owner.name}}</div>
                                                            </div>
                                                        </div>

                                                        <div class="row mybox" style="width: 100%;">
                                                            <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                <div class="size11"><b> Email: </b></div>
                                                                <div class="size12">{{detailsOrganisation.owner.email}}</div>
                                                            </div>
                                                        </div>

                                                        <div class="row mybox" style="width: 100%;">
                                                            <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                                                <div class="size11"><b> Phone: </b></div>
                                                                <div class="size12">{{detailsOrganisation.owner.phone}}</div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>

            </div>
        </div>


    </main>

</div>
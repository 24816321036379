import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-checkemail-password',
  templateUrl: './checkemail-password.component.html',
  styleUrls: ['./checkemail-password.component.css']
})
export class CheckemailPasswordComponent {
  constructor(private route:Router){}


  redirect(){
    this.route.navigateByUrl('/resetpass')
  }
}

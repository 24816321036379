import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from './base.service';
import { Project } from '../models/project';


@Injectable({
  providedIn: 'root'
})
export class ProjectsService extends BaseService<Project> {

  constructor(private httpCient: HttpClient) {
    super(
      httpCient,
      "projects"
    );
  }
}

<header class="header" style="background-color: #3d9ce6;" [class.headerchange]="headerCh">
    <div class="container-fluid">
        <div class="col-12">
            <div class="row">
                <div class="col-md-4 col-sm-5 col-4 d-flex justify-content-center align-items-center" style="padding-top: 0 !important;">
                    <div class="logo" routerLink="">
                        <a routerLink=""><img src="../assets/images/logo.png" style="max-height: 50px !important;" alt=""></a>
                    </div>
                </div>
                <div class="col-md-8 col-sm-7 col-8   justify-content-center align-items-center"  style="padding-right: 0px !important;">
                    <span class="nav-item col-12 justify-content-end align-items-end d-flex" [class.menu_iconclass]="menu_icon_variable">
                        <i class="bi bi-list menu_icon"  (click)="showmenu()" id="hide" style="color:white;height: 58px;font-size: 30px;margin-top: -20px"></i>
                    </span>
                    <div class="menu" [class.menuClass]="menuVar">
                        <ul>
                            <li class="nav-item active" style="margin-left: 20%;">
                                <a class="nav-link active" routerLink="/">Home</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" routerLink="documentation">Documentation</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/" fragment="about">About us</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/" fragment="contact">Contact</a>
                            </li>
                            <li class="nav-item">
                                <button routerLink="/login" class="btn font-weight-medium btn-up  font-weight-bold" type="submit" style="background-color: white;color: rgb(32, 152, 221);">Login</button>
                            </li>
                            <li class="nav-item ">
                                <button routerLink="/register" class="btn btn-primary text-white font-weight-bold btn-up" type="submit">Register</button>
                            </li>
                        </ul>
                       

                    </div>

                </div>
            </div>
            <div *ngIf="menuVar" class="row" style="z-index: 1000;">
                <div class="col-12">
                    <div id="sidebar" class="sidebar">
                        <div style="color: #f6f9ff !important; border-radius: 20px;">
                            <ul class="sidebar-nav" id="sidebar-nav">
                                <li class="nav-item active nav-text"><a (click)="showmenu()" routerLink="/" class="nav-link" href="#accueil"><span>Accueil</span></a></li>
                                <li class="nav-item nav-text"><a (click)="showmenu()" class="nav-link" href="#documentation"><span>Documentation</span></a>
                                </li>
                                <!-- <li class="nav-item nav-text"><a (click)="showmenu()" class="nav-link" href="#securite"><span>Securite</span></a></li> -->
                                <li class="nav-item nav-text"><a (click)="showmenu()" class="nav-link" routerLink="/" fragment="apropos"><span>A propos de
                        nous</span></a></li>
                                <li class="nav-item nav-text"><a (click)="showmenu()" class="nav-link" routerLink="/" fragment="contact"><span>Nous
                        Contacter</span></a></li>
                                <li class="nav-item nav-text"><a (click)="showmenu()" class="nav-link" routerLink="/login"><span>
                          Login</span></a></li>
                                <li class="nav-item nav-text"><a (click)="showmenu()" class="nav-link" routerLink="/register"><span>
                            Register</span></a></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- <div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Dashboard</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a>Home</a></li>
                    <li class="breadcrumb-item active">Dashboard v1</li>
                </ol>
            </div>
        </div>
    </div>
</div> -->
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <!-- Small boxes (Stat box) -->
        <div class="content">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">Dashboard</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item">
                                    <a routerLink="/dashbord">Home</a>
                                </li>
                                <li class="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
                <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                    <app-spinner></app-spinner>
                </ng-container>
                <ng-container *ngSwitchCase="DataStateEnum.ERROR">


                </ng-container>
                <ng-container *ngSwitchCase="DataStateEnum.LOADED">
                    <div class="content">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-6">

                                    <div class="card">
                                        <div class="card-header border-0">
                                            <h3 class="card-title">Generals informations</h3>
                                            <div class="card-tools">
                                                <a class="btn btn-sm btn-tool">
                                                    <i class="fas fa-download"></i>
                                                </a>
                                                <a class="btn btn-sm btn-tool">
                                                    <i class="fas fa-bars"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="d-flex justify-content-between align-items-center border-bottom mb-3">
                                                <p class="text-success text-xl">
                                                    <i class="ion ion-ios-refresh-empty"></i>
                                                </p>
                                                <p class="d-flex flex-column text-right">
                                                    <span class="font-weight-bold">
                                                <i class="ion ion-android-arrow-up text-success"></i>
                                               {{total}}
                                            </span>
                                                    <span class="text-muted">TOTAL BALANCE ESTIMATED</span>
                                                </p>
                                            </div>

                                            <div class="d-flex justify-content-between align-items-center border-bottom mb-3">
                                                <p class="text-warning text-xl">
                                                    <i class="ion ion-ios-cart-outline"></i>
                                                </p>
                                                <p class="d-flex flex-column text-right">
                                                    <span class="font-weight-bold">
                                                <i class="ion ion-android-arrow-up text-warning"></i>
                                                {{balance}}
                                            </span>
                                                    <span class="text-muted">CURRENT BALANCE ESTIMATED</span>
                                                </p>
                                            </div>

                                            <div class="d-flex justify-content-between align-items-center mb-0">
                                                <p class="text-danger text-xl">
                                                    <i class="ion ion-ios-people-outline"></i>
                                                </p>
                                                <p class="d-flex flex-column text-right">
                                                    <span class="font-weight-bold">
                                                <i class="ion ion-android-arrow-down text-danger"></i>
                                                1%
                                            </span>
                                                    <span class="text-muted">YOUR WITHDRAWALS PENDING TREATMENT</span>
                                                </p>
                                            </div>

                                            <div class="d-flex justify-content-between align-items-center mb-0">
                                                <p class="text-danger text-xl">
                                                    <i class="ion ion-ios-people-outline"></i>
                                                </p>
                                                <p class="d-flex flex-column text-right">
                                                    <span class="font-weight-bold">
                                                <i class="ion ion-android-arrow-down text-danger"></i>
                                                1%
                                            </span>
                                                    <span class="text-muted">NUMBER OF PAYMENTS</span>
                                                </p>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header border-0">
                                            <div class="d-flex justify-content-between">
                                                <h3 class="card-title">BALANCE PER MONTH</h3>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="d-flex">
                                                <p class="d-flex flex-column">
                                                    <span class="text-bold text-lg">$18,230.00</span>
                                                    <span>Sales Over Time</span>
                                                </p>
                                                <p class="ml-auto d-flex flex-column text-right">
                                                    <span class="text-success">
                                                <i class="fas fa-arrow-up"></i>
                                                33.1%
                                            </span>
                                                    <span class="text-muted">Since last month</span>
                                                </p>
                                            </div>

                                            <div class="position-relative mb-4">
                                                <canvas id="sales-chart" height="200"></canvas>
                                            </div>
                                            <div class="d-flex flex-row justify-content-end">
                                                <span class="mr-2">
                                            <i class="fas fa-square text-primary"></i>
                                            This year
                                        </span>
                                                <span>
                                            <i class="fas fa-square text-gray"></i>
                                            Last year
                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">

                                    <div class="card">
                                        <div class="card-header border-0">
                                            <h3 class="card-title">RECENT ACTIVITIES</h3>
                                            <div class="card-tools">
                                                <a class="btn btn-tool btn-sm">
                                                    <i class="fas fa-download"></i>
                                                </a>
                                                <a class="btn btn-tool btn-sm">
                                                    <i class="fas fa-bars"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="card-body table-responsive p-0">
                                            <table class="table table-striped table-valign-middle">
                                                <thead>
                                                    <tr>
                                                        <th>Payment method</th>
                                                        <th>Amount</th>
                                                        <th>User informations</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style="display: flex;align-items: center;">
                                                            <img src="../../../assets/backend/images/orange-money.png" styleClass="mr-2" width="40px" height="40px" class="rounded-circle" shape="circle">


                                                        </td>
                                                        <td>
                                                            <p style="font-size: 14px;font-weight: bold;">100 XAF</p>
                                                        </td>
                                                        <td>
                                                            691178625
                                                        </td>
                                                        <td>
                                                            11:00am, March 1,2023
                                                        </td>
                                                    </tr>
                                                    <tr style="justify-content: center;align-items: center;">
                                                        <td style="display: flex;align-items: center;">
                                                            <img src="../../../assets/backend/images/orange-money.png" styleClass="mr-2" width="40px" height="40px" class="rounded-circle" shape="circle">


                                                        </td>
                                                        <td>
                                                            <p style="font-size: 14px;font-weight: bold;">100 XAF</p>
                                                        </td>
                                                        <td>
                                                            691178625
                                                        </td>
                                                        <td>
                                                            11:00am, March 1,2023
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="display: flex;align-items: center;">
                                                            <img src="../../../assets/backend/images/orange-money.png" styleClass="mr-2" width="40px" height="40px" class="rounded-circle" shape="circle">


                                                        </td>
                                                        <td>
                                                            <p style="font-size: 14px;font-weight: bold;">100 XAF</p>
                                                        </td>
                                                        <td>
                                                            691178625
                                                        </td>
                                                        <td>
                                                            11:00am, March 1,2023
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="display: flex;align-items: center;">
                                                            <img src="../../../assets/backend/images/orange-money.png" styleClass="mr-2" width="40px" height="40px" class="rounded-circle" shape="circle">


                                                        </td>
                                                        <td>
                                                            <p style="font-size: 14px;font-weight: bold;">100 XAF</p>
                                                        </td>
                                                        <td>
                                                            691178625
                                                        </td>
                                                        <td>
                                                            11:00am, March 1,2023
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header border-0">
                                            <div class="d-flex justify-content-between">
                                                <h3 class="card-title">BALANCE PER DAY</h3>
                                                <a href="javascript:void(0);">View Report</a>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="d-flex">
                                                <p class="d-flex flex-column">
                                                    <span class="text-bold text-lg">820</span>
                                                    <span>Visitors Over Time</span>
                                                </p>
                                                <p class="ml-auto d-flex flex-column text-right">
                                                    <span class="text-success">
                                                <i class="fas fa-arrow-up"></i>
                                                12.5%
                                            </span>
                                                    <span class="text-muted">Since last week</span>
                                                </p>
                                            </div>

                                            <div class="position-relative mb-4">
                                                <canvas id="visitors-chart" height="200"></canvas>
                                            </div>
                                            <div class="d-flex flex-row justify-content-end">
                                                <span class="mr-2">
                                            <i class="fas fa-square text-primary"></i>
                                            This Week
                                        </span>
                                                <span>
                                            <i class="fas fa-square text-gray"></i>
                                            Last Week
                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </ng-container>
            </ng-container>
        </div>
        <!-- /.row -->
    </div>
    <!-- Main row -->
</section>
<!-- /.content -->
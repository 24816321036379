import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import { AuthentificationService } from '@services/authentification.service';
import {Observable} from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    userinformation = JSON.parse(localStorage.getItem("user")!);
    name = this.userinformation.sub;
    user ={'email': this.name,'picture':'../../../../../assets/img/default-profile.png'};
    dashbord =[
        {
            name: 'Dashboard',
            iconClasses: 'fas fa-tachometer-alt',
            path: ['/dashbord']
        }
    ]
    supers=[
        {
            name: ' Administration',
            iconClasses: 'fas fa-users',
            children: [
                {
                    name: 'Beneficiary',
                    iconClasses: 'fas fa-globe',
                    path: ['/dashbord/beneficiary']
                },
                {
                    name: 'Currency',
                    iconClasses: 'fas fa-usd',
                    path: ['/dashbord/currency']
                },
                {
                    name: 'Finance',
                    iconClasses: 'bi bi-bank',
                    path: ['/dashbord/withdraw-history-admin']

                   
                },
              
                {
                    name: 'Operators',
                    iconClasses: 'fas fa-phone',
                    path: ['/dashbord/operator']
                },
                {
                    name: 'Organization',
                    iconClasses: 'fas fa-calendar',
                    path: ['/dashbord/organization']
                },
                {
                    name: 'Parameters',
                    iconClasses: 'fas fa-cog',
                    path: ['/dashbord/settings']
                },
                {
                    name: 'Project',
                    iconClasses: 'far fa-folder',
                    path: ['/dashbord/projects']
                },
              
                {
                    name: 'Users',
                    iconClasses: 'fas fa-users',
                    path: ['/dashbord/users']
                }
            ]
        },
    ]
    menu = [
        {
            name: 'Finance',
            iconClasses: 'bi bi-bank',
            children: [{
                name: 'Earn and Withdraw',
                iconClasses: 'bi bi-coin',        
                path: ['/dashbord/earn-withdraw']},
                {
                    name: 'Payement History',
                    iconClasses: 'fas fa-user',        
                    path: ['/dashbord/payement-history']},{
                        name: 'Withdrawal History',
                        iconClasses: 'bi bi-currency-exchange',        
                        path: ['/dashbord/withdraw-history']}
            ]
           
        },
        {
            name: 'Organization',
            iconClasses: 'fas fa-calendar',
            path: ['/dashbord/user-organization']
        },
        {
            name: 'Projects',
            iconClasses: 'fas fa-folder',
            children: [
                {
                    name: 'Project',
                    iconClasses: 'far fa-circle-thin',
                    path: ['/dashbord/userows-project']
                },
                {
                    name: 'Add Project',
                    iconClasses: 'fas fa-circle-thin',
                    path: ['/dashbord/addProjet']
                }
            ]
        },
        {
            name: 'User',
            iconClasses: 'fas fa-user',
            children: [{
                name: 'Profile',
                iconClasses: 'fas fa-user',        
                path: ['/dashbord/profile']}
            ]
           
        },
        
        {
            name: 'Demo',
            iconClasses: 'fas fa-share-alt ',
            path: ['/dashbord/demo-payement']
        },
        
    ];

    constructor(
        public appService: AppService,
        private store: Store<AppState>,
        private auth: AuthentificationService
    ) {}
    rol:string;
    super:string = '"ROLE_SUPER"';
    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        // this.user = this.appService.user;
        this.role()

    }
    role(){
        this.rol=this.auth.role;
      }
}

export const MENU = [
    {
        name: 'Dashboard',
        iconClasses: 'fas fa-tachometer-alt',
        path: ['/']
    },
    {
        name: 'Blank',
        iconClasses: 'fas fa-file',
        path: ['/blank']
    },
    {
        name: 'Main Menu',
        iconClasses: 'fas fa-folder',        
        children: [
            {
                name: 'Sub Menu',
                iconClasses: 'far fa-address-book',
                path: ['/sub-menu-1']
            },
            {
                name: 'Blank',
                iconClasses: 'fas fa-file',
                path: ['/sub-menu-2']
            }
        ]
    }
];

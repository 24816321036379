import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common'
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap/dropdown/dropdown.module';

@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.scss']
})
export class HeadersComponent {
  constructor(@Inject(DOCUMENT) private document: Document,private activatedRoute: ActivatedRoute){

  }
  menuVar: boolean =false;
  menu_icon_variable: boolean = false;
  headerCh: boolean =true;
  
  
  showmenu(){
    this.menuVar =! this.menuVar;
  
  }
  menu(){
    this.menuVar= false;
  }
  @HostListener('document:scroll')
  scrollfunction(){
    if(document.body.scrollTop > 0 || document.documentElement.scrollTop > 0){
      this.headerCh = true;
    }else{
      this.headerCh = true;
    }
  }
  sidebarToggle()
  {
    //toggle sidebar function
    this.document.body.classList.toggle('toggle-sidebar');
  }
    ngOnInit(): void {
    this.activatedRoute.fragment.subscribe((value)=>{
      this.jumpTo(value);
    })
    }
    jumpTo(section:any){
      document.getElementById(section)?.scrollIntoView({behavior: 'smooth'})
    }
}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { UserService } from '@/services/user.service';
import { Location } from '@angular/common'
@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent {
  constructor(private location: Location,private router: Router,private route:ActivatedRoute,private info:UserService){}
  userinfo: any;
  readonly DataStateEnum=DataStateEnum;
  datas$: Observable<AppDataState<any[]>> |null = null;
  id!: any;

   ngOnInit(){
    this.id= this.route.snapshot.paramMap.get('id');
    this.getUsersInformation()
  }
  back(){
    this.location.back();
  }
  getUsersInformation(){
    this.datas$ = this.info.getById(this.id).pipe(
      map(data =>{
        console.log(data)
        this.userinfo = data;
        console.log(this.userinfo)
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-userfinance',
  templateUrl: './userfinance.component.html',
  styleUrls: ['./userfinance.component.scss']
})
export class UserfinanceComponent {

}

<app-headers></app-headers>
<main class="containe">

  <div class="container-fluid" id="cont" style="height: 100%;">
    <div *ngIf="status == false" style="width: 100%;height: 100%;">
      <img src="../../../assets/svg/hero-bg-shape.svg" style="position: absolute;bottom: 0px;right: 0px;"  alt="" srcset="">
      <div style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;">
       <div class="cont">
        <div class="left" style="align-items: center;justify-content: center;display: flex;">
          <div class="pt-4 pb-2">

         </div>
        </div>
        <div class="rigth" style="align-items: center;justify-content: center;display: flex;">
          <div *ngIf='verifie==true' class="card col-8 bg-white shadow-md p-5">
            <div class="mb-4 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="text-success" width="75" height="75"
                    fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
            </div>
            <div class="text-center">
                <h1>Thank's !</h1>
                <p>Your account has been successfully validated </p>
                <button class="btn btn-outline-success" routerLink="/login">Login</button>
            </div>
        </div>
        <div *ngIf='verifie==false' class="card col bg-white shadow-md p-5" style="min-width: 318px;max-width: 640px;">
          <div class="mb-4 text-center">
              <img src="../../../../assets/images/error.png" width="75px" height="75px" alt="" srcset="">
          </div>
          <div class="text-center">
              <h1>We encounter an error !</h1>
              <p>{{err}}</p>
          </div>
      </div>
        </div>
      </div>
    </div>
    </div>



    </div>
    <div *ngIf="status == true" style="width: 100%;height: 100%;">
      <app-spinner></app-spinner>


    </div>
</main>


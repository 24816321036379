<main class="containe">
    <div class="container-fluid" id="cont" style="height: 100%;">
        <div style="width: 100%;height: 100%;">
            <img src="../../../assets/svg/hero-bg-shape.svg" style="position: absolute;bottom: 0px;right: 0px;" alt="" srcset="">
            <div style="width: 100%;height: 100%;display: flex; justify-content: center;align-items: center;">
                <div class="cont">
                    <div class="left" style="align-items: center;justify-content: center;display: flex;">
                        <div class="pt-4 pb-2">
                            <h1 class="card-title text-Start pb-0 fs-1 size2" style="color: white;">WELCOME BACK !</h1>
                            <p class="text-Start small size1" style="color: white;font-size: 1rem;">We are delighted to meet you</p>
                        </div>
                        <br>


                    </div>
                    <div class="rigth" style="align-items: center;justify-content: center;display: flex;">

                        <div class="card col-md-7 col-lg-7" style="box-shadow: 0 0 2em rgb(0, 0, 0);background-color: white;padding-left: 10px;
          ">
                            <div class="card-body ">
                                <div class="pt-2 pb-2">
                                    <h3 class="card-title text-Start fs-3 size1" style="color: black;">Login</h3> <br>
                                    <p class="text-Start small"> <i>Enter your username and password!</i></p>
                                </div>
                                <form class="row needs-validation" [formGroup]="userInfo">
                                    <div class="col-12 field">

                                        <div class="input-field" [class.error]="username.invalid && username.hasError('required','type')">
                                            <label class="form-label" for="form3Example3">Email Adress<span class="text-danger me-1">*</span></label>
                                            <div class="input-group">
                                                <span class=" input-group-prepend ">
                                              <div class="input-group-text bg-transparent bord"><i class="fa fa-user "></i></div>
                                                </span>
                                                <input type="email" formControlName="email" placeholder="moobilpay@gmail.com " required id="form3Example3" class="form-control" />
                                            </div>
                                            <div *ngIf="username.invalid && (username.touched || username.dirty) ">
                                                <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="username.errors?.required ">*The Email Adress is required</span>
                                                <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="username.errors?.email ">*The Email is not valid</span>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 field ">
                                        <div>
                                            <div class="input-field " [class.error]="password.invalid && password.hasError( 'required', 'type') ">
                                                <label for="pass " class="form-label ">Password<span class="text-danger me-1 ">*</span></label>
                                                <div class="input-group">
                                                    <span class=" input-group-prepend ">
                                                    <div class="input-group-text bg-transparent bord"><i class="fa fa-lock "></i></div>
                                                   </span>
                                                    <input type="password" formControlName="password" class="form-control " [ngClass]="{ 'ng-dirty': (password.invalid && submitted) || (password.dirty && password.invalid)} " id="pass ">
                                                </div>
                                                <!--<small class="p-error " *ngIf="(country.invalid && submitted )|| (country.dirty && country.invalid) ">Le pays est obligatoire.</small>-->
                                                <div *ngIf="password.invalid && (password.touched || password.dirty) ">
                                                    <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="password.errors?.required ">*The Password is Required</span>
                                                    <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="password.errors?.minlength ">*Password must be at least 8 characters</span>

                                                </div>
                                                <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="subscribError ">*{{subscribError?.message}}.</span>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 field"> <button class="btn btn-primary w-100 " (click)="login() " [disabled]="submitted">Login <div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div>
                    </button>
                                    </div>
                                    <div class="col-6 ">
                                        <a routerLink="/register">Create an account?</a>
                                    </div>
                                    <div class="col-6 align-end ">
                                        <a routerLink="/resetpass">Forgot Password?</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</main>
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

import { User } from '../models/user';
import { BaseService } from './base.service';
import { environment } from 'environments/environment';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService<User> {

  constructor(private httpCient: HttpClient) {
    super(
      httpCient,
      "users"
    );
  }

  addUser(user: User): Observable<User> {
    const url = `${environment.apiUrl}/users/changePassword`;
    return this.httpCient.post<User>(url, user, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleErrors)
      )
  }


  getUserById(id: number): Observable<User> {
    const url = `${environment.apiUrl}/users/${id}`;
    return this.httpCient.get<User>(url, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleErrors)
      )
  }

  getUserByRole(): Observable<User[]> {
    return this.httpCient.get<User[]>(`${environment.apiUrl}/users/roles`, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleErrors)
      )
  }

  getOwnerById(id: number): Observable<User> {
    const url = `${environment.apiUrl}/users/owner/${id}`;
    return this.httpCient.get<User>(url, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleErrors)
      )
  }

  getUsersTinyOwner(): Observable<User[]> {
    return this.httpCient.get<User[]>(`${environment.apiUrl}/users/allTinyOwner`, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleErrors)
      )
  }

  getOwners(): Observable<User[]> {
    return this.httpCient.get<User[]>(`${environment.apiUrl}/api/users/allOwner`, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleErrors)
      )
  }

  getAllChildren(): Observable<User[]> {
    return this.httpCient.get<User[]>(`${environment.apiUrl}/api/users/allChildren`, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleErrors)
      )
  }


  getChildrenByOwner(id: number): Observable<User> {
    const url = `${environment.apiUrl}/users/allChildrenByOwner/${id}`;
    return this.httpCient.get<User>(url, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleErrors)
      )
  }

  getUsers(state: boolean): Observable<User[]> {
    return this.httpCient.get<User[]>(`${environment.apiUrl}/api/users/all/${state}`, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleErrors)
      )
  }
  updateUsers(item: any): Observable<User[]> {
    return this.httpCient.put<User[]>(`${environment.apiUrl}/users/update`, JSON.stringify(item), httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleErrors)
      )
  }


  private handleErrors(error: HttpErrorResponse) {
    let errorMessage;
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      if (error.status == 200) {
        errorMessage = { "Code d'erreur": error.status, "message": "OK" };

      } else {
        errorMessage = { "Code d'erreur": error.status, "message": error.error.message };

      }
    }
    return throwError(errorMessage);
  }
}

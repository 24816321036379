<div class="container-fluid">
    <main>
        <div class="pagetitle">
            <h1>Setting</h1>
            <nav>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/dashbord">Home</a></li>
                    <li class="breadcrumb-item active">Setting</li>
                </ol>
            </nav>
            <section class="section profile" style="justify-content: center;align-items: center;display: flex;">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center align-items-center">


                        <div class="col-xl-10">
                            <div class="card" style="padding: 20px 20px 20px 20px;">
                                <div class="card-body profile-card pt-4 d-flex flex-column align-items-center pad">

                                    <img src="assets/backend/images/avatar.png" width="50px" height="50px" alt="Profile" class="rounded-circle" style="padding-bottom: 10px;">
                                    <div class="row" style="margin-top: 10px;margin-bottom: 10px;">
                                        <button class="btn btn-warning" style="padding: 5px;width: 70px;color: white;margin-right: 10px;" (click)="showDialogadd(add,params)">Update</button>
                                    </div>
                                    <div class="row mybox" style="width: 100%;">
                                        <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                            <div class="size11"><b> Name: </b></div>
                                            <div class="size12">{{params?.name}}</div>
                                        </div>
                                    </div>

                                    <div class="row mybox" style="width: 100%;">
                                        <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                            <div class="size11"><b> Email: </b></div>
                                            <div class="size12">{{params?.email}}</div>
                                        </div>
                                    </div>

                                    <div class="row mybox" style="width: 100%;">
                                        <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                            <div class="size11"><b> Telephone: </b></div>
                                            <div class="size12">{{params?.telephone}}</div>
                                        </div>
                                    </div>

                                    <div class="row mybox" style="width: 100%;">
                                        <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                            <div class="size11"><b> Site Web: </b></div>
                                            <div class="size12">{{params?.siteweb}}</div>
                                        </div>
                                    </div>

                                    <div class="row mybox" style="width: 100%;">
                                        <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                            <div class="size11 col-4"><b> Adresse: </b></div>
                                            <div class="size12 col-8" style="text-align: end;"> {{params?.adresse}} </div>
                                        </div>
                                    </div>
                                    <div class="row mybox" style="width: 100%;">
                                        <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                            <div class="size11 col-4"><b> Frais: </b></div>
                                            <div class="size12 col-8" style="text-align: end;"> {{params?.frais}} </div>
                                        </div>
                                    </div>

                                    <div class="row mybox" style="width: 100%;">
                                        <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                            <div class="size11 "><b> Registre de commerce: </b></div>
                                            <div class="size12 ">{{params?.rc}}</div>
                                        </div>
                                    </div>

                                    <div class="row mybox" style="width: 100%;">
                                        <div class="col-xl-12" style="display: flex;justify-content: space-between;">
                                            <div class="size11"><b> Numero d'identifiant: </b></div>
                                            <div class="size12">{{params?.nui}}</div>
                                        </div>
                                    </div>





                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>


    </main>

</div>

<ng-template #add let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Update Settings</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="bi bi-x-lg"></i></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="updateForm" style="width: 100%;">
            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="errormessage">*{{errormessage?.message}}.</span>
            <div class="form-row">
                <input type="text" formControlName="id" class="form-control" id="id" name="id" [ngModel]="temporaire.id" style="display: none;">

                <div class="form-group col-md-6">
                    <div class="input-field" [class.error]="name.invalid && name.hasError('required','type')">
                        <label for="name">Name <span class="text-danger me-1">*</span></label>
                        <input type="text" formControlName="name" class="form-control" id="name" name="name" [ngModel]="temporaire.name">
                        <div *ngIf="name.invalid && (name.touched || name.dirty)">
                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="name.errors?.required">*The name required</span>
                        </div>
                    </div>

                </div>

                <div class="form-group col-md-6">
                    <div class="input-field" [class.error]="email.invalid && email.hasError('required','type')">
                        <label for="inputEmail">Email  <span class="text-danger me-1">*</span></label>
                        <input type="email" formControlName="email" class="form-control" id="email" name="email" [ngModel]="temporaire.email">
                        <div *ngIf="email.invalid && (email.touched || email.dirty)">
                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="name.errors?.required">*The email required</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <div class="input-field" [class.error]="telephone.valid && telephone.hasError('required','type')">
                        <label for="telephone">Phone Number <span class="text-danger me-1">*</span></label>
                        <input type="num" formControlName="telephone" class="form-control" id="telephone" name="telephone" [ngModel]="temporaire.telephone">
                        <div *ngIf="email.invalid && (telephone.touched || telephone.dirty)">
                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="telephone.errors?.required">*The phone number is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <div class="input-field" [class.error]="frais.valid && frais.hasError('required','type')">
                        <label for="telephone">Fees <span class="text-danger me-1">*</span></label>
                        <input type="num" formControlName="frais" class="form-control" id="telephone" name="telephone" [ngModel]="temporaire.frais">
                        <div *ngIf="frais.invalid && (frais.touched || frais.dirty)">
                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="frais.errors?.required">*The fee amount is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <div class="input-field" [class.error]="siteweb.valid && siteweb.hasError('required','type')">
                        <label for="site-web">Web Site <span class="text-danger me-1">*</span></label>
                        <input type="url" formControlName="siteweb" class="form-control" id="site-web" [ngModel]="temporaire.siteweb">
                        <div *ngIf="siteweb.invalid && (siteweb.touched || siteweb.dirty)">
                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="siteweb.errors?.required">*The url is required</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field">

                            <label for="inputAddress">NUI</label>
                            <input type="text" formControlName="nui" placeholder="Unique Identification Number" class="form-control" id="nui" [ngModel]="temporaire.nui">
                        </div>

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-field">
                            <label for="inputAddress">Register of commerce </label>
                            <input type="text" formControlName="rc" class="form-control" id="rc" [ngModel]="temporaire.rc">
                            <!-- <div *ngIf="rc.invalid && (rc.touched || rc.dirty)">
                                <span class="error fs-6 text-danger mx-2 my-2" *ngIf="name.errors?.required">*The  is required</span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>



            <div class="form-group">
                <div class="input-field" [class.error]="adresse.valid && adresse.hasError('required','type')">
                    <label for="inputAddress">Address <span class="text-danger me-1">*</span></label>
                    <textarea formControlName="adresse" class="form-control" id="inputAddress" [ngModel]="temporaire.adresse"></textarea>
                    <div *ngIf="adresse.invalid && (adresse.touched || adresse.dirty)">
                        <span class="error fs-6 text-danger mx-2 my-2" *ngIf="adresse.errors?.required">*The adresse is required</span>
                    </div>
                    <!-- <div *ngIf="siteweb.invalid && (siteweb.touched || siteweb.dirty)">
                        <span class="error fs-6 text-danger mx-2 my-2" *ngIf="name.errors?.required">*The url is required</span>
                    </div> -->
                </div>
            </div>


        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-primary" [disabled]="submitted" (click)="updateparam()">Save<div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>

    </div>
</ng-template>
<app-spinner *ngIf="submitted"></app-spinner>
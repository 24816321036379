import { Component } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { OrganizationService } from '@/services/organization.service';
import { UserService } from '@services/user.service';
@Component({
  selector: 'app-detail-organization',
  templateUrl: './detail-organization.component.html',
  styleUrls: ['./detail-organization.component.scss']
})
export class DetailOrganizationComponent {
  readonly DataStateEnum=DataStateEnum;
  datas$: Observable<AppDataState<any[]>> |null = null;
  id:any;
  detailsOrganisation:any;
  userinfo: any;
  userinformation = JSON.parse(localStorage.getItem("user")!);
  constructor(private location: Location,private router: Router,private route:ActivatedRoute,private detailsOgranisationService:OrganizationService,private info:UserService){}

  ngOnInit(){
    console.log(this.userinformation)
    this.id= this.route.snapshot.paramMap.get('id');
    this.getOrganisation()
  }


back(){
  this.location.back();
}

  getOrganisation(){

    this.datas$ = this.detailsOgranisationService.getById(this.id).pipe(
      map(data =>{
        console.log(data)
        this.detailsOrganisation = data;
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );
  }
   roleRedirect(){
    if(this.userinformation.role === "ROLE_SUPER"){
      this.router.navigateByUrl("/dashbord/organization");
    }
    else{
      this.router.navigateByUrl("/dashbord/user-organization");
    }
   }
  

}

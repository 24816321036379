import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {MainMenuComponent} from '@pages/main-menu/main-menu.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import { CheckemailPasswordComponent } from './site/auth/checkemail-password/checkemail-password.component';
import { CheckmailValidateAccountComponent } from './site/auth/checkmail-validate-account/checkmail-validate-account.component';
import { EmailActivateAccountComponent } from './site/auth/email-activate-account/email-activate-account.component';
import { EmailResetPasswordComponent } from './site/auth/email-reset-password/email-reset-password.component';
import { LoginComponent } from './site/auth/login/login.component';
import { RegisterComponent } from './site/auth/register/register.component';
import { ResetPasswordComponent } from './site/auth/reset-password/reset-password.component';
import { ValidateAccountComponent } from './site/auth/validate-account/validate-account.component';
import { DocumentationComponent } from './site/documentation/documentation.component';
import { HomeComponent } from './site/home/home.component';
import { OrganizationComponent } from '@components/organization/organization.component';
import { ProfileComponent } from '@components/profile/profile.component';
import { SettingsComponent } from '@components/super/settings/settings.component';
import { UsersComponent } from '@components/super/users/users.component';
import { OperatorsComponent } from '@components/super/operators/operators.component';
import { CurrencyComponent } from '@components/super/currency/currency.component';
import { BeneficiaryComponent } from '@components/super/beneficiary/beneficiary.component';
import { DetailOrganizationComponent } from '@components/organization/detail-organization/detail-organization.component';
import { OwnerDetailsComponent } from '@components/organization/owner-details/owner-details.component';
import { UserDetailsComponent } from '@components/super/user-details/user-details.component';
import { CreateProjectComponent } from '@components/projets/create-project/create-project.component';
import { ProjetsComponent } from '@components/projets/projets.component';
import { ProjectDetailsComponent } from '@components/projets/project-details/project-details.component';
import { UpdateProjectComponent } from '@components/projets/update-project/update-project.component';
import { HasRoleGuard } from '@guards/has-role.guard';
import { UserProjectComponent } from '@components/projets/user-project/user-project.component';
import { UserOrganizationComponent } from '@components/organization/user-organization/user-organization.component';
import { SpecificUserProjectComponent } from '@components/projets/specific-user-project/specific-user-project.component';
import { PayementComponent } from '@components/payement/payement.component';
import { FinanceComponent } from '@components/super/finance/finance.component';
import { UserfinanceComponent } from '@components/userfinance/userfinance.component';
import { PayementHistoryComponent } from '@components/payement-history/payement-history.component';
import { WithdrawHistoryComponent } from '@components/withdraw-history/withdraw-history.component';
import { PayementDetailsComponent } from '@components/payement-history/payement-details/payement-details.component';
import { WithdrawDetailsComponent } from '@components/withdraw-history/withdraw-details/withdraw-details.component';
import { AdminFinanceComponent } from '@components/payement/admin-finance/admin-finance.component';
import { DemoPayementComponent } from '@components/demo-payement/demo-payement.component';



const routes: Routes = [
    {path: '', component:HomeComponent,pathMatch:'full'},
    {path: 'login', component:LoginComponent},
    {path: 'documentation', component:DocumentationComponent},
    {path:'register', component:RegisterComponent},
    { path: 'validation/:code', component: ValidateAccountComponent },
    { path: 'emailvalidation', component: CheckmailValidateAccountComponent },
    { path: 'validatemail', component: EmailActivateAccountComponent },
    {path:'resetpass', component: EmailResetPasswordComponent },
    {path:'resetpasslink', component: CheckemailPasswordComponent},
    { path: 'passwordReset/:code', component: ResetPasswordComponent },
    { path: 'payment/process/:code', component: PayementComponent },

    {
        path: 'dashbord',
        component: MainComponent,
        canActivate: [AuthGuard],
 
        children: [
            {
                path: 'currency',
                component: CurrencyComponent,
                canActivateChild: [AuthGuard,HasRoleGuard],data:{
                    role:'ROLE_SUPER'
                  },
            },
            {
                path: 'operator',
                component: OperatorsComponent,
                canActivateChild: [AuthGuard,HasRoleGuard],data:{
                    role:'ROLE_SUPER'
                  },
            },
            {
                path: 'settings',
                component: SettingsComponent,
                canActivateChild: [AuthGuard,HasRoleGuard],data:{
                    role:'ROLE_SUPER'
                  },
            },
            {
                path: 'beneficiary',
                component: BeneficiaryComponent,
                canActivateChild: [AuthGuard,HasRoleGuard],data:{
                    role:'ROLE_SUPER'
                  },
            },
            {
                path: 'users',
                component: UsersComponent,
                canActivateChild: [AuthGuard,HasRoleGuard],data:{
                    role:'ROLE_SUPER'
                  },
            },
            {
                path: 'projects',
                component: ProjetsComponent,
                canActivateChild: [AuthGuard,HasRoleGuard],data:{
                    role:'ROLE_SUPER'
                  },
            },
            {
                path: 'organization',
                component: OrganizationComponent,
                canActivateChild: [AuthGuard,HasRoleGuard],data:{
                    role:'ROLE_SUPER'
                  },
            },
            {
                path: 'finance',
                component: FinanceComponent,
                canActivateChild: [AuthGuard,HasRoleGuard],data:{
                    role:'ROLE_SUPER'
                  },
                  
            },
            { path: 'withdraw-history-admin', 
            component: AdminFinanceComponent,
            canActivateChild: [AuthGuard,HasRoleGuard],data:{
                role:'ROLE_SUPER'
              },
        },


            {
                path: 'user-organization',
                component: UserOrganizationComponent,
           
            },
            {
                path: 'user-project/:id',
                component: UserProjectComponent
            },
            {
                path: 'userows-project',
                component: SpecificUserProjectComponent
            },
            {
                path: 'profile',
                component: ProfileComponent
            },
           
            {
                path: 'addProjet',
                component: CreateProjectComponent
            },
            {
                path: 'demo-payement',
                component: DemoPayementComponent
            },
            {
                path: 'project-details/:id',
                component: ProjectDetailsComponent
            },
            {
                path: 'update-project/:id',
                component: UpdateProjectComponent
            },
            {
                path: '',
                component: DashboardComponent
            },
            { path: 'organisation-details/:id',
            component: DetailOrganizationComponent },
            { path: 'ownwer-details/:id',
            component: OwnerDetailsComponent},
            { path: 'user-details/:id', 
            component: UserDetailsComponent},
            { path: 'earn-withdraw', 
            component: UserfinanceComponent},
            { path: 'payement-history', 
            component: PayementHistoryComponent},
            { path: 'withdraw-history', 
            component: WithdrawHistoryComponent},
            {
                path: 'payement-details/:id',
                component: PayementDetailsComponent
            },
            {
                path: 'withdraw-details/:id',
                component: WithdrawDetailsComponent
            },
        ]
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}

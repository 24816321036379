<main class="content">
    <div class="pagetitle container-fluid">
        <h1>Update Project</h1>
        <nav style="display:flex;justify-content: space-between;">
            <div>
                <ol class="breadcrumb">

                    <li class="breadcrumb-item"><a routerLink="/dashbord">Home</a></li>
                    <li class="breadcrumb-item active">Projects</li>
                    <li class="breadcrumb-item active">Update Projects</li>
                </ol>
            </div>
            <div> <button class="btn btn-success" (click)="back()"> back</button></div>

        </nav>

    </div>
    <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
        <ng-container *ngSwitchCase="DataStateEnum.LOADING">
            <app-spinne></app-spinne>
        </ng-container>
        <ng-container *ngSwitchCase="DataStateEnum.ERROR">


        </ng-container>
        <ng-container *ngSwitchCase="DataStateEnum.LOADED">
            <form [formGroup]="addprojectValue">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-11 mx-auto">
                            <div class="card card-primary card-outline">
                                <a class="d-block w-100">
                                    <div class="card-header">
                                        <h4 class="card-title w-100">
                                            GENERALS INFORMATIONS
                                        </h4>
                                    </div>


                                </a>
                                <div class="">
                                    <div class="card-body table-responsive p-0">
                                        <table class="table table-striped table-valign-middle">
                                            <div class="card-body">
                                                <div class="input-field" style="display:none">
                                                    <label class="form-label" for="form3Example3">id<span class="text-danger me-1">*</span></label>
                                                    <input type="text" formControlName="id" [(ngModel)]="projectInfo.id" required id="form3Example1" class="form-control" />
                                                </div>
                                                <div class="form-group">
                                                    <div class="input-field" [class.error]="description.invalid && description.hasError('required','type')">
                                                        <label class="form-label" for="form3Example3">description<span class="text-danger me-1">*</span></label>
                                                        <input type="text" formControlName="description" [(ngModel)]="projectInfo.description" required id="form3Example3" class="form-control" />
                                                        <div *ngIf="description.invalid && (description.touched || description.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="description.errors?.required">*The description is
                                        required</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div class="input-field" [class.error]="name.invalid && name.hasError('required','type')">
                                                        <label class="form-label" for="form3Example3">Name<span class="text-danger me-1">*</span></label>
                                                        <input type="text" formControlName="name" [(ngModel)]="projectInfo.name" required id="form3Example3" class="form-control" />
                                                        <div *ngIf="name.invalid && (name.touched || name.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="name.errors?.required">*The name is
                                        required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-11 mx-auto">
                            <div class="card card-primary card-outline">
                                <a class="d-block w-100">
                                    <div class="card-header">
                                        <h4 class="card-title w-100">
                                            TECHNICAL INFORMATIONS
                                        </h4>
                                    </div>

                                </a>
                                <div class="">
                                    <div class="card-body table-responsive p-0">
                                        <table class="table table-striped table-valign-middle">
                                            <div class="card-body">
                                                <div class="form-group">
                                                    <div class="input-field" [class.error]="notifyUrl.invalid && notifyUrl.hasError('required','type')">
                                                        <label class="form-label" for="form3Example3">notifyUrl<span class="text-danger me-1">*</span></label>
                                                        <input type="text" formControlName="notifyUrl" [(ngModel)]="projectInfo.notifyUrl" required id="form3Example3" class="form-control" />
                                                        <div *ngIf="notifyUrl.invalid && (notifyUrl.touched || notifyUrl.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="notifyUrl.errors?.required">*The notifyUrl is
                                            required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input-field" [class.error]="cancelUrl.invalid && cancelUrl.hasError('required','type')">
                                                        <label class="form-label" for="form3Example3">cancelUrl<span class="text-danger me-1">*</span></label>
                                                        <input type="text" formControlName="cancelUrl" [(ngModel)]="projectInfo.cancelUrl" required id="form3Example3" class="form-control" />
                                                        <div *ngIf="cancelUrl.invalid && (cancelUrl.touched || cancelUrl.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="cancelUrl.errors?.required">*The cancelUrl is
                                            required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input-field" [class.error]="successUrl.invalid && successUrl.hasError('required','type')">
                                                        <label class="form-label" for="form3Example3">successUrl<span class="text-danger me-1">*</span></label>
                                                        <input type="text" formControlName="successUrl" [(ngModel)]="projectInfo.successUrl" required id="form3Example3" class="form-control" />
                                                        <div *ngIf="successUrl.invalid && (successUrl.touched || successUrl.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="successUrl.errors?.required">*The successUrl is
                                            required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid">
                    <div class="row">
                        <div class="col-11 mx-auto">
                            <div class="card card-primary card-outline">
                                <a class="d-block w-100">
                                    <div class="card-header">
                                        <h4 class="card-title w-100">
                                            ADDITIONAL INFORMATIONS
                                        </h4>
                                    </div>

                                </a>
                                <div class="">
                                    <div class="card-body table-responsive p-0">
                                        <table class="table table-striped table-valign-middle">
                                            <div class="card-body">
                                                <div class="form-group">

                                                    <div class="input-field" [class.error]="websiteUrl.invalid && websiteUrl.hasError('required','type')">
                                                        <label class="form-label" for="form3Example3">websiteUrl<span class="text-danger me-1">*</span></label>
                                                        <input type="text" formControlName="websiteUrl" [(ngModel)]="projectInfo.websiteUrl" required id="form3Example3" class="form-control" />
                                                        <div *ngIf="websiteUrl.invalid && (websiteUrl.touched || websiteUrl.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="websiteUrl.errors?.required">*The name is
                                            required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input-field">
                                                        <label class="form-label" for="form3Example3">Type of items sold<span class="text-danger me-1">*</span></label>

                                                        <select formControlName="itemTypeSold" class="form-control" id="item" [(ngModel)]="projectInfo.itemTypeSold">
                                                            <option></option>
                                                            <option value="PHYSICS">PHYSICS</option>
                                                            <option value="DIGITAL">DIGITAL</option>
            
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label>Selected Organization</label>
                                                            <div class="d-flex">
                                                                <select formControlName="organizationID" [disabled]="dataLoadOrganization" class="form-control" id="operators" [(ngModel)]="projectInfo.organization.id" required>
															    <option></option>
                                                                <option *ngFor="let items of activeOrganisation" value="{{items.id}}">{{items.name}}</option>
                                                                </select>
                                                                <div *ngIf="dataLoadOrganization" class="spinner-border spinner-border-sm" role="status"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label>Selected Benefiary</label>
                                                            <div class="d-flex">
                                                                <select formControlName="principalCode" [disabled]="dataLoadBeneficiary" class="form-control" id="operators" [(ngModel)]="projectInfo.principalB.code" required>
															<option></option>
                                                            <option *ngFor="let items of activeBeneficiary" value="{{items.code}}">{{items.designation}}</option>
														
														</select>
                                                                <div *ngIf="dataLoadBeneficiary" class="spinner-border spinner-border-sm" role="status"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input-field" [class.error]="operateurs.invalid && operateurs.hasError('required','type')">

                                                        <label>Selected Operator</label>

                                                        <div class="d-flex">
                                                            <p-multiSelect required [options]="activeOperator" formControlName="operateurs" [(ngModel)]="selectedCities" optionValue="code" optionLabel="designation"></p-multiSelect>
                                                        </div>
                                                        <div *ngIf="dataLoadOperator" class="spinner-border spinner-border-sm" role="status">
                                                        </div>


                                                        <div *ngIf="operateurs.invalid && (operateurs.touched || operateurs.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="operateurs.errors?.required">*The operator is
                                                                required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-11 mx-auto" style="align-items: end;justify-content: flex-end;display: flex;">
                    <button type="button" class="btn btn-primary" (click)="UpdateProject()" [disabled]="submitted">Save<div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>

                </div>
            </form>
        </ng-container>
    </ng-container>
</main>
<div class="container-scroller">
    <div class="main-panel">
        <div class="banner-wrapper">
            <div class="container">
                <div class="row overflow-hidden">
                    <div class="col-12 col-md-5" id="main">
                        <h1 class="banner-head font-weight-normal display-4" style="color: black;">MOOBIL PAY.</h1>
                        <p class="text-muted h4 font-weight-normal">A solution for the aggregation of the different mobile payement services in Cameroon.
                        </p>
                        <div class="pt-4">
                            <button class="btn btn-primary font-weight-bold text-white btn-up" type="submit">GET STARTED</button>
                            <!-- Button trigger modal -->
                            <!-- <a class="pl-3 text-muted font-weight-medium" data-toggle="modal" data-target=".bd-example-modal-xl"
                href="#">Watch a demo</a> -->
                            <div class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog">
                                <div class="modal-dialog modal-xl">
                                    <div class="modal-content">
                                        <iframe src="https://www.youtube.com/embed/f3lv65BFmaA" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-left" class="col-12 col-md-7">
                        <img class="img-fluid mt-5 mt-md-0" src="../../../assets/images/home.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="details-wrapper" id="about">
        <div class="container">
            <div data-aos="fade-up" class="partner-content-wrapper">
                <h3 class="text-center display-4">Discover MOOBIL PAY</h3>
                <h4 class="text-center text-muted font-weight-normal " style="font-size: 1.5rem;">Our API is adapted to your needs allowing you to manage the trafic coming from many payment services</h4>
                <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div class="d-flex justify-content-around">
                            <div class="image-box pb-4 pl-0 rounded-circle">
                                <img class="img-fluid" src="assets/images/orange.png" alt="">
                            </div>
                            <div class="image-box pb-4">
                                <img class="img-fluid" src="assets/images/YoomeeLogo.png" alt="">
                            </div>
                            <div class="image-box pb-4">
                                <img class="img-fluid" src="assets/images/mtn.png" alt="">
                            </div>
                            <div class="image-box pb-4">
                                <img class="img-fluid" src="assets/images/master.png" alt="">
                            </div>
                            <div class="image-box pb-4 pr-0">
                                <img class="img-fluid" src="assets/images/partner2.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" style="color: black;" class="feature-content-wrapper">
                <h3 class="text-center display-4">Activities of MOOBIL PAY</h3>
                <h4 class="text-center feature-sub-head text-muted font-weight-normal display-5">The aggregator supports you in all your online payment activities</h4>
                <div class="row align-items-center justify-content-around">
                    <div class="col-12 col-sm-6 col-lg-4" data-aos="fade-up">
                        <div class="card img-fluid bg-white overflow-hidden">
                            <div class="card-body text-center" style="padding: 0px !important; background-color: aliceblue;">
                                <div>
                                    <img alt="Website Builder Excerpt" data-src="assets/images/pay2.jpg" class="lazyloaded" src="https://www.elavon.com/content/dam/elavon/en-us/refresh/images/solutions/online-shopping-ecommerce-customer-experience-concept.jpg"><noscript><img class="img-fluid" src="https://www.elavon.com/content/dam/elavon/en-us/refresh/images/solutions/online-shopping-ecommerce-customer-experience-concept.jpg"  alt="">
                                      alt="Website Builder Excerpt" style="max-height:100px"></noscript>
                                </div>
                                <div class="py-2 text-center mb-0">
                                    <h5 class="pl-3 " style="font-size: 2rem;">WebSite</h5>
                                </div>

                                <p style="font-size: 1.1rem;">Purchasing products on your <br> e-commerce site.</p>
                            </div>

                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4" data-aos="fade-up">
                        <div class="card img-fluid bg-white overflow-hidden">

                            <div class="card-body text-center" style="padding: 0px !important;background-color: aliceblue;">
                                <a href="">
                                    <img alt="Website Builder Excerpt" data-src="assets/images/pay3.jpg" class="lazyloaded" src="assets/images/pay3.jpg" alt="Website Builder Excerpt">
                                </a>
                                <div class="py-2 text-center mb-0">
                                    <h4 class="pl-3 " style="font-size: 2rem;">Fees</h4>
                                </div>
                                <p style="font-size: 1.1rem;">Payment of university fees, school fees, bills and more</p>
                            </div>

                        </div>
                    </div>


                    <div class="col-12 col-sm-6 col-lg-4" data-aos="fade-up">
                        <div class="card img-fluid bg-white overflow-hidden">

                            <div class="card-body text-center" style="padding: 0px !important;background-color: aliceblue;">
                                <a href="">
                                    <img alt="Website Builder Excerpt" data-src="assets/images/pay1.jpg" class="lazyloaded" src="assets/images/pay1.jpg" alt="Website Builder Excerpt">
                                </a>
                                <div class="py-2 text-center mb-0">
                                    <h4 class="pl-3 " style="font-size: 2rem;">Mobile</h4>
                                </div>
                                <p style="font-size: 1.1rem;">Payment for services in different mobile applications</p>
                            </div>

                        </div>
                    </div>


                    <!-- <div class="col-sm-4">
                        <img class="img-fluid" src="assets/images/feature1.svg" alt="">
                        <h5 class="pl-3 " style="font-size: 2rem;">WebSite</h5>
                        <p class="text-muted px-3 pt-3">Purchasing products on your e-commerce site.</p>
                    </div>
                    <div class="col-sm-4">
                        <img class="img-fluid" src="assets/images/feature2.svg" alt="">
                        <h4 class="pl-3 " style="font-size: 2rem;">Fees</h4>
                        <p class="text-muted ">Payment of university fees, School fees,allowance fees etc</p>
                    </div>
                    <div class="col-sm-4">
                        <img class="img-fluid" src="assets/images/feature3.svg" alt="">
                        <h4 class="pl-3 " style="font-size: 2rem;">Mobile</h4>
                        <p class="text-muted px-3 pt-3">Payment for services in different mobile applications</p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="dashboard-wrapper" id="documentation">
        <div class="container">
            <div class="dashboard-content-wrapper">
                <div class="row overflow-hidden align-items-center">
                    <div data-aos="fade-right" class="col-lg-6">
                        <h2 class="display-4 font-weight-normal">Documentation of</h2>
                        <h2 class="display-4 font-weight-normal">MoobilPay</h2>
                        <h4 class="text-muted font-weight-light w-75">Removes distractions from websites and increases chances of online conversions.</h4>
                    </div>
                    <div data-aos="fade-left" class="col-lg-6 pt-5">
                        <img class="img-fluid" src="https://payretailers.com/wp-content/webp-express/webp-images/doc-root/wp-content/uploads/2021/08/Online-payment-integrations-how-much-does-it-cost-and-documentation.jpg.webp" class="lazy" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row d-flex align-items-center justify-content-around">

            <div class="col-sm-5 align-center block-information__block d-flex justify-content-center align-items-center mx-3" tabindex="0">
                <div class="block-information__container">
                    <img src="assets/images/dashboard-mini1.svg" alt="" class="block-information__block-icon">

                    <div class="block-information__headline">
                        <h4 class="pt-3 display-5">About us</h4>
                    </div>

                    <div class="block-information__block-description">
                        <p>KTC CENTER is a company run by teachers, engineers and resource people whose main objective is to innovate constantly in an unprecedented professionalism and innovation.</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-5 align-center block-information__block d-flex justify-content-center align-items-center" tabindex="0">
                <div class="block-information__container">
                    <img src="assets/images/dashboard-mini2.svg" alt="" class="block-information__block-icon">

                    <div class="">
                        <h4 class="pt-3 display-5">Garanty Fiability</h4>
                    </div>

                    <div class="block-information__block-description">
                        <p>Our services meet the highest compliance and security standards</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex justify-content-around align-items-center">

            <div class="col-sm-5 align-center block-information__block d-flex justify-content-center align-items-center mx-3" tabindex="0">
                <div class="block-information__container">
                    <img src="assets/images/dashboard-mini3.svg" alt="" class="block-information__block-icon">

                    <div class="block-information__headline">
                        <h4 class="pt-3 display-5">Garanty Security</h4>
                    </div>

                    <div class="block-information__block-description">
                        <p>MOOBIL PAY is a very secure service a particular enhancement has been done to garuantee the most sercured paiement experience ever.</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-5 align-center block-information__block d-flex justify-content-center align-items-center" tabindex="0">
                <div class="block-information__container">
                    <img src="assets/images/dashboard-mini4.svg" alt="" class="block-information__block-icon">

                    <div class="">
                        <h4 class="pt-3 display-5">Well Documented</h4>
                    </div>

                    <div class="block-information__block-description">
                        <p>Our API has a complete and clair documentation that enables the developper to quickly integerate the service</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row pt-4">
            <div class="col-sm-6 pb-3">
                <img class="img-fluid" src="assets/images/dashboard-mini1.svg" alt="">
                <h4 class="pt-3 display-5">About us</h4>
                <p class="text-muted w-75">KTC CENTER is a company run by teachers, engineers and resource people whose main objective is to innovate constantly in an unprecedented professionalism and innovation.</p>
            </div>
            <div class="col-sm-6 pb-3">
                <img class="img-fluid" src="assets/images/dashboard-mini2.svg" alt="">
                <h4 class="pt-3 display-5">Garanty Fiability</h4>
                <p class="text-muted w-75">Our services meet the highest compliance and security standards</p>
            </div>
            <div class="col-sm-6 pb-3">
                <img class="img-fluid" src="assets/images/dashboard-mini3.svg" alt="">
                <h4 class="pt-3 display-5">Garanty Security</h4>
                <p class="text-muted w-75">MOOBIL PAY is a very secure service a particular enhancement has been done to garuantee the most sercured paiement experience ever.</p>
            </div>
            <div class="col-sm-6 pb-3">
                <img class="img-fluid" src="assets/images/dashboard-mini4.svg" alt="">
                <h4 class="pt-3 display-5">Well Documented</h4>
                <p class="text-muted w-75">Our API has a complete and clair documentation that enables the developper to quickly integerate the service</p>
            </div>
        </div> -->
    </div>

    <div class="bottom-wrapper" id="contact">
        <div class="tab-wrapper">
            <div class="container">
                <h2 class="text-center font-weight-normal pb-3 display-4">we are listening.</h2>
                <h4 class="text-muted text-center font-weight-light display-5">Remember to leave us a message...</h4>
                <div class="container-fluid" id="contacter">
                    <div class="row  align-items-md-center d-flex" style="display: flex;">
                        <div class="col-12" style=" display: flex;" id="cont">
                            <div class="col-12 col-md-6 ">
                                <form action="">
                                    <input type="text" class="form-control" placeholder="Name"><br>
                                    <input type="email" class="form-control" placeholder="example@email.com"><br>
                                    <textarea name="" id="" cols="30" rows="10" class="form-control" placeholder="tell us something"></textarea>
                                    <br>
                                    <div style="margin: 10px;"><button type="button" class="btn btn-primary btn-lg">Send Message</button></div>

                                </form>

                            </div>
                            <div class="col-md-6 d-flex justify-content-center align-items-center">
                                <div style="text-align: center;max-width: 400px;">
                                    <img src="assets/images/call.png" width="100%" height="100%" alt="" alt="">
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- <div class="tab-body">
          <ul class="nav nav-pills justify-content-center mb-5" id="pills-tab" role="tablist">
            <li class="nav-item">
              <a class="nav-link nav-border-left active" id="pills-home-tab" data-toggle="pill" href="#pills-home"
                role="tab" aria-controls="pills-home" aria-selected="true">Monthly</a>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-border-right" id="pills-profile-tab" data-toggle="pill" href="#pills-profile"
                role="tab" aria-controls="pills-profile" aria-selected="false">Yearly</a>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <div class="row justify-content-center">
                <div class="col-12 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <div class="tab-title">
                        Free
                      </div>
                      <div class="badge badge-secondary text-primary d-inline-block">
                        BASIC
                      </div>
                      <p class="text-muted pt-4">5 downloads / month</p>
                      <p class="text-muted pt-2">Email support</p>
                      <p class="text-muted pt-2">Customizable</p>
                      <p class="text-muted pt-2 pb-2">No credit card required</p>
                      <button class="btn btn-outline-primary font-weight-bold btn-up">GET IT NOW</button>
                      <h6 class="text-muted pt-3 font-weight-normal">Start by trying our service for 30 days
                        free trial no credit card required</h6>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="card bg-white card-highlight card-hover">
                    <div class="card-body">
                      <div class="tab-title d-inline-block">
                        <h1 class="d-inline">$</h1>35
                      </div>
                      <h3 class="d-inline-block">/monthly</h3>
                      <div>
                        <div class="badge badge-secondary text-primary ">
                          <h6 class="m-0">PREMIUM</h6>
                        </div>
                      </div>
                      <p class="text-muted pt-4">5 downloads / month</p>
                      <p class="text-muted pt-2">Email support</p>
                      <p class="text-muted pt-2">Customizable</p>
                      <p class="text-muted pt-2 pb-2">No credit card required</p>
                      <button class="btn btn-primary font-weight-bold btn-up">GET IT NOW</button>
                      <h6 class="text-muted pt-3 font-weight-normal">tellus tincidunt auctor ornare consequat auctor
                        amet augue sed non soon as prepared</h6>
                      <div class="ribbon">
                        <div class="ribbon-inner">
                          <p>BEST</p>
                          <div class="left-line"></div>
                          <div class="triangle"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="card bg-white">
                    <div class="card-body">
                      <div class="tab-title d-inline-block">
                        <h1 class="d-inline">$</h1>25
                      </div>
                      <h3 class="d-inline-block">/monthly</h3>
                      <div>
                        <div class="badge badge-secondary text-primary ">
                          <h6 class="m-0">STANDARD</h6>
                        </div>
                      </div>
                      <p class="text-muted pt-4">5 downloads / month</p>
                      <p class="text-muted pt-2">Email support</p>
                      <p class="text-muted pt-2">Customizable</p>
                      <p class="text-muted pt-2 pb-2">No credit card required</p>
                      <button class="btn btn-outline-primary font-weight-bold btn-up">GET IT NOW</button>
                      <h6 class="text-muted pt-3 font-weight-normal">Protect by trying our product awesome systamatic
                        trial no credit card required</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
              <div class="row">
                <div class="col-sm-4">
                  <div class="card">
                    <div class="card-body">
                      <div class="tab-title">
                        Trail
                      </div>
                      <div class="badge badge-secondary text-primary d-inline-block">
                        BASIC
                      </div>
                      <p class="text-muted pt-4">5 downloads / month</p>
                      <p class="text-muted pt-2">Email support</p>
                      <p class="text-muted pt-2">Customizable</p>
                      <p class="text-muted pt-2 pb-2">No credit card required</p>
                      <button class="btn btn-outline-primary font-weight-bold btn-up">GET IT NOW</button>
                      <h6 class="text-muted pt-3 font-weight-normal">Start by trying our service for 30 days
                        free trial no credit card required</h6>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="card bg-white card-highlight card-hover">
                    <div class="card-body">
                      <div class="tab-title d-inline-block">
                        <h1 class="d-inline">$</h1>45
                      </div>
                      <h3 class="d-inline-block">/monthly</h3>
                      <div>
                        <div class="badge badge-secondary text-primary ">
                          <h6 class="m-0">PREMIUM</h6>
                        </div>
                      </div>
                      <p class="text-muted pt-4">5 downloads / month</p>
                      <p class="text-muted pt-2">Email support</p>
                      <p class="text-muted pt-2">Customizable</p>
                      <p class="text-muted pt-2 pb-2">No credit card required</p>
                      <button class="btn btn-primary font-weight-bold btn-up">GET IT NOW</button>
                      <h6 class="text-muted pt-3 font-weight-normal">Start by trying our service for 30 days
                        free trial no credit card required</h6>
                      <div class="ribbon">
                        <div class="ribbon-inner">
                          <p>BEST</p>
                          <div class="left-line"></div>
                          <div class="triangle"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="card bg-white">
                    <div class="card-body">
                      <div class="tab-title d-inline-block">
                        <h1 class="d-inline">$</h1>35
                      </div>
                      <h3 class="d-inline-block">/monthly</h3>
                      <div>
                        <div class="badge badge-secondary text-primary ">
                          <h6 class="m-0">STANDARD</h6>
                        </div>
                      </div>
                      <p class="text-muted pt-4">5 downloads / month</p>
                      <p class="text-muted pt-2">Email support</p>
                      <p class="text-muted pt-2">Customizable</p>
                      <p class="text-muted pt-2 pb-2">No credit card required</p>
                      <button class="btn btn-outline-primary font-weight-bold btn-up">GET IT NOW</button>
                      <h6 class="text-muted pt-3 font-weight-normal">Start by trying our service for 30 days
                        free trial no credit card required</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
            </div>
        </div>
        <div class="gradient-wrapper">
            <div class="container">
                <div class="signup-form-wrapper">
                    <div class="card py-3">
                        <div class="card-body">
                            <div class="row justify-content-between">
                                <div class="col-sm-12 col-lg-7">
                                    <h2 class="font-weight-normal display-5"> Subscribe To Our Newsletter</h2>
                                    <h5 class="text-muted font-weight-light pt-2">Learn more about our products and services through newsletters
                                    </h5>
                                </div>
                                <div class="col-sm-12 col-lg-5 form-text-btn">
                                    <input class="form-input" placeholder="Enter your e mail or mobile." type="text">
                                    <button class="btn btn-primary form-btn font-weight-bold">Get in Touch</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- content-wrapper ends -->
                <!-- partial:partials/_footer.html -->
                <app-footer></app-footer>
                <!-- partial -->
            </div>
        </div>
    </div>
    <!-- main-panel ends -->
    <!-- container-scroller ends -->
    <div class="dark-overlay"></div>
</div>